'use strict';


import EngagementView from 'root/app/components/engagement/EngagementView';
import {angularize} from 'reactInAngular';




// Import the component from the React -> and wrap it with the angularize function
angularize(EngagementView, 'hfEngagementView', angular.module('happyForceApp'));

const EngagementViewAngular = function ManageUsersRoute($stateProvider) {
    'ngInject';
    $stateProvider.state('engagement.screen', {
        url: '/',
        template: '<hf-engagement-view></hf-engagement-view>',
        data: { pageTitle: 'SIDEMENU_ENGAGEMENT', roles: ['MANAGER', 'ADMIN', 'ADVISOR'] }
    });
}
export default EngagementViewAngular;






