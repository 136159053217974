import angular  from "angular";

export default function GroupsService(Restangular, $log, Rollbar) {
  "ngInject";
    var Groups = Restangular.one('company').one('employees');

    var _groups = {};

    var CRUD = {
        GET: function (params, callback) {
            Groups
                .customGET('groups', params)
                .then(function (data) {
                    return callback(null, data);
                }, function (err) {

                    $log.error('Error getting groups', err, params);
                    Rollbar.error('Error getting groups ', err, params);
                    callback(err);
                });
        },

        POST: function (params, callback) {
            Groups
                .customPOST(params, 'groups')
                .then(function (data) {
                    $log.debug('Groups.POST Response', data, params);
                    return callback(null, data);
                }, function (err) {

                    $log.error('Error creating group', err, params);
                    Rollbar.error('Error creating group ', err, params);
                    callback(err);
                });
        },

        PUT: function (params, callback) {
            Groups
                .one('groups')
                .one(params.id)
                .customPUT(params)
                .then(function (data) {
                    $log.debug('Groups.PUT Response', data, params);
                    return callback(null, data);
                }, function (err) {

                    $log.error('Error updating group', err, params);
                    Rollbar.error('Error updating group ', err, params);
                    callback(err);
                });
        },

        DELETE: function (params, callback) {
            Groups
                .one('groups')
                .one(params)
                .remove()
                .then(function (data) {
                    $log.debug('Groups.DELETE Response', data, params);
                    return callback(null, data);
                }, function (err) {

                    $log.error('Error deleting group', err, params);
                    Rollbar.error('Error deleting group ', err, params);
                    callback(err);
                });
        }
    };

    var methods = function () {
        return {
            name: '',
            exitPolicies: [],
            groupFlags: []
        };
    };

    methods.addExitPolicy = function (params) {
        var obj = {
            policyType: params && params.type || 'NONE'
        };

        if (params && params.action) {
            params.exitAction = methods.addExitAction(params.action.type);
        }

        if (params && params.days)
            obj.days = params.days || 0;

        return obj;
    };

    methods.addExitAction = function (type, extras) {
        var exitAction = {
            actionType: type,
            targetGroup: null
        };

        if (extras && extras.targetGroup)
            exitAction.targetGroup = extras.targetGroup;

        return exitAction;
    };

    methods.getAll = function (status, callback) {
        var params = status || {
            active: status
        };

        CRUD.GET(params, function (err, data) {
            if (err) {
                return callback(err);
            }

            data.forEach(function (g) {
                _groups[g.id] = g;
            });

            callback(null, data);
        });
    };

    methods.create = function (group, callback) {
        if (!group.exitPolicies.length) {
            group.exitPolicies.push(methods.addExitPolicy());
        }

        $log.debug('Groups.create Params', group);
        CRUD.POST(group, callback);
    };

    methods.update = function (id, group, callback) {
        group.id = id;
        CRUD.PUT(group, callback);
    };

    methods.remove = function (id, callback) {
        CRUD.DELETE(id, callback);
    };

    methods.getGroupName = function (groupId) {
        var group = _groups && _groups[groupId];

        return (group && group.name) || null;
    };

    methods.getGroupByName = function (groupName) {
        var group;
        if (_groups) {
            group = _.find(_groups, function (group) {
                return group.name.toLowerCase() == groupName.toLowerCase();
            });
        }

        return (group) || null;
    };

    methods.hasGroups = function () {
        return Object.keys(_groups).length > 0;
    };

    methods.start = function () {
        CRUD.GET({ active: true }, function (err, data) {
            if (!err) {
                data.forEach(function (g) {
                    _groups[g.id] = g;
                });
            } else {
                console.error('There was an error loading groups', err);
            }
        });
    };

    methods.getGroup = function (id, callback) {
        Groups
            .one('groups')
            .one(id)
            .get()
            .then(function (data) {
                $log.debug('Groups.getGroup Response', data, id);
                return callback(null, data);
            }, function (err) {
                $log.error('Error getting group', err, id);
                Rollbar.error('Error getting group ', err, id);
                callback(err);
            });
    };

    methods.start();

    return methods;

}
