import React, { useState, useEffect } from 'react';
import {
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Stack,
    Box, Typography, Grid, Link
} from '@mui/material';
import { getService } from 'reactInAngular';
import HighchartsReact from 'highcharts-react-official';
import async from 'async';
import EngagementCharts from '../../../engagement.charts';
import Style from '../../../../../utilities/style.utils';
import Dates from '../../../../../utilities/date.utilities';
import { getHierarchyName } from '../../../../../shared/services/migrated/hierarchy.service';
import HappyLoading from '../../../../../shared/components/_migrated/loader/loading.directive';
import {
    CharacteristicName,
    GroupName,
    HierarchyName
} from '../../../../../shared/new-components/hf-segmentation-name/hfSegmentationName';
import EmptyState from '../../../../../shared/components/_migrated/empty_state/empty_state';

interface ScorePositioningBoxProps {
    to: Date;
    score: string;
    navigateToDetail: (state: string, params: Record<string, any>) => void;
    dateGrouping: string;
}

const ScorePositioningBox: React.FC<ScorePositioningBoxProps> = ({ to, score, navigateToDetail, dateGrouping }) => {
    const $translate = getService('$translate');
    const ErrorSvrc = getService('ErrorSvrc');
    const ScoresV2Srvc = getService('ScoresV2Srvc');
    const Languages = getService('Languages');
    const Groups = getService('Groups');
    const Characteristics = getService('Characteristics');

    const [positioningBy, setPositioningBy] = useState<string>('HIERARCHIES');
    const positioningByOptions = [
        { id: 'HIERARCHIES', label: $translate.instant('AREAS') },
        { id: 'GROUPS', label: $translate.instant('GROUPS') },
        { id: 'CHARACTERISTICS', label: $translate.instant('SEGMENTS') }
    ];
    const [data, setData] = useState<any[]>([]);
    const [bubbleChart, setBubbleChart] = useState<any>(null);
    const [allSelected, setAllSelected] = useState<boolean>(false);
    const [noTypes, setNoTypes] = useState<boolean>(false);
    const [scoreName, setScoreName] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const computeColor = (count: number): string => {
        const remainder = count % Style.colors.length;
        return Style.colors[remainder === 0 ? Style.colors.length - 1 : remainder - 1];
    };


    const getSegmentationName = (segment: any, callback: (err: any, name: string | null) => void) => {
        if (positioningBy === 'HIERARCHIES') {
            getHierarchyName(segment.hierarchyId, (err, name) => {
                if (err) {
                    callback(err, null);
                } else {
                    callback(null, name);
                }
            });
        } else if (positioningBy === 'GROUPS') {
            callback(null, Groups.getGroupName(segment.groupId));
        } else if (positioningBy === 'CHARACTERISTICS') {
            let name = '';
            const characteristic = Characteristics.getCharacteristicById(segment.characteristic.characteristicId);
            if (characteristic) {
                name = `${characteristic.description}-${Languages.getTranslationFromUserLanguage(
                    Characteristics.getCharacteristicValueName(segment.characteristic.characteristicId, segment.characteristic.value)
                )}`;
            }
            callback(null, name);
        }
    };

    const loadResults = () => {
        setLoading(true);
        setBubbleChart(null);
        const toFormatted = Dates.lastDayOfMonth(to);
        const params = {
            scoreId: score,
            from: Dates.firstDayOfMonth(to),
            to: toFormatted,
            grouping: dateGrouping
        };

        ScoresV2Srvc.stats.heatmap(positioningBy, params, (err: any, response: any) => {
            if (err) {
                ErrorSvrc.showErrorModal(err);
                return;
            }

            const hasData = response && response.results && response.results.length > 0;
            setNoTypes(!hasData);

            if (hasData) {
                const result = response.results[0];
                const stream: any[] = [];
                let count = 0;

                result.results
                    .filter((item: any) => item.hierarchyId || item.groupId || item.characteristic)
                    .forEach((item: any) => {
                        if (!item.nonEnoughEmployees) {
                            stream.push((next: (err: any, finalItem: any) => void) => {
                                const finalItem = {
                                    characteristic: item.characteristic,
                                    groupId: item.groupId,
                                    hierarchyId: item.hierarchyId,
                                    value: item.result.result,
                                    participants: item.result.participants,
                                    expectedParticipants: item.result.expectedParticipants,
                                    color: computeColor(++count),
                                    selected: true  // Initially, set all items as selected
                                };
                                getSegmentationName(item, (err, name) => {
                                    if (err) {
                                        next(err, null);
                                    } else {
                                        finalItem.name = name;
                                        next(null, finalItem);
                                    }
                                });
                            });
                        }
                    });

                async.parallel(stream, (err: any, results: any[]) => {
                    if (err) {
                        ErrorSvrc.showErrorModal(err);
                        return;
                    } else {
                        setData(results);
                    }
                });
            }

            setLoading(false);
        });
    };

    useEffect(() => {
        if (to && dateGrouping && positioningBy) {
            loadResults();
        }
    }, [to, dateGrouping, positioningBy]);

    useEffect(() => {
        ScoresV2Srvc.getScore(score, (err: any, score: any) => {
            if (err) {
                setScoreName('?');
            } else {
                setScoreName(Languages.getTranslationFromUserLanguage(score.name, null));
            }
        });
    }, [score]);

    useEffect(() => {
        setAllSelected(data.every(item => item.selected));
        if (scoreName) {
            setBubbleChart(EngagementCharts.scorePositioningChart(data, 0, 10, scoreName, 'SCORE', $translate));
        }
    }, [data, scoreName]);

    const handleSelectAll = () => {
        const isSelectAll = !allSelected;
        setData(data.map(item => ({ ...item, selected: isSelectAll })));
    };

    const handleSelect = (item: any) => {
        const newData = data.map(dataItem => {
            const match =
                (item.hierarchyId && item.hierarchyId === dataItem.hierarchyId) ||
                (item.groupId && item.groupId === dataItem.groupId) ||
                (item.characteristic && item.characteristic.characteristicId === dataItem.characteristic.characteristicId && item.characteristic.value === dataItem.characteristic.value);

            if (match) {
                return { ...dataItem, selected: !dataItem.selected };
            }

            return dataItem;
        });
        setData(newData);
    };

    const renderItemForType = (item: any) => {
        if (item.hierarchyId) {
            return (<HierarchyName id={item.hierarchyId} />);
        } else if (item.groupId) {
            return (<GroupName id={item.groupId} />);
        } else if (item.characteristic) {
            return (<CharacteristicName id={item.characteristic.id} valueId={item.characteristic.value} />);
        } else {
            // TODO: log error
            return undefined;
        }
    };




    return (
        <HappyLoading loading={loading}>

            <Stack gap={1}>


                <Stack alignItems={'center'} justifyContent={'center'} direction={'row'} gap={1} >
                    <Box flexGrow={1}>
                        <Typography variant="h2">
                            {$translate.instant('SCORE_POSITIONING')}
                        </Typography>
                    </Box>
                    <Box>
                        <FormControl fullWidth>
                            <InputLabel id="positioning-by-label">{$translate.instant('SHOW_BY')}</InputLabel>
                            <Select
                                labelId="positioning-by-label"
                                value={positioningBy}
                                label="Show By"
                                onChange={event => {
                                    setPositioningBy(event.target.value);
                                }}
                            >
                                {positioningByOptions.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>{option.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Stack>

                {!noTypes && (

                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <Stack gap={1} alignItems={'flex-start'} justifyContent={'center'}>
                                <Typography variant="body3">
                                    {$translate.instant('ENGAGEMENT_POSITIONING_FILTER')}
                                </Typography>


                                <Typography variant="body3">
                                    <Link onClick={handleSelectAll}>
                                        {allSelected ? $translate.instant('UNSELECT_ALL') : $translate.instant('SELECT_ALL')}
                                    </Link>
                                </Typography>

                                <Box>
                                    <FormGroup>
                                        {data.map(item => (
                                            <FormControlLabel
                                                key={item.hierarchyId || item.groupId || item.characteristic?.characteristicId}
                                                control={<Checkbox checked={item.selected} onClick={() => {
                                                    handleSelect(item);
                                                }}/>}
                                                label={renderItemForType(item)}
                                            />
                                        ))}
                                    </FormGroup>
                                </Box>
                            </Stack>


                        </Grid>
                        <Grid item xs={9}>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={bubbleChart}></HighchartsReact>
                        </Grid>
                    </Grid>


                )}
                {noTypes && (
                    <EmptyState
                        image="images/analyze_no_data.png"
                        small
                        message={$translate.instant('ACTIVATION_PACE_NO_SEGMENTS', {})}
                        submessage={$translate.instant('ACTIVATION_PACE_NO_SEGMENTS_SUBMESSAGE', {})}
                    />

                )}


            </Stack>


        </HappyLoading>
    );




};

export default ScorePositioningBox;
