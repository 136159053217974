import React, { useState, useEffect } from 'react';
import { getService } from 'reactInAngular';
import HighchartsReact from 'highcharts-react-official';
import Numbers from '../../../../../../../utilities/number.utilities';
import Valuation from '../../../../../../../utilities/valuation.utils';
import EngagementDetailCharts from '../../../../../engagement_detail.charts';
import ScoreQuestionRow from '../question_box_row/score_question_box';
import HfMultiLanguageText from '../../../../../../../shared/new-components/hf-multilanguage-text/hfMultilanguageText';
import {Typography, Grid, Stack, Button, Divider} from '@mui/material';
import HFInfoIconWithTooltip from '../../../../../../../shared/new-components/hf-info-icon-with-tooltip';
import EmptyState from '../../../../../../../shared/components/_migrated/empty_state/empty_state';
import Highcharts from 'highcharts';
import {DataQuality} from '../../../../../../../shared/types/common';
import {ScoreFactorResult} from '../../../../../../../shared/types/scores';
import useScoreNames from '../../../../../../../shared/hooks/useScoreNames';
import QualityPopoverText from '../../../../../shared/quality_popover/EngagementQualityPopover';


interface ScoreFactorBoxProps {
    factorResults: TimeSerieFactorResult[];
    scoreId: string;
    factorId: string;
    dateGrouping: string;
}

interface TimeSerieFactorResult extends ScoreFactorResult{
    from: string;
    to: string
}

const ScoreFactorBox: React.FC<ScoreFactorBoxProps> = ({ scoreId, factorId, factorResults, dateGrouping }) => {
    const [showQuestions, setShowQuestions] = useState(false);
    const [noData, setNoData] = useState(true);
    const [value, setValue] = useState<number | null>(null);
    const [quality, setQuality] = useState(DataQuality.NO_DATA);
    const [valuationColor, setValuationColor] = useState('');
    const [variation, setVariation] = useState<number | null>(null);
    const [questions, setQuestions] = useState<any[]>([]);
    const [sparkChart, setSparkChart] = useState<any>(null);
    const [variationText, setVariationText] = useState('');

    const {  factorNames, factorDescriptions, scoreNamesLoading } = useScoreNames(scoreId);

    const $translate = getService('$translate');

    useEffect(() => {

        // Iterate over the score results to find the factor with the given id


        if (factorResults && factorResults.length > 0) {
            setNoData(false);
            const lastResult = factorResults.slice(-1)[0];
            setValue(Numbers.roundNumber(lastResult.result, 1));
            setQuality(lastResult.quality);
            setValuationColor(Valuation.valuationColor(lastResult.valuation));
            setVariation(Numbers.roundNumber(lastResult.variation, 1));

            const tmpQuestions: any[] = [];
            factorResults.forEach(factorResult => {
                factorResult.questions.forEach(questionResult => {
                    let question = tmpQuestions.find(q => q.id === questionResult.id);
                    if (!question) {
                        question = {
                            id: questionResult.id,
                            results: []
                        };
                        tmpQuestions.push(question);
                    }
                    question.results.push({
                        from: factorResult.from,
                        to: factorResult.to,
                        quality: questionResult.quality,
                        valuation: questionResult.valuation,
                        result: questionResult.result,
                        variation: questionResult.variation,
                        participants: questionResult.participants,
                        distribution: questionResult.distribution
                    });
                });
            });
            setQuestions(tmpQuestions);

            const chartResults = factorResults.map(result => [result.from, result.result]);
            setSparkChart(EngagementDetailCharts.factorSparkLineEvolution(chartResults, dateGrouping, 70));

            const previousResultIndex = factorResults.length - 2;
            const previousResultValue = previousResultIndex >= 0 ? Numbers.roundNumber(factorResults[previousResultIndex].result, 1) : 0;

            const keys = {
                variation: variation,
                previous: previousResultValue
            };

            if (variation > 0) {
                setVariationText($translate.instant('ENGAGEMENT_FACTOR_VARIATION_POSITIVE', keys));
            } else if (variation < 0) {
                setVariationText($translate.instant('ENGAGEMENT_FACTOR_VARIATION_NEGATIVE', keys));
            } else {
                setVariationText($translate.instant('ENGAGEMENT_FACTOR_VARIATION_NEUTRAL', keys));
            }


        } else {
            setNoData(true);
        }

    }, [factorResults, scoreId, dateGrouping, variation]);




    return (
        <Stack gap={1}>
            <Grid container sx={
                {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }
            }>
                <Grid item sm={3} xs={6}>
                    <Stack direction="column" gap={1}>
                        {quality !== 'NO_DATA' && value && value > 0 ? (
                            <Stack direction={'row'} display={'flex'} alignItems={'baseline'}>
                                <Typography variant="h4" fontSize={'32px'}  style={{ color: valuationColor }}>
                                    {value}
                                </Typography>
                                <Typography variant="subtitle1" style={{ color: valuationColor }}>
                                    pts
                                </Typography>
                            </Stack>

                        ) : (
                            <Typography variant="h4"  style={{ color: valuationColor }}>
                                {noData ? $translate.instant('SCORE_NO_DATA_SHORT') : ''}
                            </Typography>
                        )}

                        <Stack direction="row" gap={0.5}>
                            <Typography variant="body1" fontWeight="bold">
                                <HfMultiLanguageText multilanguage={factorNames ? factorNames[factorId] : ''} />
                            </Typography>
                            <HFInfoIconWithTooltip
                                tooltipContent={<HfMultiLanguageText multilanguage={factorDescriptions ? factorDescriptions[factorId] : ''} />}
                            />
                        </Stack>

                        <QualityPopoverText quality={quality} />
                    </Stack>
                </Grid>

                <Grid item
                    sm={2}
                    xs={6}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderLeft: '1px solid #e0e0e0',
                        padding: 2
                    }}>
                    <Typography
                        variant="body3"
                        dangerouslySetInnerHTML={{__html: variationText}}
                    />
                </Grid>

                <Grid item sm={5} xs={12}>
                    {quality === 'NO_DATA' && (
                        <EmptyState
                            image="images/no_scores.svg"
                            small={true}
                            message={$translate.instant('NO_DATA')}
                        />
                    )}
                    {quality !== 'NO_DATA' && sparkChart && (
                        <HighchartsReact
                            className="m-t-n-sm m-l-md score_chart"
                            highcharts={Highcharts}
                            options={sparkChart}
                        />
                    )}
                </Grid>

                <Grid item sm={2} xs={6} className="text-center">
                    <Button variant="text" onClick={() => setShowQuestions(!showQuestions)}>
                        {$translate.instant(showQuestions ? 'ENGAGEMENT_FACTOR_HIDE_QUESTIONS' : 'ENGAGEMENT_FACTOR_SHOW_QUESTIONS')}
                    </Button>
                </Grid>
            </Grid>

            <Divider/>

            {showQuestions && (
                <Grid container className="animated fadeIn">
                    {questions.map((question, index) => (
                        <Grid item xs={12} key={question.id} className="p-t p-b m-l-lg">
                            <ScoreQuestionRow
                                scoreId={scoreId}
                                factorId={factorId}
                                questionResults={question}
                                dateGrouping={dateGrouping}
                                numQuestions={questions.length}
                                questionNum={index + 1}
                            />
                        </Grid>
                    ))}
                </Grid>
            )}
        </Stack>
    );
};

export {ScoreFactorBox, TimeSerieFactorResult};
