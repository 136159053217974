import React, { useState, useEffect } from 'react';
import { angularize, getService } from 'reactInAngular';
import {
    Box, Link,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import Dates from '../../../../utilities/date.utilities';
import Numbers from '../../../../utilities/number.utilities';
import { APISettings } from '../../../../shared/services/migrated/axiosWrapper';
import Valuation from '../../../../utilities/valuation.utils';
import ScaleChart from '../../../../shared/components/_migrated/scale-chart/scale-chart.component';
import Style from '../../../../utilities/style.utils';
import { getENPSStats } from '../../../../shared/services/migrated/enps.service';
import HappyLoading from '../../../../shared/components/_migrated/loader/loading.directive';
import EmptyState from '../../../../shared/components/_migrated/empty_state/empty_state';
import { dateToQueryParam } from '../../../../utilities/navigation_state.utilities';
import HFTag from 'root/app/shared/new-components/hf-tag/hf-tag';
import HFInfoIconWithTooltip from 'root/app/shared/new-components/hf-info-icon-with-tooltip';
import angular from 'angular';
import QualityPopoverText from '../../shared/quality_popover/EngagementQualityPopover';
import {DataQuality} from '../../../../shared/types/common';

interface EngagementENPSBoxProps {
    to: Date;
    dateGrouping: string;
    onShowDetail: () => void;
}

const EngagementENPSBox: React.FC<EngagementENPSBoxProps> = ({ to, dateGrouping, onShowDetail }) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [noData, setNoData] = useState<boolean>(false);
    const [enps, setEnps] = useState<number>(0);
    const [nextMeasurement, setNextMeasurement] = useState<Date | null>(null);
    const [enpsEnabled, setENPSEnabled] = useState<boolean>(false);
    const [enpsColor, setEnpsColor] = useState<string>(Style.neutral);
    const [variation, setVariation] = useState<number>(0);
    const [variationText, setVariationText] = useState<string>('');
    const [benchmarkDifferenceText, setBenchmarkDifferenceText] = useState<string>('');
    const [quality, setQuality] = useState<DataQuality>(DataQuality.NO_DATA);
    const [lastMeasurement, setLastMeasurement] = useState<Date | null>(null);
    const [averageResult, setAverageResult] = useState<number>(0);
    const [tagStatus, setTagStatus] = useState<string>('NEUTRAL');
    const [benchmarkDifference, setBenchmarkDifference] = useState<number>(0);
    const [globalBenchmark, setGlobalBenchmark] = useState<number>(0);
    const [hasEnoughActiveEmployees, setHasEnoughActiveEmployees] = useState<boolean>(true);

    const $translate = getService('$translate');
    const ErrorSvrc = getService('ErrorSvrc');

    const slices = [
        {
            color: Style.bad,
            range: [-100, -10],
            label: $translate.instant('ENPS_SLICE_FIRST'),
        },
        {
            color: Style.neutral,
            range: [-10, 20],
            label: $translate.instant('ENPS_SLICE_SECOND'),
        },
        {
            color: Style.warning,
            range: [20, 40],
            label: $translate.instant('ENPS_SLICE_THIRD'),
        },
        {
            color: Style.excellent,
            range: [40, 100],
            label: $translate.instant('ENPS_SLICE_FOURTH'),
        },
    ];

    useEffect(() => {
        setLoading(true);
        const params = {
            to: Dates.lastDayOfMonth(to),
            grouping: dateGrouping,
        };

        getENPSStats(params, (err: any, result: any) => {
            setLoading(false);
            if (err) {
                if (ErrorSvrc.isNotEnoughActiveEmployees(err)) {
                    setHasEnoughActiveEmployees(false);
                } else {
                    console.error('ERROR getting ENPS Stats', err);
                }
            } else if (result) {
                setENPSEnabled(result.enabled);
                if (!result.results || result.results.length === 0) {
                    setNoData(true);
                } else {
                    setNoData(false);
                    setHasEnoughActiveEmployees(true);
                    const lastResult = result.results.slice(-1)[0];
                    setLastMeasurement(Dates.fromAPIFormat(lastResult.to, APISettings.apiDateFormat));
                    setNextMeasurement(
                        result.nextMeasurement
                            ? Dates.fromAPIFormat(result.nextMeasurement, APISettings.apiDateFormat)
                            : null
                    );
                    setQuality(lastResult.quality);
                    if (lastResult.quality !== 'NO_DATA') {
                        setEnps(Numbers.roundNumber(lastResult.result));
                        setEnpsColor(Valuation.valuationColor(lastResult.valuation, true));
                        setVariation(lastResult.variation);
                        setAverageResult(Numbers.roundNumber(lastResult.averageResult, 1));
                        setTagStatus(
                            Valuation.enpsTagType(
                                lastResult.result,
                                lastResult.averageResult,
                                lastResult.valuation,
                                true
                            )
                        );
                        setGlobalBenchmark(Numbers.roundNumber(lastResult.globalBenchmark, 1));
                        setBenchmarkDifference(
                            Numbers.roundNumber(
                                Numbers.roundNumber(lastResult.result) - lastResult.globalBenchmark,
                                1
                            )
                        );
                    }
                }
            } else {
                setNoData(true);
            }
        });
    }, [to, dateGrouping]);

    useEffect(() => {
        const keys = {
            previous: Numbers.roundNumber(enps - variation, 1),
            variation: Numbers.roundNumber(variation, 1),
        };

        if (variation === 0) {
            setVariationText($translate.instant('ENGAGEMENT_ENPS_COMPANY_COMPARISON_NEUTRAL', keys));
        } else if (variation > 0) {
            setVariationText($translate.instant('ENGAGEMENT_ENPS_COMPANY_COMPARISON_POSITIVE', keys));
        } else {
            setVariationText($translate.instant('ENGAGEMENT_ENPS_COMPANY_COMPARISON_NEGATIVE', keys));
        }
    }, [enps, variation]);

    useEffect(() => {
        const keys = {
            benchmark: Numbers.roundNumber(globalBenchmark, 1),
            difference: Numbers.roundNumber(benchmarkDifference, 1),
        };

        if (benchmarkDifference === 0) {
            setBenchmarkDifferenceText(
                $translate.instant('ENGAGEMENT_ENPS_BENCHMARK_COMPARISON_NEUTRAL', keys)
            );
        } else if (benchmarkDifference > 0) {
            setBenchmarkDifferenceText(
                $translate.instant('ENGAGEMENT_ENPS_BENCHMARK_COMPARISON_POSITIVE', keys)
            );
        } else {
            setBenchmarkDifferenceText(
                $translate.instant('ENGAGEMENT_ENPS_BENCHMARK_COMPARISON_NEGATIVE', keys)
            );
        }
    }, [globalBenchmark, benchmarkDifference]);

    const renderContent = () => (
        <HappyLoading loading={loading}>
            <Stack gap={1}>
                {!enpsEnabled && (
                    <EmptyState
                        image="images/no_enps.svg"
                        small
                        message={$translate.instant('ENGAGEMENT_ENPS_DISABLED')}
                    />
                )}
                {!hasEnoughActiveEmployees && (
                    <EmptyState
                        image="images/anonymous.png"
                        small
                        message={$translate.instant('NOT_ENOUGH_EMPLOYEES')}
                        submessage={$translate.instant('NOT_ENOUGH_EMPLOYEES_SUBMESSAGE')}
                    />
                )}
                {noData && (
                    <Stack>
                        <Typography variant="bigNumber" color="textSecondary">
                            {$translate.instant('NO_DATA')}
                        </Typography>
                        <Typography variant="small" color="textSecondary">
                            {$translate.instant('ENPS_NO_DATA')}
                        </Typography>
                    </Stack>
                )}
                {enpsEnabled && !noData && hasEnoughActiveEmployees && (
                    <Stack gap={3} direction="row" width="100%">
                        <Stack direction="column" gap={1} flex={2} width="50%" alignItems="baseline">
                            <HFTag
                                status={tagStatus}
                                text={$translate.instant('ENPS_AVERAGE_RESULT', {
                                    result: averageResult,
                                })}
                            />
                            <Stack direction="row" alignItems="baseline" gap={1}>
                                <Typography variant="bigNumber" color={enpsColor}>
                                    {enps}
                                </Typography>
                                <Typography variant="h2" color={enpsColor}>
                                    pts
                                </Typography>
                            </Stack>
                            <Box width="100%">
                                <ScaleChart slices={slices} value={enps} />
                            </Box>
                        </Stack>
                        <Stack
                            direction="column"
                            flex={2}
                            sx={{ borderLeft: '1px solid #E0E0E0' }}
                            paddingLeft={3}
                            gap={1}
                        >
                            <Typography
                                variant="body3"
                                dangerouslySetInnerHTML={{ __html: variationText }}
                            />
                            <Typography
                                variant="body3"
                                dangerouslySetInnerHTML={{ __html: benchmarkDifferenceText }}
                            />
                        </Stack>
                    </Stack>
                )}
            </Stack>
        </HappyLoading>
    );

    return (
        <Stack gap={1}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="h2">{$translate.instant('ENPS_TITLE')}</Typography>
                {!loading && lastMeasurement && (

                    <Link onClick={onShowDetail}>
                        {$translate.instant('ENGAGEMENT_VIEW_DETAIL')}
                    </Link>
                )}
            </Stack>
            <Stack direction="row">
                <Typography variant="caption" color="textSecondary">
                    {$translate.instant('ENGAGEMENT_QUARTER_MEASUREMENT_OF', {
                        date: moment(lastMeasurement).format('MMM YYYY'),
                        quarter: moment(lastMeasurement).format('[Q]Q'),
                    })}
                </Typography>
                <HFInfoIconWithTooltip
                    tooltipTitle={$translate.instant('ENGAGEMENT_QUARTER_NEXT_MEASUREMENT', {
                        date: moment(nextMeasurement).format('MMM YYYY'),
                    })}
                />
            </Stack>
           <QualityPopoverText quality={quality} status={enpsEnabled ? 'ENABLED' : 'DISABLED'} />
            {renderContent()}
        </Stack>
    );
};


export default EngagementENPSBox;
