

enum DataQuality {
    NO_DATA = 'NO_DATA',
    LOW = 'LOW',
    WARNING = 'WARNING',
    NORMAL = 'NORMAL',
    GOOD = 'GOOD'
}


enum DateGrouping {
    DAY = 'DAY',
    WEEK = 'WEEK',
    MONTH = 'MONTH',
    QUARTER = 'QUARTER',
    YEAR = 'YEAR'
}

enum ValuationType {
    NO_DATA = 'NO_DATA',
    BAD = 'BAD',
    WARNING = 'WARNING',
    NEUTRAL = 'NEUTRAL',
    GOOD = 'GOOD',
    EXCELLENT = 'EXCELLENT'
}


export { DataQuality, DateGrouping, ValuationType };
