import React from 'react';
import { angularize } from 'reactInAngular';

interface EmptyStateProps {
    image: string;
    message: string;
    submessage?: string;
    small?: boolean;
}

const EmptyState: React.FC<EmptyStateProps> = ({ image, message, submessage, small }) => {
    return small ? (
        <div className="text-center">
            <img className="img-md" src={image} alt={message} />
            <div className="small-style p-t">
                <strong>{message}</strong>
            </div>
            {submessage && <div className="warm-grey mini-style m-t-md">{submessage}</div>}
        </div>
    ) : (
        <div className="text-center">
            <img className="img-lg" src={image} alt={message} />
            <h1 className="p-t">{message}</h1>
            {submessage && <p className="small-style p-t">{submessage}</p>}
        </div>
    );
};

angularize(EmptyState, 'hfEmptyState', angular.module('happyForceApp'), {
    image: '<',
    message: '<',
    submessage: '<',
    small: '<',
});

export default EmptyState;
