import Stack from '@mui/material/Stack';
import React, { useState, useEffect } from 'react';
import { getService } from 'reactInAngular';
import {ScoreFactorBox,  TimeSerieFactorResult } from './components/factor_box_row/score_factor_box';
import {Typography} from '@mui/material';
import EmptyState from '../../../../../shared/components/_migrated/empty_state/empty_state';
import {ScoreFactorResult, ScoreResult, ScoreStatsResults} from '../../../../../shared/types/scores';
import {DataQuality, ValuationType} from '../../../../../shared/types/common';
import useScoreNames from '../../../../../shared/hooks/useScoreNames';



interface ScoreDetailFactorsListProps {
    hasEnoughActiveEmployees: boolean;
    results: ScoreResult[];
    scoreId: string;
    dateGrouping: string;
}



interface TimeSerieFactorResults {
    factorId: string;
    results: TimeSerieFactorResult[];
}


const ScoreDetailFactorsList: React.FC<ScoreDetailFactorsListProps> = ({ results, scoreId, dateGrouping, hasEnoughActiveEmployees }) => {
    const $translate = getService('$translate');
    const {factorIds} = useScoreNames(scoreId);

    const  [data, setData] = useState<TimeSerieFactorResults[]>([]);

    useEffect(() => {
        if (results && results.length > 0 && factorIds) {

            const data: TimeSerieFactorResults[] = [];

            factorIds.forEach(factorId => {

                const factorResults: TimeSerieFactorResult[] = [];

                results.forEach(scoreResult => {
                    if (scoreResult.factors) {
                        scoreResult.factors.forEach(factorResult => {
                            if (factorResult.id === factorId) {
                                factorResults.push({
                                    ...factorResult,
                                    from: scoreResult.from,
                                    to: scoreResult.to
                                });
                            }
                        });
                    }
                });

                data.push({
                    factorId,
                    results: factorResults
                });

            });

            setData(data);

        }
    }, [results, factorIds]);


    const renderFactors = () => {

        if (hasEnoughActiveEmployees) {
            return data.map((factorTimeSerie, index) => (
                <ScoreFactorBox
                    key={index}
                    scoreId={scoreId}
                    factorId={factorTimeSerie.factorId}
                    factorResults={factorTimeSerie.results}
                    dateGrouping={dateGrouping}
                />
            ));
        } else {
            return (
                <EmptyState
                    small={true}
                    image="images/anonymous.png"
                    message={$translate.instant('NOT_ENOUGH_EMPLOYEES', {})}
                    submessage={$translate.instant('NOT_ENOUGH_EMPLOYEES_SUBMESSAGE', {})}
                />
            )
        }


    }

    return (
        <Stack gap={2}>
            <Typography variant="h4" gutterBottom>
                {$translate.instant('ENGAGEMENT_DETAIL_FACTORS')}
            </Typography>

            {renderFactors()}
        </Stack>
    );
};

export default ScoreDetailFactorsList;
