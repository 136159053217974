import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import { getService } from 'reactInAngular';
import {Box, Stack, Tooltip, Typography} from '@mui/material';
import HFCircleDot from '../../../../shared/new-components/hf-circle-dot/hf-circle-dot';



interface ClassificationFlowChartProps {
    values: ClassificationFlowItem[];
}

interface ClassificationFlowItem {
    type: 'category' | 'arrow';
    color?: string;
    number?: number;
    label?: string;
    flow?: number;
    flowItems?: {
        count: number;
        sourceLabel: string;
        targetLabel: string;
    };
}

const ClassificationFlowChart: React.FC<ClassificationFlowChartProps> = ({ values }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const $translate = getService('$translate');

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);


    const renderCategory = (item: ClassificationFlowItem) => {
        return (
            <Stack spacing={1} direction={'row'} alignItems={'center'}>
                <HFCircleDot color={item.color} />
                <Typography variant={'body2'}>{item.label}</Typography>
                <Typography variant={'body2'}>{item.number}</Typography>
            </Stack>
        )

    }

    const renderArrow = (item: ClassificationFlowItem) => {
        return (
            <Box display={'flex'}
                alignItems={'center'}
                sx={{
                    minHeight: '44px',
                    minWidth: '44px',
                    backgroundImage: item.flow && item.flow >= 0 ? 'url(\'/images/flow_left.png\')' : 'url(\'/images/flow_right.png\')',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '44px 44px',
                    backgroundPosition: 'center',
                    justifyContent: 'center',
                }}

                justifyContent={'center'}>

                <Typography variant={'body2'}>
                    {item.flow}
                </Typography>

                {item.flowItems && item.flowItems.length > 0 && (
                    <Tooltip title={
                        <Stack gap={1}>

                            {item.flowItems && item.flowItems.map((flowItem, index) => (
                                <Typography variant={'caption'} key={index}>
                                    {$translate.instant('CLASSIFICATION_FLOW_CHART_ARROW_ITEMS', {
                                        source: flowItem.sourceLabel,
                                        target: flowItem.targetLabel,
                                        count: flowItem.count,
                                    })}
                                </Typography>
                            ))}

                        </Stack>
                    }>
                        <IconButton
                            aria-owns={open ? 'mouse-over-popover' : undefined}
                            aria-haspopup="true"
                            onMouseEnter={handlePopoverOpen}
                            onMouseLeave={handlePopoverClose}
                        >
                            <InfoIcon/>
                        </IconButton>
                    </Tooltip>
                )}




            </Box>
        )
    }


    return (

        <Stack spacing={2} direction={'row'} width={'100%'}>
            {values.map((item, index) => (
                <Box key={index} flexGrow={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                    {item.type === 'category' && (
                        renderCategory(item)
                    )}
                    {item.type === 'arrow' && (
                        renderArrow(item)
                    )}
                </Box>
            ))}
        </Stack>

    );
};

export {ClassificationFlowChart, ClassificationFlowItem};
