import {useEffect, useMemo, useState} from 'react';
import {getService} from '../../../migration_utils/react-in-angular';
import {MultiLanguage} from '../types/multilanguage';




/*
    A utility hook that recovers and caches the names of a score, its factors and questions, along with their descriptions.
 */
function useScoreNames(scoreId: string) {

    const ScoresV2Srvc = getService('ScoresV2Srvc');
    const Languages = getService('Languages');

    const [scoreName, setScoreName] = useState<string | undefined>();
    const [scoreDescription, setScoreDescription] = useState<string | undefined>();
    const [scoreLongDescription, setScoreLongDescription] = useState<string | undefined>();

    const [scoreIconPath, setScoreIconPath] = useState<string | undefined>();

    const [factorIds, setFactorIds] = useState<string[]>([]);
    const [factorNames, setFactorNames] = useState<MultiLanguage | undefined>();
    const [factorDescriptions, setFactorDescriptions] = useState<MultiLanguage | undefined>();
    const [factorLongDescriptions, setFactorLongDescriptions] = useState<MultiLanguage | undefined>();
    const [questionNames, setQuestionNames] = useState<MultiLanguage | undefined>();

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (scoreId) {
            setLoading(true);
            ScoresV2Srvc.getScore(scoreId, function (err:any, score: any) {
                if (err) {
                    // TODO: Handle error
                    setError(err);
                    setScoreName(scoreId);
                } else {
                    setScoreName( Languages.getTranslationFromUserLanguage(score.name));
                    setScoreIconPath(score.iconPath);
                    setScoreDescription( Languages.getTranslationFromUserLanguage(score.shortDescription));
                    setScoreLongDescription( Languages.getTranslationFromUserLanguage(score.description));

                    const newFactorNames: Names = {};
                    const newQuestionNames: Names = {};

                    const newFactorDescriptions: Names = {};
                    const newFactorLongDescriptions: Names = {};


                    score.factors.forEach((factor:any) => {
                        newFactorNames[factor.id] =  Languages.getTranslationFromUserLanguage(factor.name);
                        newFactorDescriptions[factor.id] = Languages.getTranslationFromUserLanguage(factor.description);
                        newFactorLongDescriptions[factor.id] = Languages.getTranslationFromUserLanguage(factor.longDescription);
                        factor.questions.forEach((question:any) => {
                            newQuestionNames[question.id] = Languages.getTranslationFromUserLanguage(question.title);
                        });
                    });

                    setFactorIds(score.factors.map((factor:any) => factor.id));

                    setFactorNames(newFactorNames);
                    setFactorDescriptions(newFactorDescriptions);
                    setFactorLongDescriptions(newFactorLongDescriptions);
                    setQuestionNames(newQuestionNames);

                }
                setLoading(false);
            });
        }

    }, [scoreId]);


    const memoizedScoreName = useMemo(() => scoreName, [scoreName]);
    const memoizedScoreIconPath = useMemo(() => scoreIconPath, [scoreIconPath]);
    const memoizedScoreDescription = useMemo(() => scoreDescription, [scoreDescription]);
    const memoizedScoreLongDescription = useMemo(() => scoreLongDescription, [scoreLongDescription]);
    const memoizedFactorNames = useMemo(() => factorNames, [factorNames]);
    const memoizedFactorDescriptions = useMemo(() => factorDescriptions, [factorDescriptions]);
    const memoizedFactorLongDescriptions = useMemo(() => factorLongDescriptions, [factorLongDescriptions]);
    const memoizedQuestionNames = useMemo(() => questionNames, [questionNames]);

    const memoizedFactorIds = useMemo(() => factorIds, [factorIds]);


    return {
        scoreName: memoizedScoreName,
        scoreIconPath: memoizedScoreIconPath,
        scoreDescription: memoizedScoreDescription,
        scoreLongDescription: memoizedScoreLongDescription,
        factorIds: memoizedFactorIds,
        factorNames: memoizedFactorNames,
        factorDescriptions: memoizedFactorDescriptions,
        factorLongDescriptions: memoizedFactorLongDescriptions,
        questionNames: memoizedQuestionNames,
        scoreNamesLoading: loading,
        error
    };
}

export default useScoreNames;
