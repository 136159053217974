import React, { useState, useEffect } from 'react';
import { angularize, getService } from 'reactInAngular';
import {
    Checkbox,
    Button,
    Box,
    Typography,
    Grid,
    Stack, Switch, FormControlLabel,
} from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import HFInfoIconWithTooltip from '../../../../shared/new-components/hf-info-icon-with-tooltip';
import angular from 'angular';

interface SettingsAlertsAndRemindersProps {
    companyConfig: any;
    onSave: (companyConfiguration) => void;
}

const SettingsAlertsAndReminders = ({
    companyConfig,
    onSave,
}: SettingsAlertsAndRemindersProps) => {
    const $translate = getService('$translate');

    const [alertDays, setAlertDays] = useState(companyConfig?.alertDays || []);
    const [selectedAlertTime, setSelectedAlertTime] = useState(moment().hours(0).minutes(0));

    const [lifeEventsReminderEnabled, setLifeEventsReminderEnabled] = useState(false);
    const [reminderDays, setReminderDays] = useState(companyConfig?.voteReminderDays || []);
    const [reminderTimes, setReminderTimes] = useState({
        MORNING: moment().hours(0).minutes(0),
        MIDDAY: moment().hours(0).minutes(0),
        AFTERNOON: moment().hours(0).minutes(0),
    });


    // For each time frame, create an utility array that holds the max & min times in mommentjs format

    const timeFrames = [
        {
            key: 'MORNING',
            min: moment().hours(0).minutes(0),
            max: moment().hours(11).minutes(45)
        },
        {
            key: 'MIDDAY',
            min: moment().hours(12).minutes(0),
            max: moment().hours(14).minutes(45)
        },
        {
            key: 'AFTERNOON',
            min: moment().hours(15).minutes(0),
            max: moment().hours(22).minutes(45)
        }
    ]


    useEffect(() => {
        if (companyConfig) {


            setAlertDays(companyConfig.alertDays || []);
            setSelectedAlertTime(
                moment()
                    .hours(companyConfig.alertHour || 0)
                    .minutes(companyConfig.alertMinute || 0)
            );

            setReminderDays(companyConfig.voteReminderDays || []);
            setReminderTimes({
                MORNING: moment()
                    .hours(companyConfig.voteReminderTimeConfig.MORNING.hour || 0)
                    .minutes(companyConfig.voteReminderTimeConfig.MORNING.minute || 0),
                MIDDAY: moment()
                    .hours(companyConfig.voteReminderTimeConfig.MIDDAY.hour || 0)
                    .minutes(companyConfig.voteReminderTimeConfig.MIDDAY.minute || 0),
                AFTERNOON: moment()
                    .hours(companyConfig.voteReminderTimeConfig.AFTERNOON.hour || 0)
                    .minutes(companyConfig.voteReminderTimeConfig.AFTERNOON.minute || 0),
            });

            setLifeEventsReminderEnabled(companyConfig.lifeEventsReminderEnabled);
        }
    }, [companyConfig]);



    const renderDaysCheckbox = (type, days, onChange) => (
        <>
            {[1, 2, 3, 4, 5, 6, 7].map((day) => (
                <Box key={`${type}-${day}`} mr={2}>
                    <Checkbox
                        value={day}
                        checked={days.includes(day)}
                        onChange={onChange}
                    />
                    <Typography variant="body2" component="span">
                        {$translate.instant(['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'][day - 1])}
                    </Typography>
                </Box>
            ))}
        </>
    );


    const handleSave = () => {

        // Let's create an updated companyConfig object

        const updatedCompanyConfig = {
            ...companyConfig,
            alertDays: alertDays,
            alertHour: selectedAlertTime.hours(),
            alertMinute: selectedAlertTime.minutes(),
            voteReminderDays: reminderDays,
            lifeEventsReminderEnabled: lifeEventsReminderEnabled,
            voteReminderTimeConfig: {
                MORNING: {
                    hour: reminderTimes.MORNING.hours(),
                    minute: reminderTimes.MORNING.minutes(),
                },
                MIDDAY: {
                    hour: reminderTimes.MIDDAY.hours(),
                    minute: reminderTimes.MIDDAY.minutes(),
                },
                AFTERNOON: {
                    hour: reminderTimes.AFTERNOON.hours(),
                    minute: reminderTimes.AFTERNOON.minutes(),
                },
            },
        };


        onSave(updatedCompanyConfig);
    }

    if (!companyConfig) {
        return <></>
    }

    return (
        <Stack gap={2}>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={10}>
                    <Typography variant="h2" fontWeight="bold">
                        {$translate.instant('ALERTS_AND_REMINDERS')}
                    </Typography>
                    <Typography variant="body3" color="text.secondary">
                        {$translate.instant('ALERTS_AND_REMINDERS_DESCRIPTION')}
                    </Typography>
                </Grid>
                <Grid item xs={2} display={'flex'} justifyContent={'end'}>
                    <Button variant="contained" color="primary" onClick={handleSave}>
                        {$translate.instant('SAVE')}
                    </Button>
                </Grid>
            </Grid>

            <Stack gap={1}>
                <Typography variant="h4" fontWeight="bold">
                    {$translate.instant('ALERTS')}
                </Typography>
                <Typography
                    variant="body3"
                    color="text.secondary"
                    dangerouslySetInnerHTML={{
                        __html: $translate.instant('ALERTS_DESCRIPTION'),
                    }}
                />
            </Stack>

            <Grid container spacing={2}>
                <Grid item xs={6} sm={12}>
                    <Typography variant="h6">
                        {$translate.instant('ALERT_DAYS')}
                    </Typography>

                    <Stack direction="row">
                        {renderDaysCheckbox('alert', alertDays, (e) => {

                            const handleDay = Number(e.target.value);

                            if (e.target.checked) {
                                setAlertDays([...alertDays, handleDay]);
                            } else {
                                setAlertDays(alertDays.filter((day) => day !== handleDay));
                            }

                        })}
                    </Stack>
                </Grid>
                <Grid item xs={6} sm={12}>
                    <Stack gap={1}>
                        <Typography variant="h6">
                            {$translate.instant('ALERT_HOUR')}
                        </Typography>
                        <TimePicker
                            timeSteps={{ hours: 1, minutes: 15 }}
                            value={selectedAlertTime}
                            onChange={(newTime) => setSelectedAlertTime(newTime)}
                        />
                    </Stack>

                </Grid>
            </Grid>

            <Stack gap={1}>
                <Typography variant="h4" fontWeight="bold">
                    {$translate.instant('VOTE_REMINDER')}
                </Typography>
                <Typography variant="body3" color="text.secondary" mt={1}>
                    {$translate.instant('VOTE_REMINDER_DESCRIPTION')}
                </Typography>
            </Stack>

            <Grid container spacing={2}>
                <Grid item xs={6} sm={12}>
                    <Typography variant="h6" fontWeight="bold">
                        {$translate.instant('REMINDER_DAYS')}
                    </Typography>
                    <Stack direction="row">


                        {renderDaysCheckbox('voteReminderDays', reminderDays, (e) => {

                            const handleDay = Number(e.target.value);

                            if (e.target.checked) {
                                setReminderDays([...reminderDays, handleDay]);
                            } else {
                                setReminderDays(reminderDays.filter((day) => day !== handleDay));
                            }

                        })}

                    </Stack>
                </Grid>


                {timeFrames.map((timeFrame) => (
                    <Grid item xs={2} sm={4} key={timeFrame.key}>
                        <Stack gap={1}>
                            <Stack direction={'row'} gap={0.5}>
                                <Typography variant="body3" fontWeight="bold">
                                    {$translate.instant(`REMINDER_${timeFrame.key}_HOUR`)}
                                </Typography>

                                <HFInfoIconWithTooltip
                                    tooltipContent={
                                        <Typography variant={'caption'}>
                                            {$translate.instant(`REMINDER_${timeFrame.key}_HOUR_DESCRIPTION`)}
                                        </Typography>
                                    }
                                    color={'primary'}
                                />
                            </Stack>


                            <TimePicker
                                slotProps={{
                                    field: {
                                        readOnly: true,
                                    },
                                }}
                                skipDisabled={true}
                                minTime={timeFrame.min}
                                maxTime={timeFrame.max}
                                timeSteps={{ hours: 1, minutes: 15 }}
                                value={reminderTimes[timeFrame.key]}
                                onChange={(newTime) => setReminderTimes({ ...reminderTimes, [timeFrame.key]: newTime })}
                            />
                        </Stack>
                    </Grid>
                ))}
            </Grid>


            <Stack gap={1}>
                <Typography variant="h4" fontWeight="bold">
                    {$translate.instant('ANNIVERSARY_BIRTHDAY_REMINDER')}
                </Typography>
                <Typography variant="body3" color="text.secondary" mt={1}>
                    {$translate.instant('ANNIVERSARY_BIRTHDAY_REMINDER_DESCRIPTION')}
                </Typography>


                <FormControlLabel
                    control={
                        <Switch
                            checked={lifeEventsReminderEnabled}
                            onChange={event => {
                                setLifeEventsReminderEnabled(event.target.checked);
                            }}
                        />
                    }
                    label={
                        <Typography variant={'body3'}>
                            {$translate.instant('ACTIVATED')}
                        </Typography>
                    }
                />


            </Stack>
        </Stack>
    );
};

angularize(
    SettingsAlertsAndReminders,
    'hfSettingsAlertsAndReminders',
    angular.module('happyForceApp'),
    {
        companyConfig: '<',
        onSave: '<',
    }
);

export default SettingsAlertsAndReminders;
