


import React, {FC, useEffect, useState} from 'react';

import {angularize, getService} from '../../../../../migration_utils/react-in-angular';
import angular from 'angular';
import {
    HeatmapType,
    IHeatmapColumn,
    IHeatmapRow,
    ISegmentInfo
} from '../../../../shared/new-components/hf-heatmap/heatmap-types';
import {
    buildHeatMapKey, buildLoadedSegmentKey,
    createColumnDefinition,
    getSegmentId
} from '../../../../shared/new-components/hf-heatmap/heatmap_utils';
import {getENPSGrouping} from '../../../../shared/services/migrated/enps.service';
import Dates from '../../../../utilities/date.utilities';
import {APISettings} from '../../../../shared/services/migrated/axiosWrapper';
import HFHeatmap from '../../../../shared/new-components/hf-heatmap/heatmap';
import ENPSCell from './components/cellTypes/ENPSCell';
import HICell from './components/cellTypes/HICell';
import ScoreFactorCell from './components/cellTypes/ScoreFactorCell';
import {CommonSegmentationFilters} from '../../../../shared/new-components/hf-filter-menu/types';


interface EngagementHeatmapProps {
    to: Date;
    dateGrouping: string;
    onShowScoreDetail: (scoreId: string, filters?: CommonSegmentationFilters) => void;
    onShowHIDetail: (filters?: CommonSegmentationFilters) => void;
    onShowENPSDetail: (filters?: CommonSegmentationFilters) => void;
}



const EngagementHeatmap: FC<EngagementHeatmapProps> = ({ to, dateGrouping, onShowHIDetail, onShowENPSDetail, onShowScoreDetail }) => {


    const Scores = getService('Scores');
    const Languages = getService('Languages');
    const HIService = getService('HIService');
    const ScoresV2Srvc = getService('ScoresV2Srvc');
    const ErrorSvrc = getService('ErrorSvrc');


    const [loadingKeys, setLoadingKeys] = useState<Set<string>>(new Set()); // This is used to avoid loading the same segment twice
    const [preparingColumns, setPreparingColumns] = useState<boolean>(false);
    const [heatMapData, setHeatMapData] = useState({});
    const [rowsNotEnoughEmployees, setRowsNotEnoughEmployees] = useState<Set<string>>(new Set());
    const [loadingData, setLoadingData] = useState<boolean>(false);

    const [columns, setColumns] = useState<IHeatmapColumn[]>([]);
    const [loadedSegmentKeys, setLoadedSegmentKeys] = useState<Set<string>>(new Set());
    const [heatmapGroupBy, setHeatmapGroupBy] = useState<HeatmapType>(HeatmapType.HIERARCHIES);


    /**
     * Prepare columns
     */


    useEffect(() => {

        setPreparingColumns(true); // Start loading

        Scores.list({}, (err, scores) => {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                const processedColumns = [];

                // First column will always be HI:
                processedColumns.push(createColumnDefinition('HI', 'Happiness Index', true, 'HI', false, false, '/images/hi_column_hm.png', null));

                // Add ENPS column:
                processedColumns.push(createColumnDefinition('ENPS', 'eNPS', true, 'ENPS', false, false, '/images/enps_column_hm.png', null));

                // Process and add scores:
                scores.forEach((score) => {
                    if (score.status === 'ACTIVATED' || (score.status === 'DISABLED' && score.numberMeasurements > 0)) {
                        processedColumns.push(createColumnDefinition(
                            score.id,
                            Languages.getTranslationFromUserLanguage(score.name),
                            true, 'SCORE', true, false, score.iconPath, null
                        ));

                        // Process and add factors for each score:
                        score.factors.forEach((factor) => {
                            processedColumns.push(createColumnDefinition(
                                factor.id,
                                Languages.getTranslationFromUserLanguage(factor.name),
                                false, 'FACTOR', false, false, null, score.id
                            ));
                        });
                    }
                });
                setColumns(processedColumns); // Set processed columns into state
            }
            setPreparingColumns(false); // End loading
        });

    }, [to, dateGrouping]);






    const handleOnColumnPress = (columnId:string, showChildren: boolean) => {
        // Check if the column has children
        if (columns?.filter(c => c.id === columnId)[0]?.hasChildren) {
            const updatedColumns = angular.copy(columns);
            updatedColumns.forEach((column) => {
                if (column.id === columnId) {
                    column.isDeployed = showChildren;
                }
                if (column.parentId === columnId) {
                    column.visible = showChildren;
                }
            });
            setColumns(updatedColumns);
        }
    }


    const handleOnCellPress = (segmentInfo: ISegmentInfo, column: IHeatmapColumn) => {




        const filter: CommonSegmentationFilters = {
            segmentIds: segmentInfo.type === 'CHARACTERISTIC' ? [{
                id: segmentInfo.characteristic.characteristicId,
                values: [segmentInfo.characteristic.value]
            }]: null,
            hierarchyIds: segmentInfo.type === 'HIERARCHY' && segmentInfo.hierarchyId !== 'Company' ? [segmentInfo.hierarchyId] : null,
            groupIds: segmentInfo.type === 'GROUP' ? [segmentInfo.groupId] : null,
        }

        if (column.type === 'HI') {
            onShowHIDetail(filter);
        } else if (column.type === 'ENPS') {
            onShowENPSDetail(filter);
        } else if (column.type === 'SCORE') {
            onShowScoreDetail(column.id, filter);
        } else if (column.type === 'FACTOR') {
            onShowScoreDetail(column.parentId, filter);
        }
    }


    const buildCell = (column: IHeatmapColumn, row: IHeatmapRow, keyDate) => {

        const cellData = heatMapData[buildHeatMapKey(column.type, column.id, row.segmentId, keyDate, heatmapGroupBy, dateGrouping)];

        if (column.type === 'HI') {
            return <HICell cellData={cellData}
                segment={row.segmentInfo}
                column={column}
                onCellClick={handleOnCellPress}
                selectedInsight={null}
                loading={loadingKeys.has(row.segmentId)}/>;
        } else if (column.type === 'ENPS') {
            return <ENPSCell cellData={cellData}
                segment={row.segmentInfo}
                column={column}
                onCellClick={handleOnCellPress}
                selectedInsight={null}
                loading={loadingKeys.has(row.segmentId)}/>;
        } else if (column.type === 'SCORE') {
            return <ScoreFactorCell cellData={cellData}
                loading={loadingKeys.has(row.segmentId)}
                segment={row.segmentInfo}
                column={column}
                selectedInsight={null}
                onCellClick={handleOnCellPress}
                id={column.id}
                type={'SCORE'}/>;
        } else if (column.type === 'FACTOR') {
            return <ScoreFactorCell cellData={cellData}
                loading={loadingKeys.has(row.segmentId)}
                segment={row.segmentInfo}
                column={column}
                selectedInsight={null}
                id={column.id}
                onCellClick={handleOnCellPress}
                type={'FACTOR'}/>;
        }
    }




    //Three functions used to get info from HI, eNPS and Scores.
    const loadHIResults = function (groupBy: HeatmapType, params) {

        return new Promise(function (resolve, reject) {
            HIService.stats.grouping(groupBy, params, function (error, response) {
                if (error) {
                    reject(error);
                } else {
                    resolve([{
                        id: 'HI',
                        status: 'ACTIVATED',
                        results: response.results
                    }]);
                }
            });
        });
    };

    const loadENPSResults = function (groupBy: HeatmapType, params) {
        return new Promise(function (resolve, reject) {
            getENPSGrouping(groupBy, params, function (error, response) {
                if (error) {
                    reject(error);
                } else {
                    resolve([{
                        id: 'ENPS',
                        status: 'ACTIVATED',
                        results: response.results
                    }]);
                }
            });
        });
    };

    const loadScoreResults = function (groupBy: HeatmapType, params) {
        return new Promise(function (resolve, reject) {
            ScoresV2Srvc.stats.heatmap(groupBy, params, function (error, response) {
                if (error) {
                    reject(error);
                } else {

                    const scoreResults = [];

                    response.results.forEach(function (scoreGroupedResult) {
                        scoreResults.push({
                            id: scoreGroupedResult.id,
                            status: scoreGroupedResult.status,
                            results: scoreGroupedResult.results,
                        });

                    });
                    resolve(scoreResults);
                }
            });
        });
    };


    const processHeatmapData = function (newData) {
        const newHeatMapData = angular.copy(heatMapData);
        const keyDate = Dates.toAPIFormat(to, APISettings.apiDateFormat);
        const newRowsNotEnoughEmployees = new Set(rowsNotEnoughEmployees);
        const newLoadedSegmentKeys = new Set(loadedSegmentKeys);

        // We need to iterate over all the data to build entries for the heatmap
        newData.forEach(function (data) {
            data.results.forEach(function (result) {

                /**
                 * We build the key for the heatmap data
                 */
                if (data.id === 'HI' || data.id === 'ENPS') {
                    const segmentId = getSegmentId(result);
                    const key = buildHeatMapKey(data.id, data.id, segmentId, keyDate, heatmapGroupBy, dateGrouping);
                    const loadedSegmentKey = buildLoadedSegmentKey(segmentId, keyDate, heatmapGroupBy, dateGrouping);

                    newHeatMapData[key] = result;
                    newLoadedSegmentKeys.add(loadedSegmentKey);
                    if (result.nonEnoughEmployees) {
                        newRowsNotEnoughEmployees.add(loadedSegmentKey);
                    } else {
                        newRowsNotEnoughEmployees.delete(loadedSegmentKey);
                    }
                } else {

                    // For scores, we need to iterate over the factors and sub results

                    data.results.forEach(function (scoreResult) {

                        try {
                            const segmentId = getSegmentId(scoreResult);
                            const key = buildHeatMapKey('SCORE', data.id, segmentId, keyDate, heatmapGroupBy, dateGrouping);
                            newHeatMapData[key] = scoreResult.result;
                            const loadedSegmentKey = buildLoadedSegmentKey(segmentId, keyDate, heatmapGroupBy, dateGrouping);
                            newLoadedSegmentKeys.add(loadedSegmentKey);
                            if (scoreResult.nonEnoughEmployees) {
                                newRowsNotEnoughEmployees.add(loadedSegmentKey);
                            } else {
                                newRowsNotEnoughEmployees.delete(loadedSegmentKey);
                            }

                            scoreResult.result?.factors?.forEach(function (factoResult) {
                                const factorKey = buildHeatMapKey('FACTOR', factoResult.id, segmentId, keyDate, heatmapGroupBy, dateGrouping);
                                newHeatMapData[factorKey] = factoResult;
                            });


                        } catch (e) {
                            console.log(e);
                        }
                    });
                }
            });
        });

        setHeatMapData(newHeatMapData);
        setRowsNotEnoughEmployees(newRowsNotEnoughEmployees);
        setLoadedSegmentKeys(newLoadedSegmentKeys);
    };

    const loadData = function (requiredSegmentIds: string[]) {

        // Check if there are keys to load
        const toLoadKeys = requiredSegmentIds.filter(segmentId => {
            const keyDate = Dates.toAPIFormat(to, APISettings.apiDateFormat);
            const loadedSegmentKey = buildLoadedSegmentKey(segmentId, keyDate, heatmapGroupBy, dateGrouping);
            return !loadedSegmentKeys.has(loadedSegmentKey);
        });

        // If there are keys to load, start to load them
        if (toLoadKeys.length > 0) {
            setLoadingData(true);
            setLoadingKeys(new Set(toLoadKeys));
            const params = {
                grouping: dateGrouping,
                to: Dates.lastDayOfMonth(to)
            };
            if (HeatmapType.HIERARCHIES === heatmapGroupBy) {
                params['hierarchyId'] = toLoadKeys;
            } else if (HeatmapType.GROUPS === heatmapGroupBy) {
                params['groupId'] = toLoadKeys;
            } else if (HeatmapType.CHARACTERISTICS === heatmapGroupBy) {
                params['characteristicId'] = toLoadKeys;
            }

            const promises = [
                loadHIResults(heatmapGroupBy, params),
                loadENPSResults(heatmapGroupBy, params),
                loadScoreResults(heatmapGroupBy, params)
            ];
            Promise.all(promises).then(function (values: any[]) {
                const hiValues = values[0] || [];
                const eNPSValues = values[1] || [];
                const scoreValues = values[2] || [];

                // Build heatmap data object for late processing
                const heatmapRowData = hiValues.concat(eNPSValues, scoreValues);
                processHeatmapData(heatmapRowData);
                setLoadingKeys(new Set());
            }).catch(function (error) {
                ErrorSvrc.showErrorModal(error);
            }).finally(function () {
                setLoadingData(false);
            });
        }
    }

    return (
        <HFHeatmap
            to={to}
            dateGrouping={dateGrouping}
            columns={columns}
            buildCell={buildCell}
            onColumnPress={handleOnColumnPress}
            onDataRequired={loadData}
            loadingData={loadingData}
            preparingColumns={preparingColumns}
            heatmapGroupBy={heatmapGroupBy}
            rowsNotEnoughEmployees={rowsNotEnoughEmployees}
            onHeatmapGroupByChange={setHeatmapGroupBy}
        />
    );
}


angularize(EngagementHeatmap, 'hfEngagementHeatmap', angular.module('happyForceApp'), {
    to: '<',
    dateGrouping: '<'
});



export default EngagementHeatmap;
