import React, { useState, useEffect } from 'react';
import { getService, angularize } from 'reactInAngular';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, { Options } from 'highcharts';
import HappyLoading from '../../../../shared/components/_migrated/loader/loading.directive';
import Style from '../../../../utilities/style.utils';
import Numbers from '../../../../utilities/number.utilities';
import { APISettings } from '../../../../shared/services/migrated/axiosWrapper';
import Dates from '../../../../utilities/date.utilities';
import Charts from '../../../../utilities/charts.utilities';
import { Typography } from '@mui/material';
import moment from 'moment';

interface EngagementDetailEvolutionChartProps {
    loading: boolean;
    hasEnoughActiveEmployees: boolean;
    data: Array<{
        from: string;
        result: number | null;
        globalBenchmark: number | null;
        industryBenchmark: number | null;
        companyBenchmark: number | null;
    }>;
    minValue: number;
    maxValue: number;
    scoreType: string;
    showCompanyBenchmark?: boolean;
    dateGrouping: string;
}

const EngagementDetailEvolutionChart: React.FC<EngagementDetailEvolutionChartProps> = ({ loading, data, minValue, maxValue, scoreType, showCompanyBenchmark, dateGrouping }) => {
    const [chartConfig, setChartConfig] = useState<Options | null>(null);
    const [hasData, setHasData] = useState(true);

    const $translate = getService('$translate');
    const CompanySvrc = getService('CompanySvrc');

    const offset12Hours = 12 * 3600 * 1000;

    const scoreLineEvolution = (data: EngagementDetailEvolutionChartProps['data'], min: number, max: number, type: string, industryName: string | null) => {
        const results: [number, number | null][] = [];
        const benchmarks: [number, number | null][] = [];
        const industryBenchmarks: [number, number | null][] = [];
        const companyBenchmarks: [number, number | null][] = [];

        let dataMin = Number.MAX_VALUE;
        let dataMax = Number.MIN_VALUE;

        data && data.forEach((item) => {
            let date = Dates.fromAPIFormat(item.from, APISettings.apiDateFormat).getTime() + offset12Hours;
            if (type === 'HI') {
                date = moment.utc(item.from, APISettings.apiDateFormat).toDate().getTime();
            }
            const result = item.result ? Numbers.roundNumber(item.result, 1) : null;
            const benchmark = item.globalBenchmark ? Numbers.roundNumber(item.globalBenchmark, 1) : null;
            const industryBenchmark = item.industryBenchmark ? Numbers.roundNumber(item.industryBenchmark, 1) : null;
            const companyBenchmark = item.companyBenchmark ? Numbers.roundNumber(item.companyBenchmark, 1) : null;

            const minVal = Math.min(result, benchmark);
            dataMin = Math.min(dataMin, minVal);
            const maxVal = Math.max(result, benchmark);
            dataMax = Math.max(dataMax, maxVal);

            results.push([date, result]);
            benchmarks.push([date, benchmark]);
            industryBenchmarks.push([date, industryBenchmark]);
            companyBenchmarks.push([date, companyBenchmark]);
        });

        dataMin = Math.floor(dataMin - 0.1);
        dataMax = Math.ceil(dataMax + 0.1);
        dataMin = Math.max(min, dataMin);
        dataMax = Math.min(max, dataMax);

        let zones;
        if (type === 'ENPS') {
            zones = [
                { value: -10, color: Style.bad },
                { value: 20, color: Style.neutral },
                { value: 60, color: Style.warning },
                { color: Style.good }
            ];
        } else if (type === 'HI') {
            zones = [
                { value: 33, color: Style.bad },
                { value: 66, color: Style.warning },
                { color: Style.good }
            ];
        } else {
            zones = [
                { value: 0, color: Style.noData },
                { value: 5, color: Style.bad },
                { value: 7, color: Style.warning },
                { color: Style.good }
            ];
        }

        Charts.initializeDateFormats();

        const series: Highcharts.SeriesOptionsType[] = [
            {
                name: $translate.instant('SURVEY_RESULTS_SCORE'),
                data: results,
                zones: zones,
                color: '#0CAD00',
                connectNulls: true,
                lineWidth: 2,
                showInLegend: false
            },
            {
                type: 'line',
                name: 'Happyforce',
                data: benchmarks,
                connectNulls: true,
                color: '#4A90E2',
                dashStyle: 'Dash',
                step: 'right',
                lineWidth: 1,
                marker: {
                    enabled: false
                }
            },
            {
                type: 'line',
                name: industryName,
                data: industryBenchmarks,
                connectNulls: true,
                color: '#FFC200',
                dashStyle: 'Dash',
                step: 'right',
                lineWidth: 1,
                marker: {
                    enabled: false
                }
            }
        ];
        if (showCompanyBenchmark) {
            series.push({
                type: 'line',
                name: $translate.instant('COMPANY'),
                data: companyBenchmarks,
                connectNulls: true,
                color: '#9A9A9A',
                dashStyle: 'Dash',
                step: 'right',
                lineWidth: 1,
                marker: {
                    enabled: false
                }
            });
        }

        const chartOptions: Options = {
            chart: {
                type: 'spline',
                height: 190,
                backgroundColor: 'rgba(255, 255, 255, 0.0)',
                fontFamily: 'HelveticaNeue-Light'
            },
            title: null,
            plotOptions: {
                series: {
                    lineWidth: 4,
                    dataLabels: {
                        enabled: false
                    }
                }
            },
            xAxis: {
                type: 'datetime',
                dateTimeLabelFormats: Charts.getAxisDateFormatFromDateGrouping(dateGrouping),
                startOnTick: false,
                title: {
                    enabled: false
                }
            },
            yAxis: {
                tickColor: '#b5bbc3',
                title: {
                    enabled: false
                },
                gridLineWidth: 1,
                gridLineDashStyle: 'Dot',
                labels: {
                    style: {
                        color: '#b5bbc3'
                    }
                },
                min: dataMin,
                max: dataMax,
                tickInterval: max / 5
            },
            credits: {
                enabled: false
            },
            legend: {
                enabled: true,
                useHTML: true,
                itemDistance: 40,
                labelFormatter: function () {
                    if (this.index === 0) {
                        return this.name;
                    }

                    let icon = '';
                    if (this.visible) {
                        icon += '<img src="/images/icon-eye-visible.svg" alt="">';
                    } else {
                        icon += '<img class="legend-item-hidden" src="/images/icon-eye-hidden.svg" alt="">';
                    }

                    return this.name + ' ' + icon;
                }
            },
            tooltip: {
                enabled: true,
                pointFormat: '<span style="font-size: 10px">{series.name}: <b>{point.y}</b></span>',
                headerFormat: '<b>{point.x:' + Charts.getDateFormatFromDateGrouping(dateGrouping) + '}</b>',
                split: true,
                shared: true
            },
            series: series
        };

        return chartOptions;
    };

    useEffect(() => {
        const dataAvailable = data && data.length > 0;



        setHasData(dataAvailable);
        if (dataAvailable) {
            CompanySvrc.getCompanyIndustryName((err: any, industryName: string | null) => {
                setChartConfig(scoreLineEvolution(data, minValue, maxValue, scoreType, industryName ? industryName : 'Industry'));
            });

        } else {
            setChartConfig(null);
        }
    }, [data, dateGrouping, showCompanyBenchmark]);

    return (
        <HappyLoading loading={loading}>
            {!hasData && (
                <Typography variant="h2" className="ultra-style score-no-data p-t text-center">
                    {$translate.instant('SCORE_NO_DATA')}
                </Typography>
            )}
            {hasData && (
                <HighchartsReact highcharts={Highcharts} options={chartConfig} />
            )}
        </HappyLoading>
    );
};

export default EngagementDetailEvolutionChart;
