import * as moment from 'moment';

export default function Characteristics(Restangular, Rollbar) {
    'ngInject';
    var Company = Restangular.one('company');
    var _characteristics;

    var methods = {};

    methods.getAll = function (callback) {
        Company.one('characteristics').get({}).then(function (result, err) {
            if (err) {
                callback(err);
            } else {
                callback(null, result);
            }
        });
    };

    methods.create = function (characteristic, callback) {
        Company.customPOST(characteristic, 'characteristics').then(function (data) {
            callback(null, data);
        }, function (err) {
            callback(err);
        });
    };

    methods.remove = function (characteristicId, callback, error) {
        Company.one('characteristics').one(characteristicId).remove().then(callback, error);
    };

    methods.update = function (characteristicId, characteristic, callback) {
        Company.one('characteristics').one(characteristicId).customPUT(characteristic).then(function (data) {
            callback(null, data);
        }, function (err) {

            callback(err);
        });
    };


    methods.hasCharacteristics = function (callback) {

        if (_characteristics) {
            callback(_characteristics.length > 0);
        } else {
            methods.getAll(
                function (data) {
                    callback(_characteristics.length > 0);
                });
        }
    };

    methods.getCharacteristicName = function (_characteristic) {
        var characteristic = _characteristics.filter(function (item) {
            return item.id === _characteristic.characteristicId;
        });

        if (characteristic && characteristic.length) {
            characteristic = characteristic[0];
            characteristic.value = _characteristic.value;
            setValue(characteristic);
            return characteristic.description + ':' + characteristic.value;
        }

        return 'NO NAME';
    };


    methods.getCharacteristicNameById = function (characteristicId) {
        var characteristic = getCharacteristicOption(characteristicId);
        if (!characteristic)
        {return null;}
        else
        {return characteristic.description;}
    };

    methods.getCharacteristicValueName = function (characteristicId, valueId) {
        var characteristic = getCharacteristicOption(characteristicId);
        if (!characteristic) {return null;}

        if (characteristic.questionType === 'BOOLEAN') {
            return valueId ? characteristic.yesValue : characteristic.noValue;
        } else if (characteristic.questionType === 'LIST') {
            var value = characteristic.values.filter(function (value) {
                return value.id === valueId;
            })[0];

            return !value ? null : value.value;
        }  else if (characteristic.questionType === 'DATE') {
            var value = characteristic.ranges.filter(function (range) {
                return range.id === valueId;
            })[0];

            return !value ? null : value.name;
        }

    };

    methods.getCharacteristicById = function (id) {

        return _.find(_characteristics, function (characteristic) {
            return characteristic.id == id;
        });

    };

    methods.start = function () {
        // Initilizer
        methods.getAll(function (err, data) {
            if (err) {
                Rollbar.error('Error loading characteristics', err);
            } else {
                _characteristics = data;
                _characteristics.update = moment().add(1, 'm');
            }

        });
    };

    var operations = {};

    function getCharacteristicOption(id, field) {
        //            if (!_characteristics.length) return;
        var characteristic = _characteristics.filter(function (item) {
            return item.id === id;
        });

        if (characteristic && characteristic.length) {
            characteristic = characteristic[0];
            if (field && characteristic[field]) {
                return characteristic[field];
            } else if (!field) {
                return characteristic;
            }
        }

        return null;
    }

    operations.getCharacteristicQuestion = function (id) {
        return getCharacteristicOption(id, 'question');
    };

    operations.getCharacteristicType = function (id) {
        return getCharacteristicOption(id, 'questionType');
    };

    function setValue(characteristic) {
        if (characteristic.questionType === 'BOOLEAN') {
            characteristic.value = characteristic.value ? characteristic.yesValue : characteristic.noValue;
        } else if (characteristic.questionType === 'LIST') {
            var value = characteristic.values.filter(function (value) {
                return value.id === characteristic.value;
            })[0];

            characteristic.value = value ? value.value : null;
        }  else if (characteristic.questionType === 'DATE') {
            var range = characteristic.ranges.filter(function (range) {
                return range.id === characteristic.value;
            })[0];

            characteristic.value = range ? range.name : null;
        }
    }

    operations.getCharacteristic = function (_characteristic) {
        var characteristic = getCharacteristicOption(_characteristic.id);
        if (!characteristic) {return null;}
        characteristic.value = _characteristic.value;

        setValue(characteristic);

        return characteristic;
    };

    operations.getCharacteristicValue = function (_characteristicValue) {
        var characteristic = getCharacteristicOption(_characteristicValue.characteristicId);
        if (!characteristic) {return null;}
        characteristic.value = _characteristicValue.value;

        setValue(characteristic);

        return characteristic;
    };

    methods.operations = operations;


    if (!_characteristics) {
        methods.start();
    }

    return methods;

}
