import React, { useEffect, useState } from 'react';
import { getService } from 'reactInAngular';
import Valuation from '../../../../../utilities/valuation.utils';
import ScaleChart from '../../../../../shared/components/_migrated/scale-chart/scale-chart.component';
import EmptyState from '../../../../../shared/components/_migrated/empty_state/empty_state';
import {Box, Stack, Typography} from '@mui/material';
import HfImpactMark from '../../../components/impact_mark/HfImpactMark';
import HappyLoading from '../../../../../shared/components/_migrated/loader/loading.directive';

interface ScoreEngagementDetailOverviewProps {
    loading: boolean;
    value: number;
    quality: string;
    variation: number;
    valuation: string;
    enpsImpact: 'LOW' | 'MODERATE' | 'HIGH' | 'TOTAL';
    hiImpact: 'LOW' | 'MODERATE' | 'HIGH' | 'TOTAL';
    status: string;
}

const ScoreEngagementDetailOverview: React.FC<ScoreEngagementDetailOverviewProps> = ({ loading, value, quality, variation, valuation, enpsImpact, hiImpact, status }) => {
    const $translate = getService('$translate');
    const [slices, setSlices] = useState<any[]>([]);
    const [scoreColor, setScoreColor] = useState<string>('');
    const [shouldDisplayData, setShouldDisplayData] = useState<boolean>(false);

    useEffect(() => {
        setSlices([
            {
                color: Valuation.valuationColor('BAD', false),
                range: [0, 3],
                label: $translate.instant('HI_SLICE_FIRST')
            },
            {
                color: Valuation.valuationColor('WARNING', false),
                range: [3, 7],
                label: $translate.instant('HI_SLICE_SECOND')
            },
            {
                color: Valuation.valuationColor('EXCELLENT', false),
                range: [7, 10],
                label: $translate.instant('HI_SLICE_THIRD')
            }
        ]);

        processData();
    }, [variation, value, quality, status, valuation]);

    const processData = () => {
        setShouldDisplayData( status === 'ACTIVATED' && quality !== 'NO_DATA');
        setScoreColor(Valuation.valuationColor(valuation, false));
    };

    const getVariationText = () => {
        let key = 'SCORE_VARIATION_';
        if (variation > 0) {
            key += 'UP';
        } else if (variation < 0) {
            key += 'DOWN';
        } else {
            key += 'EQUAL';
        }

        return $translate.instant(key, { variation: variation });
    };

    return (
        <HappyLoading loading={loading}>
            <Stack gap={2}
                direction={'row'}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}>
                <Box width={'100%'}>
                    {shouldDisplayData && (
                        <Stack gap={1}>
                            <Box component={'span'} style={{color: scoreColor}}>
                                <Typography component="span" variant="bigNumber">{value}</Typography>
                                <Typography component="span" variant="subtitle1">pts</Typography>
                            </Box>
                            <ScaleChart slices={slices} value={value}/>
                            <Typography variant="body3" dangerouslySetInnerHTML={{__html: getVariationText()}}></Typography>

                        </Stack>
                    )}

                    {quality === 'NO_DATA' && (
                        <Stack gap={1}>

                            <Box component={'span'} style={{color: scoreColor}}>
                                <Typography component="span"
                                    variant="bigNumber">{$translate.instant('NO_DATA')}</Typography>
                            </Box>
                            <Typography variant="body3">
                                {$translate.instant('SCORE_NO_DATA_SHORT_DESCRIPTION')}
                            </Typography>

                        </Stack>

                    )}


                    {status === 'DISABLED' && (


                        <EmptyState
                            small={true}
                            image="'images/no_scores.svg'"
                            message={$translate.instant('SCORE_DEACTIVATED', {})}
                            submessage={$translate.instant('SCORE_DEACTIVATED_DESCRIPTION', {})}
                        />
                    )}
                </Box>



                <Stack gap={1} width={'50%'}>
                    <Box width={'100%'} alignItems={'center'} display={'flex'} justifyContent={'center'}>
                        <Typography variant={'body3'}
                            fontWeight={'bold'}>{$translate.instant('ENGAGEMENT_IMPACT_HI')}</Typography>
                    </Box>
                    {hiImpact && <HfImpactMark level={hiImpact} color={scoreColor}/>}
                    <Box width={'100%'} alignItems={'center'} display={'flex'} justifyContent={'center'}>
                        <Typography variant={'body3'}
                            fontWeight={'bold'}>{$translate.instant('ENGAGEMENT_IMPACT_ENPS')}</Typography>

                    </Box>
                    {enpsImpact && <HfImpactMark level={enpsImpact} color={scoreColor}/>}
                </Stack>


            </Stack>
        </HappyLoading>

    );
};

export default ScoreEngagementDetailOverview;
