import React, { useState, useEffect } from 'react';
import { getService } from 'reactInAngular';
import {Alert, Box, Stack, Typography} from '@mui/material';

interface MeasuringNowBoxProps {
    to: Date;
}

const MeasuringNowBox: React.FC<MeasuringNowBoxProps> = ({ to }) => {
    const [days, setDays] = useState<number | null>(null);

    const $translate = getService('$translate');

    useEffect(() => {
        if (!to) {
            return;
        }

        const currentDate = new Date();
        const lastDayOfMonth = new Date(to.getFullYear(), to.getMonth() + 1, 0);

        setDays(lastDayOfMonth.getDate() - currentDate.getDate());
    }, [to]);

    return (

        <Alert severity={'warning'} icon={<></>}>
            <Stack  gap={2} direction={'row'}>
                <Box component={'img'}
                    width={'25%'}
                    maxWidth={'32px'}
                    src={'/images/info_icon.svg'}
                    alt="Ranking"/>

                <Stack gap={2}>

                    <Typography variant="h4">
                        {$translate.instant('ENGAGEMENT_MEASURING_NOW')}
                    </Typography>

                    <Typography variant="body3">
                        {$translate.instant('ENGAGEMENT_MEASURING_NOW_REMAINING_DAYS', { days })}
                    </Typography>

                    <Typography
                        variant="caption"
                        dangerouslySetInnerHTML={{ __html: $translate.instant('ENGAGEMENT_MEASURING_NOW_GO_TO_PREVIOUS') }}
                    />
                </Stack>
            </Stack>

        </Alert>
    );
};

export default MeasuringNowBox;
