import React, {useEffect, useState } from 'react';
import { getService } from 'reactInAngular';
import Dates from '../../../../../utilities/date.utilities';
import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Grid,
    DialogContentText
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import CloseableDialogTitle from '../../../../../shared/new-components/hf-closeable-dialog-title/CloseableDialogTitle';

interface HIReportModalProps {
    to: Date;
    show: boolean;
    onClose: (from?: Date, to?: Date) => void;
}

const HIReportModal: React.FC<HIReportModalProps> = ({ to, show, onClose }) => {
    const $translate = getService('$translate');

    const [ranges, setRanges] = useState({
        from: null,
        to: null,
    });

    const handleDateChange = (type: 'from' | 'to', value: Date | null) => {
        setRanges({ ...ranges, [type]: value });
    };

    const handleOk = () => {
        onClose(moment(ranges.from).toDate(), moment(ranges.to).toDate());
    };

    const handleCancel = () => {
        onClose();
    };


    useEffect(() => {
        if (to) {
            setRanges({
                from: Dates.firstDayOfMonth(Dates.substractDoDate(to, 12, 'months')),
                to: Dates.lastDayOfMonth(to),
            });
        }

    }, [to]);

    return (
        <Dialog open={show} onClose={handleCancel}>
            <CloseableDialogTitle
                title={$translate.instant('HI_REPORT_MODAL_TITLE')}
                onClose={onClose}
            />
            <DialogContent>
                <DialogContentText>
                    {$translate.instant('HI_REPORT_MODAL_DESCRIPTION')}
                </DialogContentText>
                <Grid container sx={theme => ({ paddingTop: theme.spacing(2) })}>
                    <Grid item xs={6}>
                        <DatePicker
                            label={$translate.instant('FROM')}
                            onChange={value => handleDateChange('from', value)}
                            defaultValue={moment(ranges.from)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DatePicker
                            label={$translate.instant('TO')}
                            onChange={value => handleDateChange('to', value)}
                            defaultValue={moment(ranges.to)}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="secondary" onClick={handleCancel}>
                    {$translate.instant('CANCEL')}
                </Button>
                <Button variant="contained" onClick={handleOk} color="primary">
                    {$translate.instant('EXPORT_EXCEL')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default HIReportModal;
