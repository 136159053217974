import { useEffect, useState } from 'react';
import { getService } from 'reactInAngular';
import { NestedMenuItem } from 'mui-nested-menu';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Dates from '../../../../utilities/date.utilities';
import { ListItemText, Typography } from '@mui/material';
import { DateSelected } from '../types';
import { StaticDatePicker } from '@mui/x-date-pickers';
import moment, {Moment} from 'moment';

interface DateNestedMenuItemProps {
    open?: boolean;
    filters: Date;
    onAddFilter: (type: string, filters: Date) => void;
    title: string;
    type: string;
    nonRemovable: boolean;
    //mode?: string;
}

const DateNestedMenuItem = ({ open, filters, onAddFilter, title, type, nonRemovable }: DateNestedMenuItemProps) => {

    const handleDateChante = (date: Moment) => {
        if (date && date.isValid() && date.year() > 1900) {

            onAddFilter(type, date.toDate());
        }
    };

    const renderDate = () => {
        return (

            <StaticDatePicker value={moment(filters)}  onChange={handleDateChante} />

        );
    }

    return (
        <NestedMenuItem
            renderLabel={() => <ListItemText primary={
                <Typography style={{ fontSize: '14px', paddingLeft: 10 }}>
                    {title}
                </Typography>
            } />}
            parentMenuOpen={open}
        >
            {renderDate()}
        </NestedMenuItem>
    );
};

export default DateNestedMenuItem;
