

import React, { useEffect, useState } from 'react';
import { angularize, getService } from 'reactInAngular';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import angular from 'angular';
import {Stack} from '@mui/material';
import HappyLoading from '../../../../shared/components/_migrated/loader/loading.directive';

interface EngagementRankingBoxProps {
    loading: boolean;
    scoreId?: string;
    ranking: number;
    type: string;
}

const EngagementRankingBox: React.FC<EngagementRankingBoxProps> = ({ loading, scoreId, ranking, type }) => {
    const [rankingImg, setRankingImg] = useState<string | null>(null);
    const [rankingLabel, setRankingLabel] = useState<string | null>(null);


    const updateRankingLabelAndImage = () => {
        if (ranking) {
            const RankingService = getService('RankingService');
            const $translate = getService('$translate');
            const Languages = getService('Languages');
            const ScoresV2Srvc = getService('ScoresV2Srvc');

            setRankingImg(RankingService.getRankingImage(ranking));

            if (type === 'HI' || type === 'ENPS') {
                setRankingLabel(
                    $translate.instant(
                        RankingService.getRankingLabel(ranking),
                        { name: (type === 'HI' ? 'HI' : 'eNPS') }
                    )
                );
            } else {
                ScoresV2Srvc.getScore(scoreId, (err, score) => {
                    setRankingLabel(
                        $translate.instant(
                            RankingService.getRankingLabel(ranking),
                            { name: Languages.getTranslationFromUserLanguage(score.name) }
                        )
                    );
                });
            }
        }
    };

    useEffect(() => {
        updateRankingLabelAndImage();
    }, [ranking]);

    return (
        <HappyLoading loading={loading}>
            <Stack gap={2}>
                <Box display="flex" justifyContent="center">
                    <Box component={'img'}  width={'25%'} maxWidth={'60px'} src={rankingImg || ''} alt="Ranking" />
                </Box>
                <Box mt={2} display={'flex'} textAlign={'center'}>
                    <Typography variant="body3" dangerouslySetInnerHTML={{ __html: rankingLabel || '' }} />
                </Box>

            </Stack>

        </HappyLoading>

    );
};

angularize(EngagementRankingBox, 'hfEngagementRankingBox', angular.module('happyForceApp'), {
    scoreId: '=',
    ranking: '=',
    type: '='
});

export default EngagementRankingBox;
