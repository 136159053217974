import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { angularize, getService } from 'reactInAngular';
import Dates from '../../../../../utilities/date.utilities';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Grid,
    DialogContentText,
    Typography,
    FormControl,
    RadioGroup, FormControlLabel, Radio, Stack, InputLabel, Select, MenuItem, SelectChangeEvent
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import angular from 'angular';
import { getScoreReport } from '../../../../../shared/services/migrated/reports.service'
import {toSegmentParams} from '../../../shared/utils';


interface ScoreReportModalProps {
    scoreId: string;
    to: Date;
    show: boolean;
    segmentIds: any[];
    groupIds: string[];
    hierarchyIds: string[];
    onClose: () => void;
}

const ScoreReportModal = ({ scoreId, to, segmentIds, groupIds, hierarchyIds, show, onClose }: ScoreReportModalProps) => {

    const $translate = getService('$translate');
    const ErrorSvrc = getService('ErrorSvrc');
    const toaster = getService('toaster');

    const [selectedOption, setSelectedOption] = useState('CURRENT');

    const [selectedGrouping, setSelectedGrouping] = useState('HIERARCHIES');



    const [ranges, setRanges] = useState({
        from: Dates.firstDayOfMonth(Dates.substractDoDate(to, 12, 'months')),
        to: Dates.lastDayOfMonth(to),
    });

    const handleDateChange = (type, value)  => {
        setRanges({ ...ranges, [type]: value });
    };

    const handleOk = () => {

        const reportParams = {
            from: moment(ranges.from).toDate(),
            to: moment(ranges.to).toDate()
        }

        if (selectedOption === 'CURRENT') {
            reportParams.groupId = groupIds;
            reportParams.hierarchyId = hierarchyIds;
            reportParams.filters = toSegmentParams(segmentIds as any[]);

        } else {
            reportParams.groupingType =  selectedGrouping;
        }


        getScoreReport(scoreId, reportParams, function (err, result) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                toaster.pop('success', null, $translate.instant('ENGAGEMENT_EXPORT_SENT'));
            }
            onClose();
        });

    };

    const handleCancel = () => {
        onClose();
    };


    const handleReportTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedOption((event.target as HTMLInputElement).value);
    };

    const handleSegmentationReportTypeChange = (event: SelectChangeEvent) => {
        setSelectedGrouping(event.target.value as string);
    };

    // TODO: The localization provider should be in the root of the app
    return (
        <Dialog open={show} onClose={handleCancel}>

            <DialogTitle>
                <Typography variant={'h4'}>
                    {$translate.instant('SCORE_REPORT_MODAL_TITLE')}
                </Typography>

            </DialogTitle>
            <DialogContent>

                <Stack gap={2}>

                    <DialogContentText>
                        <Typography variant={'body2'}>
                            {$translate.instant('SCORE_REPORT_MODAL_DESCRIPTION')}
                        </Typography>
                    </DialogContentText>


                    <FormControl component="fieldset">
                        <RadioGroup
                            aria-label="opciones"
                            name="opcion"
                            value={selectedOption}
                            onChange={handleReportTypeChange}
                        >
                            <FormControlLabel
                                value="CURRENT"
                                control={<Radio />}
                                label={<Typography variant={'caption'}>{$translate.instant('SCORE_REPORT_MODAL_EXPORT_CURRENT_SEGMENTATION')}</Typography>}
                            />
                            <FormControlLabel
                                value="GROUPING"
                                control={<Radio />}
                                label={<Typography variant={'caption'}>{$translate.instant('SCORE_REPORT_MODAL_EXPORT_ALL_BY_SEGMENTATION_TYPE')}</Typography>}
                            />
                        </RadioGroup>
                    </FormControl>


                    {selectedOption === 'GROUPING' && (
                        <>
                            <Typography variant={'caption'}>
                                {$translate.instant('SCORE_REPORT_MODAL_EXPORT_SELECT_SEGMENTATION')}
                            </Typography>
                            <FormControl fullWidth>
                                <InputLabel id="option-select-label">{$translate.instant('SCORE_REPORT_MODAL_EXPORT_SELECT_OPTION')}</InputLabel>
                                <Select
                                    labelId="option-select-label"
                                    id="option-select"
                                    value={selectedGrouping}
                                    label="Elige una opción"
                                    onChange={(event) => {handleSegmentationReportTypeChange(event)}}
                                >
                                    <MenuItem value="HIERARCHIES">{$translate.instant('AREAS')}</MenuItem>
                                    <MenuItem value="CHARACTERISTICS">{$translate.instant('PROFILE')}</MenuItem>
                                    <MenuItem value="GROUPS">{$translate.instant('GROUPS')}</MenuItem>
                                </Select>
                            </FormControl>
                        </>


                    )}


                    <Grid container sx={theme => ({'paddingTop': theme.spacing(2)})}>
                        <Grid item xs={6}>
                            <DatePicker
                                label={$translate.instant('FROM')}
                                onChange={value => handleDateChange('from', value)}
                                defaultValue={moment(ranges.from)} />
                        </Grid>
                        <Grid item xs={6}>
                            <DatePicker
                                label={$translate.instant('TO')}
                                onChange={value => handleDateChange('to', value)}
                                defaultValue={moment(ranges.to)} />
                        </Grid>
                    </Grid>
                </Stack>




            </DialogContent>
            <DialogActions>
                <Button variant={'contained'} color={'secondary'} onClick={handleCancel}>
                    {$translate.instant('CANCEL')}
                </Button>
                <Button variant={'contained'} onClick={handleOk} color="primary">
                    {$translate.instant('EXPORT_EXCEL')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ScoreReportModal;
