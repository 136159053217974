import React, { useState, useEffect } from 'react';
import { getService } from 'reactInAngular';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import Numbers from '../../../../../utilities/number.utilities';
import Valuation from '../../../../../utilities/valuation.utils';
import EngagementDetailCharts from '../../../engagement_detail.charts';
import { getENPSConfiguration } from '../../../../../shared/services/migrated/enps.service';
import HappyLoading from '../../../../../shared/components/_migrated/loader/loading.directive';
import HFTag from '../../../../../shared/new-components/hf-tag/hf-tag';
import {Grid, Stack, Typography} from '@mui/material';
import HfMultiLanguageText from '../../../../../shared/new-components/hf-multilanguage-text/hfMultilanguageText';
import EmptyState from '../../../../../shared/components/_migrated/empty_state/empty_state';

interface ENPSVotesDistributionProps {
    data: any[];
    noData: boolean;
    loading: boolean;
}

const ENPSVotesDistribution: React.FC<ENPSVotesDistributionProps> = ({ data, noData, loading }) => {
    const [quality, setQuality] = useState<string>('NO_DATA');
    const [questionData, setQuestionData] = useState<{
        title: string;
        first: string;
        last: string;
        type: string;
    } | null>(null);
    const [result, setResult] = useState<number | null>(null);
    const [averageResult, setAverageResult] = useState<number | null>(null);
    const [tagStatus, setTagStatus] = useState<string | null>(null);
    const [questionResultsChart, setQuestionResultsChart] = useState<Highcharts.Options | null>(null);

    const ErrorSvrc = getService('ErrorSvrc');
    const $translate = getService('$translate'); // Assuming $translate is a service

    const processData = () => {
        if (data && data.length > 0 && questionData) {
            const lastResult = data.slice(-1)[0];
            if (lastResult.quality !== 'NO_DATA_') {
                setQuality(lastResult.quality);
                setResult(Numbers.roundNumber(lastResult.result, 1));
                setAverageResult(Numbers.roundNumber(lastResult.averageResult, 1));
                setTagStatus(Valuation.enpsTagType(lastResult.result, lastResult.averageResult, lastResult.valuation, true));
                const questionResults = {
                    distribution: lastResult.distribution,
                    participants: lastResult.participants
                };

                const chartConfig = EngagementDetailCharts.scoreQuestionChart(questionData, questionResults);

                setQuestionResultsChart(chartConfig);
            } else {
                setQuestionResultsChart(null);
                setResult(null);
            }
        } else {
            setQuestionResultsChart(null);
            setResult(null);
        }
    };

    useEffect(() => {
        processData();
    }, [data, noData, questionData]);

    useEffect(() => {
        getENPSConfiguration((err, enpsConfiguration) => {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                setQuestionData({
                    title: enpsConfiguration.title,
                    first: enpsConfiguration.first,
                    last: enpsConfiguration.last,
                    type: 'RANGE'
                });
            }
        });
    }, []);


    const renderTitleColumnContent = () => {

        return (
            <Stack gap={2} alignItems={'flex-start'} display={'flex'} justifyContent={'center'}>
                <Typography variant="body2" >
                    <HfMultiLanguageText multilanguage={questionData?.title} />
                </Typography>

                {quality !== 'NO_DATA' && !noData && (
                    <HFTag
                        status={tagStatus}
                        text={$translate.instant('ENPS_AVERAGE_RESULT', { result: averageResult })}
                    />
                )}

                {(quality === 'NO_DATA' || noData) && (
                    <HFTag
                        status={'neutral'}
                        text={$translate.instant('NO_DATA')}
                    />
                )}
            </Stack>
        );

    }

    const renderChart = () => {

        if (questionResultsChart && !noData) {
            return (<HighchartsReact
                highcharts={Highcharts}
                options={questionResultsChart}/>);
        } else {
            return (
                <EmptyState
                    image="images/empty_state_chart.png"
                    small={true}
                    message={$translate.instant('NO_DATA')}
                    submessage={$translate.instant('SCORE_NO_DATA_DESCRIPTION')}
                />
            )
        }
    }

    return (
        <HappyLoading loading={loading} message={$translate.instant('LOADING_DATA_SENTENCE_1')}>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={5}>
                    {renderTitleColumnContent()}
                </Grid>

                <Grid item xs={12} sm={7}>
                    {renderChart()}

                </Grid>
            </Grid>

        </HappyLoading>
    );
};

export default ENPSVotesDistribution;
