

import React, {useEffect, useMemo, useState} from 'react';
import moment from 'moment/moment';
import {
    CommonSegmentationFilters,
    FilterSelected,
    GroupFiltersConfig,
    HierarchyFiltersConfig,
    SegmentFiltersConfig
} from '../../../shared/new-components/hf-filter-menu/types';
import {DataQuality, DateGrouping} from '../../../shared/types/common';
import {getService} from '../../../../migration_utils/react-in-angular';
import {Box, ButtonGroup, Grid, Stack, Typography} from '@mui/material';
import HfFilterMenu from '../../../shared/new-components/hf-filter-menu/hfFilterMenu';
import EngagementDateBrowser from '../shared/date_browser/EngagementDateBrowser';
import {WhiteBgPaper} from '../../../shared/new-components/hf-styled-components/WhiteBgPaddedPapper';
import HfDateGroupingFormat from '../../../shared/new-components/hf-date-format/hfDateGroupingFormat';
import EmptyState from '../../../shared/components/_migrated/empty_state/empty_state';

import Dates from '../../../utilities/date.utilities';
import {getENPSStats} from '../../../shared/services/migrated/enps.service';
import Numbers from '../../../utilities/number.utilities';
import {toSegmentParams} from '../shared/utils';
import { APISettings } from '../../../shared/services/migrated/axiosWrapper';
import HFSwitch from '../../../shared/new-components/hf-switch/hf-switch.component';
import EnpsDetailOverview from './components/overview/EnpsDetailOverview';
import EngagementDetailEnpsEvolution from './components/enps_evolution_chart/EnpsEvolutionChart';
import EngagementDetailEnpsBenchmark from './components/enps_benchmark/EngagementDetailEnpsBenchmark';
import EngagementScoresFeedbackBox from '../components/feedback_box/EngagementFeedbackBox';
import HFInfoIconWithTooltip from '../../../shared/new-components/hf-info-icon-with-tooltip';
import ENPSFunnelBox from './components/enps_funnel_box/ENPSFunnelBox';
import ENPSVotesDistribution from './components/votes_distribution/EnpsVotesDistribution';
import Button from '@mui/material/Button';
import EngagementEnpsIndividualHeatmapBox from './components/enps_individual_heatmap/ENPSIndividualHeatmap';
import EnpsPositioningBox from './components/positioning_box/ENPSPositioningChart';
import QualityPopoverText from '../shared/quality_popover/EngagementQualityPopover';
import MeasuringNowBox from '../shared/measuring_now_box/EngagementMeasuringNow';
import ScoresImpactChart from '../shared/scores_impact_chart/ScoresImpactChart';
import EngagementDetailDrawer from '../shared/big-drawer/EngagementDetailDrawer';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import ENPSReportModal from './components/enps_report_modal/ENPSReportModal';




export interface ENPSDetailShowParams {
    visible: boolean;
    to: Date;
    dateGrouping: DateGrouping;
    filters: CommonSegmentationFilters;
}


interface ENPSDetailDrawerProps {

    showParams: ENPSDetailShowParams;
    onClose: () => void;

}

const ENPSDetailDrawer = ({showParams, onClose}: ENPSDetailDrawerProps) => {

    const $translate = getService('$translate');
    const ErrorSvrc = getService('ErrorSvrc');


    const Filters = getService('Filters');

    const [filters, setFilters] = useState<FilterSelected>({
        hierarchyIds: [],
        segmentIds: [],
        groupIds: [],
    });


    const [to, setTo] = useState<Date>(null);
    const [from, setFrom] = useState(null);
    const [dateGrouping, setDateGrouping] = React.useState<DateGrouping>(DateGrouping.QUARTER);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [measuringNow, setMeasuringNow] = React.useState<boolean>(false);
    const [hasEnoughActiveEmployees, setHasEnoughActiveEmployees] = useState(true);

    const [selectedENPSMode, setSelectedENPSMode] = useState('ENPS_MODE');

    const [filtered, setFiltered] = useState<boolean>(false);
    const [quality, setQuality] = useState<DataQuality | null>(null);
    const [valuation, setValuation] = useState<string | null>(null);
    const [value, setValue] = useState<number | null>(null);
    const [averageValue, setAverageValue] = useState<number | null>(null);
    const [variation, setVariation] = useState<number | null>(null);
    const [averageVariation, setAverageVariation] = useState<number | null>(null);
    const [measureDate, setMeasureDate] = useState<string | null>(null);
    const [companyBenchmark, setCompanyBenchmark] = useState<number | null>(null);
    const [companyAverageBenchmark, setCompanyAverageBenchmark] = useState<number | null>(null);
    const [industryBenchmark, setIndustryBenchmark] = useState<number | null>(null);
    const [industryAverageBenchmark, setIndustryAverageBenchmark] = useState<number | null>(null);
    const [globalBenchmark, setGlobalBenchmark] = useState<number | null>(null);
    const [globalAverageBenchmark, setGlobalAverageBenchmark] = useState<number | null>(null);
    const [ranking, setRanking] = useState<number | null>(null);

    const [previousValue, setPreviousValue] = useState<number | null>(null);
    const [showReportModal, setShowReportModal] = useState<boolean>(false);

    const [results, setResults] = useState([]);
    const [noData, setNoData] = useState(false);
    const [currentClassification, setCurrentClassification] = useState({});
    const [measureFromDate, setMeasureFromDate] = useState<Date>(null);
    const [measureToDate, setMeasureToDate] = useState<Date>(null);
    const [totalClassified, setTotalClassified] = useState(0);
    const [summaryView, setSummaryView] = useState(1);
    const [showDrawer, setShowDrawer] = useState<boolean>(false);


    const hierarchyFiltersConfig: HierarchyFiltersConfig = useMemo(() => {
        return {
            id: 'HIERARCHY',
            type: 'hierarchyIds',
            mode: 'single',
            title: $translate.instant('AREA'),
            nonRemovable: false,
        }
    }, []);


    const groupFiltersConfig: GroupFiltersConfig = useMemo(() => {
        return {
            id: 'GROUP',
            type: 'groupIds',
            mode: 'multi',
            title: $translate.instant('GROUPS'),
            nonRemovable: false,
            groups: null,
        }
    }, []);


    const segmentFiltersConfig: SegmentFiltersConfig = useMemo(() => {
        return {
            id: 'SEGMENT',
            type: 'segmentIds',
            mode: 'multi',
            title: $translate.instant('SEGMENTED'),
            nonRemovable: false,
            segments: null
        }

    }, []);


    const prepareParams = () => {
        const params = {
            to: Dates.lastDayOfMonth(to),
            filters: toSegmentParams(filters?.segmentIds as any[]),
            groupId: filters?.groupIds,
            hierarchyId: filters?.hierarchyIds,
            grouping: dateGrouping,
        };

        return params;
    }


    const loadENPS = () => {

        setLoading(true);
        setHasEnoughActiveEmployees(true);

        const params = prepareParams();


        console.log('to', to);

        getENPSStats(params, function (err, response) {
            if (err) {
                if (err) {
                    if (ErrorSvrc.isNotEnoughActiveEmployees(err)) {
                        setHasEnoughActiveEmployees(false);
                    } else {
                        ErrorSvrc.showErrorModal(err);
                    }
                }
            } else {
                const numResults = response.results.length;
                const current = response.results[numResults - 1];

                setResults(response.results);

                setQuality(current.quality);
                if (response.results.length > 0 && current.quality != 'NO_DATA') {
                    const value = current.result;
                    const variation = current.variation;
                    const averageValue = current.averageResult;
                    const averageVariation = current.averageVariation;

                    setFiltered(response.filtered);

                    setValuation(current.valuation);
                    setValue(Numbers.roundNumber(value, 1));
                    setAverageValue(Numbers.roundNumber(averageValue, 1));
                    setVariation(Numbers.roundNumber(variation, 1));
                    setAverageVariation(Numbers.roundNumber(averageVariation, 1));
                    setMeasuringNow(current.measuringNow);
                    setMeasureDate(current.from);
                    setCompanyBenchmark(current.companyBenchmark);
                    setCompanyAverageBenchmark(current.companyAverageBenchmark);
                    setIndustryBenchmark(current.industryBenchmark);
                    setIndustryAverageBenchmark(current.industryAverageBenchmark);
                    setGlobalBenchmark(current.globalBenchmark);
                    setGlobalAverageBenchmark(current.globalAverageBenchmark);
                    setRanking(current.ranking);

                    if (numResults > 1) {
                        const previousValue = response.results[numResults - 2].result;
                        setPreviousValue(Numbers.roundNumber(previousValue, 1));
                    }

                    setNoData(false);
                    setMeasureFromDate(Dates.fromAPIFormat(current.from, APISettings.apiDateFormat));
                    setMeasureToDate(Dates.fromAPIFormat(current.to, APISettings.apiDateFormat));
                    setCurrentClassification(current.classification);
                    let totalClassified = 0;
                    Object.keys(current.classification).forEach(function (classification) {
                        totalClassified += current.classification[classification];
                    });
                    setTotalClassified(totalClassified);
                } else {
                    setValue(null);
                    setMeasuringNow(false);
                    setNoData(true);
                    setCurrentClassification({});

                    setMeasureFromDate(null);
                    setMeasureToDate(null);

                }

            }
            setLoading(false);
        });
    };



    useEffect(() => {
        if (showParams?.visible) {
            setShowDrawer(true);
            setFilters(showParams.filters);
            const toValue = showParams.to ? showParams.to : Filters.endDate();
            setTo( toValue);
        } else {
            setShowDrawer(false);
        }
    }, [showParams]);



    useEffect(() => {
        const newFrom = moment(to).subtract(4, 'Q').toDate();
        setFrom(newFrom);
    }, [to]);


    useEffect(() => {
        if (showDrawer && to) {
            loadENPS();
        }
    }, [showDrawer, to, filters, dateGrouping]);


    return (

        <EngagementDetailDrawer
            open={showDrawer}
            onClose={onClose}
            title={

                <Stack gap={1} width={'100%'} >



                    <Box display="flex" flexDirection="row" alignItems="center">
                        <Box flex="1">
                            <Stack direction="row" gap={0}>
                                <Typography variant="h1" noWrap>
                                    {$translate.instant('ENGAGEMENT_ENPS_SECTION_TITLE')}
                                </Typography>
                            </Stack>

                            <Typography variant="body3" color="text.secondary">
                                {$translate.instant('ENGAGEMENT_ENPS_SECTION_DESCRIPTION')}
                            </Typography>
                        </Box>

                        <Box>

                            <Stack direction="row" spacing={2}>

                                <EngagementDateBrowser
                                    to={to}
                                    dateGrouping={dateGrouping}
                                    onDateGroupingChange={setDateGrouping}
                                    showDateGrouping={true}
                                    onDateChange={setTo}
                                    showYears={true}
                                    showQuarters={true}
                                    showMonths={false}
                                />


                                <Button
                                    variant="contained"
                                    startIcon={<FileDownloadRoundedIcon />}
                                    onClick={() => setShowReportModal(true)}
                                >
                                </Button>


                            </Stack>

                        </Box>

                    </Box>

                    <HfFilterMenu
                        segmentFiltersConfig={segmentFiltersConfig}
                        hierarchyFiltersConfig={hierarchyFiltersConfig}
                        groupFiltersConfig={groupFiltersConfig}
                        selectedFilters={filters}
                        updateFilters={setFilters}
                    />
                </Stack>






            }
        >

            <Stack gap={2}>



                {measuringNow && (
                    <MeasuringNowBox
                        to={to}/>
                )}


                <WhiteBgPaper>

                    <Stack gap={1}>
                        <Stack gap={1}>
                            <Stack direction="row" alignItems="center" justifyContent="flex-start" gap={1}>
                                <Typography variant="h1">
                                    <HfDateGroupingFormat format={dateGrouping} date={to}/>
                                </Typography>

                                <HFSwitch firstLabel={$translate.instant('ENPS_MODE')}
                                    firstValue={'ENPS_MODE'}
                                    secondLabel={$translate.instant('ENPS_AVERAGE_MODE')}
                                    secondValue={'AVERAGE_MODE'}
                                    selected={selectedENPSMode}
                                    onSelect={(value) => setSelectedENPSMode(value as string)}/>
                            </Stack>

                            <QualityPopoverText quality={quality}/>
                        </Stack>

                        {!hasEnoughActiveEmployees && (
                            <EmptyState
                                small={true}
                                image="images/anonymous.png"
                                message={$translate.instant('NOT_ENOUGH_EMPLOYEES', {})}
                                submessage={$translate.instant('NOT_ENOUGH_EMPLOYEES_SUBMESSAGE', {})}
                            />
                        )}

                        {hasEnoughActiveEmployees && (
                            <Grid container spacing={2}>

                                <Grid item xs={12} md={4}>

                                    <EnpsDetailOverview
                                        loading={loading}
                                        value={value}
                                        averageValue={averageValue}
                                        valuation={valuation}
                                        variation={variation}
                                        averageVariation={averageVariation}
                                        measureDate={measureDate}
                                        noData={noData}
                                        quality={quality}
                                        hasEnoughActiveEmployees={hasEnoughActiveEmployees}
                                        mode={selectedENPSMode}
                                    />
                                </Grid>


                                <Grid item xs={12} md={6} sm={8}>

                                    <EngagementDetailEnpsEvolution
                                        loading={loading}
                                        hasEnoughActiveEmployees={hasEnoughActiveEmployees}
                                        showCompanyBenchmark={filtered}
                                        data={results}
                                        mode={selectedENPSMode}
                                        dateGrouping={dateGrouping}/>
                                </Grid>

                                <Grid item xs={12} md={2} sm={12}>

                                    <EngagementDetailEnpsBenchmark
                                        showCompanyBenchmark={filtered}
                                        noData={noData}
                                        mode={selectedENPSMode}
                                        loading={loading}
                                        industryBenchmark={industryBenchmark}
                                        globalBenchmark={globalBenchmark}
                                        companyBenchmark={companyBenchmark}
                                        ranking={ranking}
                                        value={value}
                                        averageValue={averageValue}

                                        industryAverageBenchmark={industryAverageBenchmark}
                                        globalAverageBenchmark={globalAverageBenchmark}
                                        companyAverageBenchmark={companyAverageBenchmark}/>
                                </Grid>
                            </Grid>
                        )}



                    </Stack>
                </WhiteBgPaper>


                <Grid container spacing={2}>
                    {hasEnoughActiveEmployees && (
                        <Grid item xs={12} md={9}>

                            <Stack direction="column" spacing={2}>

                                <WhiteBgPaper>

                                    <Stack direction="column" spacing={2}>

                                        <Stack direction="column" spacing={1}>
                                            <Stack direction="row">
                                                <Typography variant="h4">
                                                    {$translate.instant('ENPS_CLASSIFICATION')}
                                                </Typography>

                                                <HFInfoIconWithTooltip
                                                    tooltipContent={$translate.instant('ENPS_CLASSIFICATION_DESCRIPTION')}
                                                    icon={'INFO'}
                                                    color={'primary'}
                                                />
                                            </Stack>

                                            <Typography variant="caption">

                                                {$translate.instant('ENPS_DATA_QUARTER_PARTICIPANTS', {
                                                    votes: totalClassified,
                                                    quarter: Dates.formatDateFromGrouping(measureFromDate, dateGrouping)
                                                })}

                                            </Typography>
                                        </Stack>

                                        <ENPSFunnelBox
                                            to={measureToDate}
                                            dateGrouping={dateGrouping}
                                            filters={filters}
                                            currentClassification={currentClassification}/>
                                    </Stack>


                                </WhiteBgPaper>

                                <WhiteBgPaper>

                                    <ENPSVotesDistribution
                                        data={results}
                                        loading={loading}
                                        noData={noData}/>


                                </WhiteBgPaper>

                                <WhiteBgPaper>

                                    <Stack direction="column" spacing={2}>

                                        <Box display={'flex'}
                                            width={'100%'}
                                            alignItems={'center'}
                                            justifyContent={'center'}>
                                            <ButtonGroup variant="outlined" aria-label="Basic button group">
                                                <Button
                                                    variant={summaryView === 1 ? 'contained' : 'outlined'}
                                                    onClick={() => setSummaryView(1)}
                                                >
                                                    {$translate.instant('ENPS_INDIVIDUALS_MAP')}
                                                </Button>
                                                <Button
                                                    variant={summaryView === 2 ? 'contained' : 'outlined'}
                                                    onClick={() => setSummaryView(2)}
                                                >
                                                    {$translate.instant('ENPS_POSITIONING')}
                                                </Button>

                                                <Button
                                                    variant={summaryView === 3 ? 'contained' : 'outlined'}
                                                    onClick={() => setSummaryView(3)}
                                                >
                                                    {$translate.instant('ENPS_SCORES_IMPACT')}
                                                </Button>
                                            </ButtonGroup>
                                        </Box>

                                        {summaryView === 1 && (
                                            <EngagementEnpsIndividualHeatmapBox
                                                to={to}
                                                filters={filters}
                                                noData={noData}
                                                data={results}
                                                noData={noData}/>
                                        )}

                                        {summaryView === 2 && (
                                            <EnpsPositioningBox
                                                to={to}
                                                noData={noData}
                                                mode={selectedENPSMode}
                                                navigateToDetail={() => {}}
                                            />
                                        )}

                                        {summaryView === 3 && (
                                            <ScoresImpactChart
                                                from={from}
                                                to={to}
                                                noData={noData}
                                                filters={filters}
                                                dateGrouping={dateGrouping}
                                                type={'ENPS'}/>
                                        )}


                                    </Stack>


                                </WhiteBgPaper>

                            </Stack>

                        </Grid>
                    )}

                    <Grid item xs={12} md={hasEnoughActiveEmployees ? 3 : 12}>
                        <WhiteBgPaper sx={{height: '100%'}}>
                            <EngagementScoresFeedbackBox
                                hasEnoughActiveEmployees={hasEnoughActiveEmployees}
                                mode={'ENPS'}
                                filters={filters}
                                dateGrouping={dateGrouping}
                                to={to}/>
                        </WhiteBgPaper>
                    </Grid>
                </Grid>


            </Stack>


            <ENPSReportModal
                to={to}
                show={showReportModal}
                segmentIds={filters?.segmentIds}
                groupIds={filters?.groupIds}
                hierarchyIds={filters?.hierarchyIds}
                onClose={() => {
                    setShowReportModal(false);
                }}></ENPSReportModal>

        </EngagementDetailDrawer>


    );
}

export default ENPSDetailDrawer;
