import React, { useState, useEffect } from 'react';
import HighchartsReact from 'highcharts-react-official';
import { getService } from 'reactInAngular';
import Highcharts from 'highcharts';
import {Select, MenuItem, FormControl, InputLabel, Box, Pagination, Stack, Typography} from '@mui/material';
import Dates from '../../../../../utilities/date.utilities';
import Style from '../../../../../utilities/style.utils';
import Valuation from '../../../../../utilities/valuation.utils';
import Charts from '../../../../../utilities/charts.utilities';
import { FilterSelected } from '../../../../../shared/new-components/hf-filter-menu/types';
import {toSegmentParams} from '../../../shared/utils';
import HappyLoading from '../../../../../shared/components/_migrated/loader/loading.directive';
import PersonEngagementStatusContextModal from '../../../../../shared/components/_migrated/engagement_context_modal/PersonEngagementContextModal';

interface EngagementHiIndividualHeatmapBoxProps {
    to: Date;
    filters: FilterSelected;
}

const EngagementHiIndividualHeatmapBox: React.FC<EngagementHiIndividualHeatmapBoxProps> = ({ to, filters }) => {
    const [displayBy, setDisplayBy] = useState<string>('CALENDAR');
    const [loading, setLoading] = useState<boolean>(false);
    const [results, setResults] = useState<any[]>([]);
    const [individualChartConfig, setIndividualChartConfig] = useState<any>(null);
    const [calendarChartConfig, setCalendarChartConfig] = useState<any>(null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const itemsPerPage = 25;
    const HIService = getService('HIService');
    const ErrorSvrc = getService('ErrorSvrc');
    const $translate = getService('$translate');


    const [showEngagementContext, setShowEngagementContext] = useState<boolean>(false);
    const [selectedMemberId, setSelectedMemberId] = useState<any>(null);


    const prepareParams = () => {
        const params = {

            filters: toSegmentParams(filters?.segmentIds as any[]),
            groupId: filters?.groupIds,
            hierarchyId: filters?.hierarchyIds
        };

        return params;
    }





    const loadIndividualVotes = () => {
        setLoading(true);
        const params = prepareParams();

        const fromParam =  Dates.firstDayOfMonth(to);
        const toParam =  Dates.lastDayOfMonth(to);

        HIService.stats.individual(fromParam, toParam, params, (err: any, result: any) => {
            setLoading(false);
            if (err) {
                if (!ErrorSvrc.isNotEnoughActiveEmployees(err)) {
                    ErrorSvrc.showErrorModal(err);
                } else {
                    setResults([]);
                    setCalendarChartConfig(null);
                    setIndividualChartConfig(null);
                }
            } else {
                setResults(result.members);
            }
        });
    };


    const buildCalendarChart = (members) => {

        // ... create calendar chart
        Charts.initializeDateFormats();

        const series = [];
        const hiCategories = [];
        const conversationCategories = [];


        members.forEach(function (member) {
            hiCategories.push(member.hi);
            conversationCategories.push(false);
            const memberSerie = {
                data: []
            };
            member.votes.forEach(function (vote) {
                memberSerie.data.push([Dates.formatServerDateToTimestamp(vote.date), series.length, vote.rating]);
            });
            series.push(memberSerie);
        });

        const numSeries = series.length;


        return {
            chart: {
                type: 'heatmap',
                plotBorderWidth: 0,
                height: ((35 * numSeries) + 100) + 'px'
            },
            boost: {
                useGPUTranslations: true
            },
            title: null,
            xAxis: [{
                type: 'datetime',
                gridLineWidth: 0,
                tickInterval: 24 * 3600 * 1000
            },
            {
                type: 'datetime',
                gridLineWidth: 0,
                opposite: true,
                linkedTo: 0,
                tickInterval: 24 * 3600 * 1000
            }],
            yAxis: [
                {  // Primary yAxis
                    type: 'category',
                    categories: hiCategories,
                    title: null,
                    gridLineWidth: 0,
                    dashStyle: 'Dot',
                    labels: {
                        useHTML: true,
                        formatter: function () {
                            let img;
                            if (this.value > 75) {
                                img = '/images/vote_1_btn.png';
                            } else if (this.value > 50) {
                                img = '/images/vote_2_btn.png';
                            } else if (this.value > 25) {
                                img = '/images/vote_3_btn.png';
                            } else {
                                img = '/images/vote_4_btn.png';
                            }

                            const hasConversationClass =  members[this.pos] && members[this.pos].hasConversations ? 'fa-comments' : 'fa-comments-o';

                            return '<span role="button" class="medium-style text-success" style="display: flex;align-items: center;">'
                                + '<img src="' + img + '" class="img-xsm" />'
                                + '<i  class="fa m-l-xs '  + hasConversationClass + '"></i></span>';

                        },
                        events: {
                            click: function () {
                                if (this.pos >= 0) {
                                    setSelectedMemberId(members[this.pos].encryptedId);
                                    setShowEngagementContext(true);
                                }
                            }
                        }
                    }
                }
            ],
            colorAxis: {
                dataClasses: [{
                    from: 1,
                    to: 1,
                    color: Style.faceSad
                }, {
                    from: 2,
                    to: 2,
                    color: Style.faceBad
                }, {
                    from: 3,
                    to: 3,
                    color: Style.faceGood
                },
                {
                    from: 4,
                    to: 4,
                    color: Style.faceHappy
                }]
            },
            plotOptions: {
                series: {
                    borderWidth: 0,
                    colsize: 24 * 36e5,
                    rowsize: 0.50,
                    marker: {
                        symbol: 'circle'
                    }
                }
            },
            legend: {
                enabled: false
            },
            tooltip: {
                useHTML: true,
                formatter: function () {
                    return '<b>' + Highcharts.dateFormat('%e-%b-%Y', new Date(this.point.x)) + '</b>';
                }
            },
            series: series
        };
    };


    const buildIndividualVotesChart = (members) => {
        const serieData = [];
        const hiCategories = [];


        const markerPulsing = {
            radius: 5,
            symbol: 'circle',
            states: {
                hover: {
                    enabled: true
                },
                normal: {
                    enabled: true,
                    lineColor: Style.chartColors[0],
                    lineWidthPlus: 3
                }
            }
        };

        const markerNotPulsing = {
            radius: 5,
            symbol: 'circle',
            states: {
                hover: {
                    enabled: true
                }
            }
        };


        members.forEach(function (member) {
            hiCategories.push(member.hi);
            const memberData = {
                x: member.votes.length,
                y: member.hi,
                color: Valuation.valuationColor(member.valuation),
                encryptedId: member.encryptedId

            };
            if (member.hasConversations) {
                memberData.marker = markerPulsing;
            } else {
                memberData.marker = markerNotPulsing;
            }

            serieData.push(memberData);
        });

        return {
            chart: {
                type: 'scatter',
                zoomType: 'xy',
                plotBorderWidth: 0
            },
            boost: {
                useGPUTranslations: true
            },
            title: null,
            xAxis: {
                title: {
                    text: 'Votes'
                },
                gridLineWidth: 0,
                tickInterval: 1
            },
            yAxis: {  // Primary yAxis
                gridLineWidth: 0,
                min: 0,
                max: 100,
                title: {
                    text: 'HI'
                }
            },
            legend: {
                enabled: false
            },
            plotOptions: {
                series: {
                    turboThreshold: 0,
                    point: {
                        events: {
                            click: function (event) {
                                if (!event.point.isCluster) {
                                    setSelectedMemberId(event.point.options.encryptedId);
                                    setShowEngagementContext(true);
                                }

                            }
                        }
                    },
                    dataLabels: {
                        enabled: true,
                        pointFormat: ''
                    },
                    jitter: {
                        x: 0.25, // This will add some randomless to the chart
                        y: 0.75
                    }
                }
            },
            series:  [{
                type: 'scatter',
                data: serieData,

                turboThreshold: 0,

                cursor: 'pointer',



                tooltip: {
                    followPointer: false,
                    pointFormat: 'Votos: {point.x} HI: {point.y:.1f}'
                }
            }]
        };
    };




    useEffect(() => {
        if (to) {
            loadIndividualVotes();
        }

    }, [to, filters, displayBy]);

    useEffect(() => {
        if (results && results.length) {
            const paginatedMembers = results.slice(
                (currentPage - 1) * itemsPerPage,
                currentPage * itemsPerPage
            );
            setIndividualChartConfig(buildIndividualVotesChart(results));
            setCalendarChartConfig(buildCalendarChart(paginatedMembers));
        }
    }, [results, currentPage]);


    return (
        <HappyLoading message={$translate.instant('LOADING_DATA_SENTENCE_1')} loading={loading}>
            <Stack gap={2}>
                <Stack alignItems={'center'} justifyContent={'center'} direction={'row'} gap={1} >
                    <Box flexGrow={1}>
                        <Typography variant="h2" >
                            {$translate.instant('HI_INDIVIDUALS_MAP')}
                        </Typography>

                        <Typography variant="body3"  color={'textSecondary'} >
                            {$translate.instant(displayBy === 'SCATTER'
                                ? 'INDIVIDUAL_HEATMAP_BY_SCATTER_HINT'
                                : 'INDIVIDUAL_HEATMAP_BY_CALENDAR_HINT')}
                        </Typography>
                    </Box>
                    <Box>
                        <FormControl fullWidth>
                            <InputLabel>{$translate.instant('DISPLAY_MODE')}</InputLabel>
                            <Select value={displayBy}
                                onChange={(e) => setDisplayBy(e.target.value)}>
                                <MenuItem value="CALENDAR">{$translate.instant('INDIVIDUAL_VOTES_CALENDAR')}</MenuItem>
                                <MenuItem value="SCATTER">{$translate.instant('INDIVIDUAL_VOTES_SCATTER')}</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Stack>


                <Stack>
                    {displayBy === 'SCATTER' && (
                        <HighchartsReact highcharts={Highcharts} options={individualChartConfig} />
                    )}
                    {displayBy === 'CALENDAR' && (
                        <>
                            <HighchartsReact highcharts={Highcharts} options={calendarChartConfig} />
                            <Box display="flex" justifyContent="center">
                                <Pagination
                                    count={Math.ceil(results.length / itemsPerPage)}
                                    page={currentPage}
                                    onChange={(_, page) => setCurrentPage(page)}
                                />
                            </Box>
                        </>

                    )}
                </Stack>

            </Stack>
            <PersonEngagementStatusContextModal
                from={Dates.firstDayOfMonth(to)}
                to={Dates.lastDayOfMonth(to)}
                employeeToken={selectedMemberId}
                showDrawer={showEngagementContext}
                onClose={() => setShowEngagementContext(false)}
            />
        </HappyLoading>

    );

};


export default EngagementHiIndividualHeatmapBox;
