import useHierarchyName from '../../hooks/useHierarchyName';


const HierarchyName = ({hierarchyId}: {hierarchyId: string}) => {
    const {hierarchyName} = useHierarchyName(hierarchyId);
    return (
        <>{hierarchyName}</>
    );
}

export default HierarchyName;
