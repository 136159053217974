import Style from './style.utils';


var Valuation = {};

Valuation.valuationColor = function (valuation, enps) {
    if (!valuation) {return;}

    if (enps) {
        if (valuation === 'EXCELLENT') {
            return Style.excellent;
        } else if (valuation === 'GOOD') {
            return Style.warning;
        } else if (valuation === 'WARNING') {
            return Style.neutral;
        } else if (valuation === 'BAD') {
            return Style.bad;
        } else {
            return Style.noData;
        }
    } else {
        if (valuation === 'EXCELLENT') {
            return Style.excellent;
        } else if (valuation === 'GOOD') {
            return Style.good;
        } else if (valuation === 'WARNING') {
            return Style.warning;
        } else if (valuation === 'BAD') {
            return Style.bad;
        } else {
            return Style.noData;
        }
    }

};

Valuation.valuationColorWithLowQuality = function (valuation, enps) {
    if (!valuation) {return;}

    if (enps) {
        if (valuation === 'EXCELLENT') {
            return Style.goodNonRelevant;
        } else if (valuation === 'GOOD') {
            return Style.warningNonRelevant;
        } else if (valuation === 'WARNING') {
            return Style.neutralNotRelevant;
        } else if (valuation === 'BAD') {
            return Style.badNonRelevant;
        } else {
            return Style.noDataNonRelevant;
        }
    } else {
        if (valuation === 'EXCELLENT') {
            return Style.goodNonRelevant;
        } else if (valuation === 'GOOD') {
            return Style.goodNonRelevant;
        } else if (valuation === 'WARNING') {
            return Style.warningNonRelevant;
        } else if (valuation === 'BAD') {
            return Style.badNonRelevant;
        } else {
            return Style.noDataNonRelevant;
        }
    }
}

Valuation.fillColorForValuation = function (valuation, enps) {
    if (enps) {
        if (valuation === 'EXCELLENT') {
            return 'rgba(12, 173, 0, 0.5)';
        } else if (valuation === 'GOOD') {
            return 'rgba(255, 147, 0, 0.5)';
        } else if (valuation === 'WARNING') {
            return '(200, 203, 204, 0.5)';
        } else if (valuation === 'BAD') {
            return 'rgba(254, 40, 71, 0.5)';
        } else {
            return 'rgba(187, 187, 187, 0.5)';
        }
    } else {
        if (valuation === 'EXCELLENT') {
            return 'rgba(12, 173, 0, 0.5)';
        } else if (valuation === 'GOOD') {
            return 'rgba(12, 173, 0, 0.5)';
        } else if (valuation === 'WARNING') {
            return 'rgba(255, 147, 0, 0.5)';
        } else if (valuation === 'BAD') {
            return 'rgba(254, 40, 71, 0.5)';
        } else {
            return 'rgba(187, 187, 187, 0.5)';
        }
    }
};

Valuation.enpsTagType =  function (value, average, valuation, useAverage) {
    if (useAverage) {
        if (average < 6) {
            return 'danger';
        } else if (average < 8) {
            return 'warning';
        } else {
            return 'success';
        }
    } else {
        switch (valuation) {
            case 'BAD':
                return 'danger';
            case 'WARNING':
                return 'neutral';
            case 'GOOD':
                return 'warning';
            case 'EXCELLENT':
                return 'success';
            default:
                return 'primary';
        }
    }
};

Valuation.enpsValuationColor =  function (value, average, valuation, useAverage) {
    if (useAverage) {
        if (average < 6) {
            return Style.bad;
        } else if (average < 8) {
            return Style.warning;
        } else {
            return Style.good;
        }
    } else {
        if (valuation === 'EXCELLENT') {
            return Style.excellent;
        } else if (valuation === 'GOOD') {
            return Style.warning;
        } else if (valuation === 'WARNING') {
            return Style.neutral;
        } else if (valuation === 'BAD') {
            return Style.bad;
        } else {
            return Style.noData;
        }
    }
};


export default Valuation;
