import React, {JSX} from 'react';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import {LocalizationProvider} from '@mui/x-date-pickers';
import moment from 'moment';


type LocalizationProviderWrapper = {
    children: JSX.Element
}

/*
*  We need to use this component while we are migrating to React
* since MUI Dates and other tools need a LocalizationProvider to work.
* Once the migration is done, we can move this component as a Provider in the App.tsx
 */

export const LocalizationProviderWrapper = ({children}: LocalizationProviderWrapper) => {


    // Get the user's locale from the browser
    const browserLocale = navigator.language || 'en';

    // Pass the browser locale to moment
    moment.locale(browserLocale);

    return (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={browserLocale}>
            {children}
        </LocalizationProvider>
    );
};
