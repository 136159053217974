import React, { useState, useEffect } from 'react';
import { getService } from 'reactInAngular';
import Numbers from '../../../../utilities/number.utilities';
import Dates from '../../../../utilities/date.utilities';
import {Box, Button, Stack, Grid, Typography, Alert} from '@mui/material';
import {CommonSegmentationFilters, FilterSelected} from '../../../../shared/new-components/hf-filter-menu/types';
import HfFilterMenu from '../../../../shared/new-components/hf-filter-menu/hfFilterMenu';
import HappyLoading from '../../../../shared/components/_migrated/loader/loading.directive';
import HFTag from '../../../../shared/new-components/hf-tag/hf-tag';
import HierarchyName from '../../../../shared/new-components/hf-hierarchy-name';
import {CharacteristicName} from '../../../../shared/new-components/hf-segmentation-name/hfSegmentationName';
import {ScoreFactorName, ScoreName} from '../../../../shared/new-components/hf-score-name/hfScoreName';
import StyleUtils from '../../../../utilities/style.utils';
import { Insight } from 'src/app/shared/types/scores';
import Style from '../../../../utilities/style.utils';
import {toSegmentParams} from '../../shared/utils';
import EmptyState from '../../../../shared/components/_migrated/empty_state/empty_state';
import moment from 'moment';
import {APISettings} from '../../../../shared/services/migrated/axiosWrapper';

interface EngagementPrioritiesBoxProps {
    to: Date;
    dateGrouping?: string;
    scoreId?: string;
    filters?: FilterSelected;
    showFiltering?: boolean;
    showInsightDetail: (date: Date,  scoreId: string, filters?: CommonSegmentationFilters) => void;
    onViewDetailLabel?: string;
}


const EngagementPrioritiesBox: React.FC<EngagementPrioritiesBoxProps> = ({
    to,
    dateGrouping,
    scoreId,
    filters,
    showFiltering,
    showInsightDetail,
    onViewDetailLabel,
}) => {
    const $translate = getService('$translate');
    const InsightsService = getService('InsightsService');
    const ErrorSvrc = getService('ErrorSvrc');

    const [selectedFilters, setSelectedFilters] = useState<FilterSelected>(filters);
    const [positiveInsights, setPositiveInsights] = useState<Insight[]>([]);
    const [negativeInsights, setNegativeInsights] = useState<Insight[]>([]);
    const [totalPositive, setTotalPositive] = useState<number>(0);
    const [totalNegative, setTotalNegative] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);


    useEffect(() => {
        if (to && dateGrouping) {
            loadInsights('POSITIVE', false);
            loadInsights('NEGATIVE', false);
        }

    }, [to, dateGrouping, selectedFilters]);


    useEffect(() => {
        setSelectedFilters(filters);
    }, [filters]);

    const processInsight = (insights: Insight[], positive: boolean) => {
        return insights.map((insight) => {

            insight.linkParams = {
                scoreId: insight.scoreId,
                hierarchyId: insight.hierarchyId,
                filters: insight.characteristic
                    ? `${insight.characteristic.characteristicId}:${insight.characteristic.value}`
                    : null,
            };

            const params = {
                difference: Numbers.roundNumber(
                    insight.value - insight.companyBenchmark,
                    1
                ),
                companyBenchmark: Numbers.roundNumber(
                    insight.companyBenchmark,
                    1
                ),
                color: insight.positive ? Style.good : Style.bad,
            };

            let label: string | null = null;

            if (insight.type === 'HI_IMPACT') {
                label = 'ENGAGEMENT_INSIGHTS_HI_IMPACT';
            } else if (insight.type === 'ENPS_IMPACT') {
                label = 'ENGAGEMENT_INSIGHTS_ENPS_IMPACT';
            } else if (insight.type === 'VARIATION') {
                label = positive
                    ? 'ENGAGEMENT_INSIGHTS_POSITIVE_VARIATION'
                    : 'ENGAGEMENT_INSIGHTS_NEGATIVE_VARIATION';
            } else {
                label = positive
                    ? 'ENGAGEMENT_INSIGHTS_POSITIVE_DIFFERENCE'
                    : 'ENGAGEMENT_INSIGHTS_NEGATIVE_DIFFERENCE';
            }

            insight.label = $translate.instant(label, params);
            return insight;
        });
    };


    const prepareParams = () => {


        const previousMonth =  Dates.firstDayOfMonth(Dates.substractDoDate(to, 1, 'month'));
        const previousMonthFirstDay = Dates.firstDayOfMonth(previousMonth);

        const params = {
            from: previousMonthFirstDay,
            to: Dates.lastDayOfMonth(previousMonthFirstDay),
            filters: toSegmentParams(filters?.segmentIds as any[]),
            groupId: filters?.groupIds,
            hierarchyId: filters?.hierarchyIds,
            grouping: dateGrouping,
            scoreId: scoreId,
        };

        return params;
    }

    const loadInsights = (type: string, isMore: boolean) => {

        setLoading(true);


        const params = prepareParams();




        if (type === 'POSITIVE') {
            Object.assign(params, {
                valuationTypes: ['EXCELLENT', 'GOOD'],
                fromInsight: positiveInsights.length,
                toInsight: positiveInsights.length + 3,
                sort: 'POSITIVE',
            });
        } else {
            Object.assign(params, {
                valuationTypes: ['BAD', 'WARNING'],
                fromInsight: negativeInsights.length,
                toInsight: negativeInsights.length + 3,
                sort: 'NEGATIVE',
            });
        }

        InsightsService.getScoreInsights(params, (err: any, result: any) => {


            if (!err) {
                if (type === 'POSITIVE') {
                    if (isMore) {
                        setPositiveInsights((prev) =>
                            [...prev, ...processInsight(result.result, true)]
                        );
                    } else {
                        setPositiveInsights(processInsight(result.result, true));
                    }
                    setTotalPositive(result.total);
                } else {
                    if (isMore) {
                        setNegativeInsights((prev) =>
                            [...prev, ...processInsight(result.result, false)]
                        );
                    } else {
                        setNegativeInsights(processInsight(result.result, false));
                    }

                    setTotalNegative(result.total);
                }
            } else {
                ErrorSvrc.showErrorModal(err);
            }
            setLoading(false);
        });
    };

    const onInsightClick = (insight: Insight) => {
        if (showInsightDetail) {



            const params = {
                from: Dates.substractDoDate(to, 1, 'month'),
                hierarchyIds: [insight.hierarchyId],
                segmentIds: [],
                groupIds: [],
            }

            if (insight.characteristic) {
                debugger;
                params.segmentIds = [{
                    id: insight.characteristic.characteristicId,
                    values: [insight.characteristic.value],
                }];

            }


            showInsightDetail(to, insight.scoreId, params);
        }

    }


    const renderInsight = (insight: Insight, index: number, positive: boolean) => {

        let borderColor;

        if (insight.valuation === 'EXCELLENT') {
            borderColor = StyleUtils.excellent;
        } else if (insight.valuation === 'GOOD') {
            borderColor = StyleUtils.good;
        } else if (insight.valuation === 'BAD') {
            borderColor = StyleUtils.bad;
        } else if (insight.valuation === 'WARNING') {
            borderColor = StyleUtils.warning;
        }


        return (
            <Box key={index}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: 2,
                    border: '1px solid #ECEFF3',
                    borderLeft: `10px solid ${borderColor}`,
                }}>

                <Grid container spacing={2}>
                    <Grid item xs={4} sx={{display:'flex', alignItems:'center', justifyContent:'start'}}>
                        <Stack gap={0.5} direction={'row'}>
                            <HFTag
                                type={'icon'}
                                status={positive ? 'success' : 'warning'}
                                text={<HierarchyName hierarchyId={insight.hierarchyId} />}
                                iconClass={'fa fa-building-o'}/>

                            {insight.characteristic && (
                                <HFTag
                                    type={'icon'}
                                    status={positive ? 'success' : 'warning'}
                                    text={<CharacteristicName id={insight.characteristic.characteristicId} valueId={insight.characteristic.value} />}
                                    iconClass={'fa fa-user-o'}/>
                            )}
                        </Stack>
                    </Grid>

                    <Grid item xs={4} justifyContent={'center'}>
                        <Stack gap={1}>
                            <Typography variant={'body3'} fontWeight={'bold'}>
                                {insight.factorId && (<ScoreFactorName scoreId={insight.scoreId} factorId={insight.factorId} />)}
                                {!insight.factorId && ( <ScoreName scoreId={insight.scoreId} />)}
                                {` (${ Number((insight.value).toFixed(1))}) pts`}

                            </Typography>

                            <Typography variant={'body3'}
                                dangerouslySetInnerHTML= {{__html: insight.label}}/>

                        </Stack>
                    </Grid>

                    <Grid item xs={4} sx={{display:'flex', alignItems:'center', justifyContent:'end'}}>
                        <Box component={'a'} onClick={() => {onInsightClick(insight)}}> {onViewDetailLabel}</Box>
                    </Grid>
                </Grid>

            </Box>
        )
    }


    const renderInsights = () => {


        if ((!positiveInsights || positiveInsights.length === 0) && (!negativeInsights || negativeInsights.length === 0)) {
            return (
                <EmptyState image={'images/no_insights.svg'}
                    small={true}
                    message={$translate.instant('ENGAGEMENT_INSIGHTS_NO_INSIGHTS_TITLE')}
                    submessage={$translate.instant('ENGAGEMENT_INSIGHTS_NO_INSIGHTS_DESCRIPTION')}/>
            )


        } else {
            return (
                <>

                    <Alert severity="info">
                        <Typography variant={'body3'}>
                            {$translate.instant('ENGAGEMENT_INSIGHTS_ONLY_LAST_MONTH', {date:
                                    moment(Dates.firstDayOfMonth(to)).format('MMM-YYYY')})}
                        </Typography>
                    </Alert>


                    {positiveInsights.map((insight, index) => (
                        renderInsight(insight, index, true)
                    ))}

                    {totalPositive && (
                        <Button variant={'text'} sx={{width:'100%', textAlign:'left'}} onClick={() => loadInsights('POSITIVE', true)}>
                            {$translate.instant('ENGAGEMENT_INSIGHTS_LOAD_MORE')}
                        </Button>
                    )}



                    {negativeInsights.map((insight, index) => (
                        renderInsight(insight, index, false)
                    ))}

                    {totalNegative && (
                        <Button variant={'text'} sx={{width:'100%', textAlign:'left'}} onClick={() => loadInsights('NEGATIVE', true)}>
                            {$translate.instant('ENGAGEMENT_INSIGHTS_LOAD_MORE')}
                        </Button>
                    )}
                </>
            );
        }


    }

    return (
        <HappyLoading loading={loading}>
            <Stack gap={2}>

                {showFiltering && (
                    <HfFilterMenu
                        selectedFilters={selectedFilters}
                        updateFilters={(filters: FilterSelected) => setSelectedFilters(filters)}
                        groupFiltersConfig={{
                            id: 'GROUP',
                            type: 'groups',
                            mode: 'multi',
                            title: $translate.instant('GROUPS'),
                            nonRemovable: false,
                            groups: null,
                        }}
                        segmentFiltersConfig={{
                            id: 'SEGMENT',
                            type: 'segments',
                            mode: 'multi',
                            title: $translate.instant('SEGMENTED'),
                            nonRemovable: false,
                            segments: null,
                        }}
                        hierarchyFiltersConfig={{
                            id: 'HIERARCHY',
                            type: 'hierarchyIds',
                            mode: 'multi',
                            title: $translate.instant('AREA'),
                            nonRemovable: false,
                            hierarchies: null,
                        }}/>
                )}


                {renderInsights()}





            </Stack>
        </HappyLoading>
    );
};


export default EngagementPrioritiesBox;
