import React, { useState } from 'react';
import {
    Box,
    Typography,
    IconButton,
    Collapse,
    Tooltip, Stack,
} from '@mui/material';
import { Info, ArrowBack } from '@mui/icons-material';


interface DashboardHeaderProps {
    hfTitle: string;
    hfSubtitle: string;
    hfInfo?: string;
    showBackArrow?: boolean;
    section?: string;
    actions?: React.ReactNode;
}

const DashboardHeader: React.FC<DashboardHeaderProps> = ({
    hfTitle,
    hfSubtitle,
    hfInfo,
    showBackArrow,
    actions,
}) => {

    const handleArrowClick = () => {
        window.history.back();
    }

    return (
        <Box id="dashboard-header" paddingTop={1} paddingBottom={1} paddingLeft={1} paddingRight={1} width={'100%'}>
            <Box display="flex" flexDirection="row" alignItems="center" ml={2}>


                <Box flex="1">

                    <Stack direction="row" gap={0}>
                        {showBackArrow && (
                            <IconButton onClick={handleArrowClick} size="small">
                                <ArrowBack />
                            </IconButton>
                        )}
                        <Typography variant="h1" noWrap>
                            {hfTitle}
                            {hfInfo && (
                                <Tooltip title={hfInfo}>
                                    <Info color="success" style={{ marginLeft: 8 }} />
                                </Tooltip>
                            )}
                        </Typography>
                    </Stack>

                    <Typography variant="body3" color="text.secondary">
                        {hfSubtitle}
                    </Typography>
                </Box>

            </Box>

            <Box>
                {actions}
            </Box>
        </Box>
    );
};


export default DashboardHeader;
