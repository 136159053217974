'use strict';

import Dates from '../../../utilities/date.utilities';
import { APISettings, doPost } from './axiosWrapper';

const reportsBaseUrl = '/company/reports';

export const getScoreReport = (scoreId, params, callback) => {
    const { from, to, ...reqParams } = params;
    const reportFrom = Dates.toAPIFormat(params.from, APISettings.apiDateFormat);
    const reportTo = Dates.toAPIFormat(params.to, APISettings.apiDateFormat);
    const url = `${reportsBaseUrl}/score/${scoreId}/from/${reportFrom}/to/${reportTo}`;

    doPost(url, null, reqParams, callback);
};


export const getENPSReport = (params, callback) => {
    const reqParams = { ...params };
    const reportFrom = Dates.toAPIFormat(params.from, APISettings.apiDateFormat);
    const reportTo = Dates.toAPIFormat(params.to, APISettings.apiDateFormat);
    const url = `${reportsBaseUrl}/enps/from/${reportFrom}/to/${reportTo}`;

    doPost(url, null, reqParams, callback);
}
