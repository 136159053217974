import React from 'react';
import { Box, Skeleton } from '@mui/material';
import Style from '../../../../utilities/style.utils';

interface HfImpactMarkProps {
    level?: 'LOW' | 'MODERATE' | 'HIGH' | 'TOTAL';
    color?: string;
    loading?: boolean;
}

const HfImpactMark: React.FC<HfImpactMarkProps> = ({ level, color = '#000', loading = false }) => {
    const getGrade = (level?: string): number => {
        switch (level) {
            case 'LOW':
                return 1;
            case 'MODERATE':
                return 2;
            case 'HIGH':
                return 3;
            case 'TOTAL':
                return 4;
            default:
                return 0;
        }
    };

    const grade = getGrade(level);

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            {loading ? (
                <Box sx={{ minWidth: '120px' }}>
                    <Skeleton variant="rectangular" width="100%" height={15} />
                </Box>
            ) : (
                <Box
                    sx={{
                        position: 'relative',
                        width: '70%',
                        minWidth: '120px',
                        height: '15px',
                        border: `1px solid ${Style.otherColor}`,
                        borderRadius: '3px',
                        display: 'flex',
                        flexDirection: 'row',
                        overflow: 'hidden',
                    }}
                >
                    {[1, 2, 3, 4].map((i) => (
                        <Box
                            key={i}
                            sx={{
                                width: '25%',
                                height: '100%',
                                borderRight: i < 4 ? `1px solid ${Style.otherColor}` : 'none',
                                display: 'flex',
                                padding: '1px',
                                '&:first-of-type .complete': {
                                    borderTopLeftRadius: '1px',
                                    borderBottomLeftRadius: '1px',
                                },
                                '&:last-of-type .complete': {
                                    borderTopRightRadius: '1px',
                                    borderBottomRightRadius: '1px',
                                },
                            }}
                        >
                            <Box
                                className="complete"
                                sx={{
                                    height: '100%',
                                    width: '100%',
                                    backgroundColor: i <= grade ? color : 'transparent',
                                }}
                            />
                        </Box>
                    ))}
                </Box>
            )}
        </Box>
    );
};

export default HfImpactMark;
