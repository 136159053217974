

import axios from 'axios';
import qs from 'qs';



axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';


axios.defaults.paramsSerializer = function (params) {
    return qs.stringify(params, { indices: false }); // param=value1&param=value2
};

const APISettings = {
    apiUrl: process.env.API_URL + '/api/v1',
    wsURL: process.env.API_URL + '/api/ws/v1',
    apiDateFormat: 'DDMMYYYY'
};



const translateAxiosError = (axiosError) => {
    // Default error object structure
    let errorObj = {
        status: null,
        data: {},
        errorMessage: undefined
    };

    if (axiosError.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        errorObj.status = axiosError.response.status;
        errorObj.data = axiosError.response.data;
    } else if (axiosError.request) {
        // The request was made but no response was received
        errorObj.status = 0; // Could be a connection error
    } else {
        // Something happened in setting up the request that triggered an Error
        errorObj.errorMessage = axiosError.message;
    }

    return errorObj;
};

export default translateAxiosError;


const doGet = (url, params, callback) => {
    axios.get(`${APISettings.apiUrl}${url}`, { params })
        .then(response => callback(null, response.data))
        .catch(error => callback(translateAxiosError(error)));
};

const doGetAsync = async (url, params) => {
    return axios
        .get(`${APISettings.apiUrl}${url}`, {params})
        .then(response => response.data)
        .catch(r => Promise.reject(translateAxiosError(r)));
}

const doPost = (url, data, requestParams, callback) => {

    axios.post(`${APISettings.apiUrl}${url}`, data, requestParams ? { params: requestParams } : {})
        .then(response => callback(null, response.data))
        .catch(error => callback(translateAxiosError(error)));
};


/**
 * @param {string} url
 * @param {AnnouncementGenerateContentRequestDTO|D} data
 */
const doPostAsync = async (url, data, requestParams) => {

    return axios
        .post(`${APISettings.apiUrl}${url}`, data, {params: requestParams})
        .then(response => response.data)
        .catch(r => Promise.reject(translateAxiosError(r)));
};

const doPut = (url, data, callback) => {
    axios.put(`${APISettings.apiUrl}${url}`, data)
        .then(response => callback(null, response.data))
        .catch(error => callback(translateAxiosError(error)));
};

const doDelete = (url, data, callback) => {
    axios.delete(`${APISettings.apiUrl}${url}`, data)
        .then(response => callback(null, response.data))
        .catch(error => callback(translateAxiosError(error)));
};



export {doGet, doGetAsync ,doPost,doPostAsync, doPut, doDelete, APISettings};
