import React, {useEffect, useState} from 'react';
import {getService} from 'reactInAngular';
import {Tooltip, Typography} from '@mui/material';
import {DataQuality} from '../../../../shared/types/common';
import HFTag from '../../../../shared/new-components/hf-tag/hf-tag';


interface QualityPopoverTextProps  {
    quality: DataQuality;
    status?: string;
}

const QualityPopoverText = ({quality} : QualityPopoverTextProps) => {


    const [qualityText, setQualityText] = useState('');
    const [qualityDescription, setQualityDescription] = useState('');

    const $translate = getService('$translate');

    useEffect(() => {



        if (status === 'DISABLED') {
            setQualityText($translate.instant('SCORE_DISABLED', { result: 1 }));
            setQualityDescription($translate.instant('SCORE_DISABLED_QUALITY_DESCRIPTION'));
        } else if (quality === 'NO_DATA') {
            setQualityText($translate.instant('NO_DATA'));
            setQualityDescription($translate.instant('SCORE_NODATA_QUALITY_DESCRIPTION'));
        } else if (quality === 'LOW') {
            setQualityText($translate.instant('NO_REPRESENTATIVE_SAMPLE'));
            setQualityDescription($translate.instant('SCORE_LOW_QUALITY_DESCRIPTION'));
        } else {
            setQualityText($translate.instant('REPRESENTATIVE_SAMPLE'));
            setQualityDescription($translate.instant('SCORE_NORMAL_QUALITY_DESCRIPTION'));

        }

    }, [quality, status]);

    return (

        <Tooltip title={qualityDescription} placement="top">
            <Typography
                variant="caption"
                sx={{textDecoration: 'underline'}}

                dangerouslySetInnerHTML={{ __html: qualityText }}
            />
        </Tooltip>


    );

}

export default QualityPopoverText;
