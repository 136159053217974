import {useEffect, useState} from 'react';
// @ts-ignore
import {getHierarchyName} from '../../services/migrated/hierarchy.service';
import {getService} from 'reactInAngular';

interface Props {
    id: string;
}

/*
    Given a segmentation id and type, returns the name of the segmentation.
 */

const HierarchyName = (({id}:Props) => {
    const [hierarchyName, setHierarchyName] = useState<string>('');

    useEffect(() => {
        getHierarchyName(id, function (err:any, name: string) {
            if (err) {
                // TODO: Handle error
                setHierarchyName('?');
            } else {
                setHierarchyName(name);
            }
        });
    }, [id]);

    return (
        <>{hierarchyName}</>
    );
});

const GroupName = (({id}:Props) => {

    // TODO: This is a hack to get the service. At some point we should refactor this to use the new service.
    const Groups = getService('Groups');

    const [groupName, setGroupName] = useState<string>('');


    useEffect(() => {
        Groups.getGroup(id, function (err:any, group: any) {
            if (err) {
                // TODO: Handle error
                setGroupName('?');
                console.log(err);
            } else {
                setGroupName(group.name);
            }
        });
    }, [id]);
    return (
        <>{groupName}</>
    );
});

interface CharacteristicProps extends Props {
    valueId: string;
}

const CharacteristicName = (({id, valueId}:CharacteristicProps) => {

    const [characteristicDescription, setCharacteristicDescription] = useState<string>('');
    const [valueName, setValueName] = useState<string>('');


    // TODO: This is a hack to get the service. At some point we should refactor this to use the new service.
    const Characteristics = getService('Characteristics');
    const Languages = getService('Languages');


    useEffect(() => {
        setCharacteristicDescription(Languages.getTranslationFromUserLanguage(Characteristics.getCharacteristicNameById(id)));
        setValueName(Languages.getTranslationFromUserLanguage(Characteristics.getCharacteristicValueName(id, valueId)));
    }, [id, valueId]);



    return (<>{characteristicDescription}: {valueName}</>);
});

export {HierarchyName, CharacteristicName, GroupName};
