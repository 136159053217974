import Style from "../../utilities/style.utils";

export default function CommentsService(Restangular, Settings, $log, $uibModal, $rootScope, $intercom, Conversation) {
    'ngInject';
    var Company = Restangular.one('company');

    var methods = {};

    methods.get = function (params, callback, error) {

        var finalParams = angular.copy(params);


        if (params && params.from)
            finalParams.from = moment(params.from).format(Settings.apiDateFormat);

        if (params && params.to)
            finalParams.to = moment(params.to).format(Settings.apiDateFormat);

        if (params && params.hierarchyId && params.hierarchyId.id) {
            finalParams.hierarchyId = params.hierarchyId.id;
        }

        Company.one('comments')
            .get(finalParams || null).then(callback, error);
    };

    methods.inappropiate = function (commentId, replyId, params, callback) {
        // http://api-staging.myhappyforce.com/v1/company/comments/inappropriate/{{commentId}}
        var commentRequest = Company.one('comments').one('inappropriate').one(commentId);
        if (replyId)
            commentRequest = commentRequest.one(replyId);

        commentRequest.customPUT(params, '', {}, {}).then(function (result) {
            callback(null, result);
        }, function (err) {
            callback(err);
        });
    };

    methods.getOne = function (commentId, callback) {
        Company.one('comments').one(commentId).get().then(function (comment) {
            callback(null, comment);
        }, function (err) {

            callback(err);
        });
    };

    methods.addHrClassify = function (commentId, classification, callback, error) {
        Company.one('comments').one('classify').one(commentId).one(classification).put().then(callback, error);
    };

    methods.removeHrClassify = function (commentId, classification, callback, error) {
        Company.one('comments').one('classify').one(commentId).one(classification).remove().then(callback, error);
    };

    methods.addHrTag = function (commentId, tag, callback, error) {
        Company.one('comments').one('tags').one(commentId).one(tag).put().then(callback, error);
    };

    methods.removeHrTag = function (commentId, tag, callback, error) {
        Company.one('comments').one('tags').one(commentId).one(tag).remove().then(callback, error);
    };

    methods.listHrTags = function (commentId, searchText, callback, error) {
        Company.one('comments').one('tags').get().then(callback, error);
    };

    methods.setHrPossitiveness = function (commentId, positive, callback, error) {
        Company.one('comments').one('mark').one(commentId).one('above').one(positive).put().then(callback, error);
    };

    methods.setHRelevant = function (commentId, relevant, callback, error) {
        Company.one('comments').one('mark').one(commentId).one('relevant').one(relevant).put().then(callback, error);
    };


    methods.setCommentContainer = function (commentId, containerId, callback)
    {
        Company.one('comments').one('container').one(commentId).one(containerId).put().then(
            function (result) {
                callback(null, result);
            },
            function (err) {
                callback(err);
            });
    };

    methods.removeCommentContainer = function (commentId, callback) {
        Company.one('comments').one('container').one(commentId).remove().then(
            function (result) {
                callback(null, result);
            },
            function (err) {
                callback(err);
            });
    };

    methods.showSwapVisibilityModal = function (commentId, privated, callback) {
        Company.one('comments').one('mark').one(commentId).one('privated').one(privated).put().then(
            function (result) {
                callback(null, result);
            },
            function (err) {
                callback(err);
            });
    };

    methods.sendPredefinedReply = function (commentId, replyCode, callback) {
        var reply = {
            messageType: replyCode,
            signed: true
        };

        Company.one('comments').one('reply').one(commentId).customPOST(reply, '', {}, {}).then(function (result) {
            callback(null, result);
        },
        function (err) {
            callback(err);
        });
    };

    methods.removeCommentContainer = function (commentId, callback) {
        Company.one('comments').one('container').one(commentId).remove().then(
            function (result) {
                callback(null, result);
            },
            function (err) {
                callback(err);
            });
    };

    methods.markAsSeen = function (commentId, callback) {
        var commentIds = [commentId];
        Company.one('comments').one('seen').one(commentId).customPOST({}, '', {}, {}).then(function (result) {
            callback(null, result);
        }, function (err) {
            callback(err);
        });
    };

    methods.sendConversationMessage = Conversation.sendMessage;
    methods.getConversation = Conversation.get;

    methods.sendReply = function (commentId, message, signed, callback) {
        var reply = {
            message: message,
            signed: signed
        };

        Company.one('comments').one('reply').one(commentId).customPOST(reply, '', {}, {}).then(function (result) {
            callback(null, result);
        }, function (err) {
            callback(err);
        });
    };

    methods.getFeedbackTypeColor = function (type) {
        switch (type) {
            case 'CONGRATULATION':
                return Style.congratulationColor;
            case 'CRITICISM':
                return Style.criticismColor;
            case 'INFORMATION':
                return Style.informationColor;
            case 'SUGGESTION':
                return Style.suggestionColor;
            case 'RECOGNITION':
                return Style.recognitionColor;
            case 'OTHER':
                return Style.otherColor;
            default:
                break;

        }
    };

    methods.sendConversationMessage = Conversation.sendMessage;
    methods.getConversation = Conversation.get;

    methods.getFeedbackTypeColor = function (type) {
        switch (type) {
            case 'CONGRATULATION':
                return Style.congratulationColor;
            case 'CRITICISM':
                return Style.criticismColor;
            case 'INFORMATION':
                return Style.informationColor;
            case 'SUGGESTION':
                return Style.suggestionColor;
            case 'RECOGNITION':
                return Style.recognitionColor;
            case 'OTHER':
                return Style.otherColor;
            default:
                break;

        }
    };

    var Stats = Company.one('stats');
    var stats = {};

    stats.get = function (params, callback) {

        var finalParams = angular.copy(params);

        if (params && params.from) {
            finalParams.from = moment(params.from).format(Settings.apiDateFormat);
        }

        if (params && params.to) {
            finalParams.to = moment(params.to).format(Settings.apiDateFormat);
        }

        if (params && params.hierarchyId && params.hierarchyId.id) {
            finalParams.hierarchyId = params.hierarchyId.id;
        }


        Stats.one('comments')
            .get(finalParams || null).then(function (data) {
            callback(null, data);
        }, function (err) {

            callback(err);
        });
    };

    methods.stats = stats;
    methods.availableHRCategories = ['FEEDBACK_RECOGNITION',
        'TEAM_SPIRIT',
        'JOB_SATISFACTION',
        'LEADERSHIP',
        'COMMUNICATION',
        'PEOPLE_DEVELOPMENT',
        'WORKING_ENABLERS',
        'ENGAGEMENT_MOTIVATION',
        'OTHERS',
        'CHEER_UP',
        'SALARY_AND_REWARD',
        'SUGGESTIONS'];


    return methods;
};
