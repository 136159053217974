import React from 'react';
import {BoxProps, DrawerProps, useMediaQuery} from '@mui/material';
import {TitleCloseableDrawer} from '../../../../shared/new-components/hf-title-closeable-drawer/hfTiteCloseableDrawer';


interface EngagementDetailDrawer extends DrawerProps {
    onClose: () => void;
    title: string | React.ReactNode;
    headerProps?: BoxProps;
}


const EngagementDetailDrawer: React.FC<EngagementDetailDrawer> = ({
    open,
    onClose,
    title,
    children,
    headerProps,
    ...drawerProps
}) => {

    const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));


    const finalDrawerProps = {
        ...drawerProps,
        PaperProps: {
            sx: {
                width: matches ? '80%' : '100%',
            },
        }
    }


    return (
        <TitleCloseableDrawer
            open={open}
            onClose={onClose}
            title={title}

            headerProps={headerProps}
            {...finalDrawerProps}
        >
            {children}
        </TitleCloseableDrawer>
    );
}

export default EngagementDetailDrawer;
