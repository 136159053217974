import React, { useState, useEffect } from 'react';
import { getService } from 'reactInAngular';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

import Dates from '../../../../utilities/date.utilities';
import Numbers from '../../../../utilities/number.utilities';
import Valuation from '../../../../utilities/valuation.utils';
import HappyLoading from '../../../../shared/components/_migrated/loader/loading.directive';
import Style from '../../../../utilities/style.utils';
import {FilterSelected} from '../../../../shared/new-components/hf-filter-menu/types';
import {toSegmentParams} from '../utils';
import EmptyState from '../../../../shared/components/_migrated/empty_state/empty_state';

interface ScoresImpactChartProps {
    from: Date;
    to: Date;
    filters: FilterSelected;
    type: 'ENPS' | 'HI';
    dateGrouping: string;
}

interface Correlation {
    scoreId: string;
    correlation: number;
    result: number;
    valuation: string;
    name?: string;
    value?: number;
    color?: string;
    borderColor?: string;
}

const ScoresImpactChart: React.FC<ScoresImpactChartProps> = ({ from, to, filters, type, dateGrouping }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [correlations, setCorrelations] = useState<Correlation[]>([]);
    const [chartConfig, setChartConfig] = useState<any>(null);
    const [hasEnoughActiveEmployees, setHasEnoughActiveEmployees] = useState<boolean>(true);

    const ScoresV2Srvc = getService('ScoresV2Srvc');
    const ErrorSvrc = getService('ErrorSvrc');
    const Languages = getService('Languages');
    const $translate = getService('$translate');

    const navigateToScore = (scoreId: string) => {
        // Implement navigation logic
    };

    const processResults = async (error: any, results: Correlation[]) => {
        if (error) {
            setLoading(false);
            setCorrelations([]);
            setChartConfig(null);
            if (ErrorSvrc.isNotEnoughActiveEmployees(error)) {
                setHasEnoughActiveEmployees(false);
            } else {
                ErrorSvrc.showErrorModal(error);
            }
        } else {
            const namePromises = results.map(item =>
                new Promise<string>(resolve => {
                    ScoresV2Srvc.getScore(item.scoreId, (err: any, score: any) => {
                        if (err) {
                            resolve('ND');
                        } else {
                            resolve(score.name);
                        }
                    });
                })
            );

            const names = await Promise.all(namePromises);

            const newCorrelations = results.map((item, index) => ({
                ...item,
                name: names[index],
                value: Math.abs(item.correlation),
                result: Numbers.roundNumber(item.result, 2),
                color: Valuation.fillColorForValuation(item.valuation),
                borderColor: Valuation.valuationColor(item.valuation)
            }));
            setHasEnoughActiveEmployees(true);

            setCorrelations(newCorrelations);
            setLoading(false);
        }
    };


    const prepareParams = () => {

        const params = {
            from: Dates.firstDayOfMonth(from),
            to: Dates.lastDayOfMonth(to),
            filters: toSegmentParams(filters?.segmentIds as any[]),
            groupId: filters?.groupIds,
            hierarchyId: filters?.hierarchyIds,
            grouping: dateGrouping,
        };

        return params;
    }



    const loadData = () => {
        if (!to && !from) { return; }

        setLoading(true);
        setCorrelations([]);

        const params = prepareParams();
        if (type === 'HI') {
            ScoresV2Srvc.stats.hiImpact(params, processResults);
        } else if (type === 'ENPS') {
            ScoresV2Srvc.stats.enpsImpact(params, processResults);
        }
    };

    useEffect(() => {
        loadData();
    }, [from, to, filters, type, dateGrouping]);

    useEffect(() => {
        if (correlations) {
            const names: string[] = [];
            const values: any[] = [];
            correlations.forEach(item => {
                const result = item.result ? '' + item.result : 'ND';
                names.push(Languages.getTranslationFromUserLanguage(item.name!) + ' (' + result + ')');
                values.push({ y: item.value, color: item.color, result: item.result, borderWidth: 1, borderColor: item.borderColor });
            });

            const newChartConfig = {
                chart: {
                    type: 'bar',
                    style: {
                        fontFamily: Style.charts.fontFamily,
                        fontSize: Style.charts.fontSmall.size,
                        fontWeight: Style.charts.fontSmall.weight,
                        color: Style.charts.textColor
                    },
                    height: 40 + (40 * values.length),
                    events: {
                        render: function () {
                            const axis = this.xAxis[0];
                            if (!axis.ticks) {
                                return;
                            }

                            const ticks = Object.values(axis.ticks);
                            ticks.forEach(tick => {
                                const index = tick.pos;
                                if (index === -1) {
                                    return;
                                }

                                const scoreId = correlations[index].scoreId;
                                if (tick.label.element.onclick) {
                                    return;
                                }

                                tick.label.element.onclick = function () {
                                    navigateToScore(scoreId);
                                };
                            });
                        }
                    }
                },
                title: {
                    text: null
                },
                xAxis: {
                    categories: names,
                    gridLineWidth: 0,
                    labels: {
                        useHTML: true,
                        formatter: function () {
                            return '<a>' + this.value + '</a>';
                        }
                    }
                },
                yAxis: {
                    min: 0,
                    max: 1,
                    title: null,
                    gridLineWidth: 0,
                    labels: {
                        step: 10,
                        useHTML: true,
                        align: 'left',
                        formatter: function () {
                            if (this.isFirst) {
                                return $translate.instant('LOW_IMPACT');
                            } else if (this.isLast) {
                                return '<span style="margin-left: -80px">' + $translate.instant('HIGH_IMPACT') + '</span>';
                            } else {
                                return '';
                            }
                        }
                    }
                },
                tooltip: {
                    enabled: false
                },
                series: [{
                    data: values,
                    name: $translate.instant('IMPACT'),
                    showInLegend: false
                }]
            };

            setChartConfig(newChartConfig);
        }
    }, [correlations]);

    return (
        <HappyLoading loading={loading}>

            {!hasEnoughActiveEmployees && (
                <EmptyState
                    small={true}
                    image="images/anonymous.png"
                    message={$translate.instant('NOT_ENOUGH_EMPLOYEES', {})}
                    submessage={$translate.instant('NOT_ENOUGH_EMPLOYEES_SUBMESSAGE', {})}
                />
            )}

            {hasEnoughActiveEmployees && (
                <HighchartsReact
                    highcharts={Highcharts}
                    options={chartConfig}
                />
            )}


        </HappyLoading>
    );
};

export default ScoresImpactChart;
