

/*
    A simple component that formats a date according to the given format.
 */

import moment from 'moment';
import {DateGrouping} from '../../types/common';

interface HfDateGroupingFormatProps {
    date: Date;
    format: DateGrouping;
}

const HfDateGroupingFormat = ({ date, format } : HfDateGroupingFormatProps) => {


    const formats = {
        MONTH: 'MMM YYYY',
        QUARTER: '[Q]Q-YYYY',
        YEAR: 'YYYY'
    }

    // Check if the format is a valid key
    if (!formats[format]) {
        return (<>{moment(date).format('MMM DD, YYYY')}</>);
    } else {
        return (<>{moment(date).format(formats[format])}</>);
    }



};

export default HfDateGroupingFormat;
