import React, { useEffect, useState } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { getService } from 'reactInAngular';
import Numbers from '../../../../../utilities/number.utilities';
import Valuation from '../../../../../utilities/valuation.utils';
import Dates from '../../../../../utilities/date.utilities';
import Charts from '../../../../../utilities/charts.utilities';
import EngagementDetailCharts from '../../../engagement_detail.charts';
import { APISettings } from '../../../../../shared/services/migrated/axiosWrapper';
import { ScoreName } from '../../../../../shared/new-components/hf-score-name/hfScoreName';
import HappyLoading from '../../../../../shared/components/_migrated/loader/loading.directive';
import EmptyState from '../../../../../shared/components/_migrated/empty_state/empty_state';
import HfImpactMark from '../../impact_mark/HfImpactMark';
import {ScoreStatsResults} from '../../../../../shared/types/scores';
import {DataQuality} from '../../../../../shared/types/common';
import QualityPopoverText from '../../../shared/quality_popover/EngagementQualityPopover';
import {Grid, Link, Stack, Typography} from '@mui/material';

interface EngagementScoresSummaryRowProps {
    score: any;
    to: Date;
    dateGrouping: string;
    onShowDetail: (scoreId: string) => void;
}


const EngagementScoresSummaryRow: React.FC<EngagementScoresSummaryRowProps> = ({ onShowDetail, score, to, dateGrouping }) => {
    const [loading, setLoading] = useState(false);



    const [value, setValue] = useState<number>(0);
    const [quality, setQuality] = useState<DataQuality>(DataQuality.NO_DATA);
    const [valuationColor, setValuationColor] = useState<string>('');
    const [variation, setVariation] = useState<number>(0);
    const [from, setFrom] = useState<Date>(null);
    const [sparkChart, setSparkChart] = useState<any>(null);
    const [lastResult, setLastResult] = useState<number>(0);


    const [hiImpact, setHiImpact] = useState('');
    const [enpsImpact, setEnpsImpact] = useState('');
    const [noData, setNoData] = useState(false);
    const [status, setStatus] = useState('');
    const [noEnoughEmployees, setNoEnoughEmployees] = useState(false);
    const $translate = getService('$translate');
    const ErrorSvrc = getService('ErrorSvrc');
    const CompanySvrc = getService('CompanySvrc');
    const ScoresV2Service = getService('ScoresV2Srvc');

    const computeBenchmark = (results: any[], filtered: boolean) => {
        const benchmarkResults: number[] = [];
        let benchmarkAverage = 0;
        if (results) {
            let benchmarkSum = 0;
            let benchmarkCount = 0;
            results.forEach(result => {
                if (filtered && result.companyBenchmark > 0) {
                    benchmarkResults.push(Numbers.roundNumber(result.companyBenchmark, 1));
                    benchmarkSum += result.companyBenchmark;
                    benchmarkCount++;
                } else if (result.globalBenchmark > 0) {
                    benchmarkResults.push(Numbers.roundNumber(result.globalBenchmark, 1));
                    benchmarkSum += result.globalBenchmark;
                    benchmarkCount++;
                }
            });
            if (benchmarkCount > 0) {
                benchmarkAverage = Numbers.roundNumber(benchmarkSum / benchmarkCount, 1);
            } else {
                benchmarkAverage = 0;
            }
        }
        return {
            benchmarkResults: benchmarkResults,
            benchmarkAverage: benchmarkAverage
        };
    };

    const scoreSparkLineEvolution = (data: any[], benchmarkData: any, benchmarkLabel: string) => {
        Charts.initializeDateFormats();

        let dataMin: number | undefined;
        let dataMax: number | undefined;
        const seriesData: any[] = [];
        const benchmarkSeriesData: any[] = [];

        data.forEach((point, index) => {
            if (!dataMin || dataMin > point[1]) {
                dataMin = point[1];
            }
            if (!dataMax || dataMax < point[1]) {
                dataMax = point[1];
            }
            seriesData.push({
                x: Dates.fromAPIFormat(point[0], APISettings.apiDateFormat).getTime() + Charts.oneDayOffset,
                y: Numbers.roundNumber(point[1], 1),
                z: benchmarkData.benchmarkResults[index]
            });
            benchmarkSeriesData.push({
                x: Dates.fromAPIFormat(point[0], APISettings.apiDateFormat).getTime() + Charts.oneDayOffset,
                y: benchmarkData.benchmarkResults[index]
            });
        });

        const min = benchmarkData.benchmarkAverage < dataMin ? benchmarkData.benchmarkAverage - 0.1 : dataMin - 0.1;
        const max = benchmarkData.benchmarkAverage > dataMax ? benchmarkData.benchmarkAverage + 0.1 : dataMax + 0.1;

        return {
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            exporting: {
                enabled: false
            },
            chart: {
                type: 'line',
                style: {
                    fontFamily: 'Helvetica',
                    color: '#333',
                    fontSize: '12px',
                    fontWeight: 'normal',
                    fontStyle: 'normal'
                },
                height: 66,
                marginLeft: 2,
                marginRight: 2,
                marginTop: 2,
                marginBottom: 2
            },
            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: false
                    }
                }
            },
            yAxis: {
                visible: true,
                max: max,
                min: min,
                gridLineWidth: 0,
                gridLineDashStyle: 'ShortDot',
                gridLineColor: '#EEEEEE'
            },
            xAxis: {
                labels: {
                    enabled: false
                },
                type: 'datetime',
                alignTicks: true,
                endOnTick: false,
                minorGridLineWidth: 1,
                minorTickLength: 1,
                minorTicks: true,
                minorGridLineDashStyle: 'ShortDot',
                minorGridLineColor: '#EEEEEE',
                showFirstLabel: true,
                showEmpty: true,
                visible: true,
                lineWidth: 1,
                lineColor: '#E2E7E9',
                gridLineWidth: 0,
                gridLineColor: '#EEEEEE',
                gridLineDashStyle: 'ShortDot',
                tickWidth: 0
            },
            credits: {
                enabled: false
            },
            legend: {
                enabled: false
            },
            colors: [
                '#7cb5ec'
            ],
            pane: {
                background: []
            },
            responsive: {
                rules: []
            },
            tooltip: {
                shared: true,
                enabled: true,
                padding: 4,
                backgroundColor: '#ffffff',
                borderColor: '#4A90E2',
                useHTML: false,
                xDateFormat: Charts.getDateFormatFromDateGrouping(dateGrouping),
                headerFormat: '<span style="font-size: 10px">{point.key}: <b>{point.y}</b>' +
                    '</span><br/><span style="font-size: 10px">' + benchmarkLabel + ': <b>{point.z}</b></span>',
                pointFormat: ''
            },
            series: [
                {
                    name: 'spark',
                    marker: {
                        enabled: false
                    },
                    dashStyle: 'Solid',
                    lineWidth: 2,
                    zones: EngagementDetailCharts.scoreZones,
                    data: seriesData
                },
                {
                    name: 'spark',
                    color: '#4A90E2',
                    marker: {
                        enabled: false
                    },
                    dashStyle: 'dash',
                    lineWidth: 1,
                    data: benchmarkSeriesData
                }
            ]
        };
    };

    useEffect(() => {
        if (score) {
            setLoading(true);
            const params = {
                to: Dates.lastDayOfMonth(to),
                grouping: dateGrouping
            };

            ScoresV2Service.stats.get(score.id, params, (err: any, result: ScoreStatsResults) => {
                const chartResults: any[] = [];
                if (err) {
                    if (ErrorSvrc.isNotEnoughActiveEmployees(err)) {
                        setNoEnoughEmployees(true);
                        setNoData(false);
                    } else {
                        setNoData(true);
                        ErrorSvrc.showErrorModal(err);
                    }
                } else {
                    if (score && result.results && result.results.length > 0) {
                        setStatus(score.status);

                        const lastResult = result.results.slice(-1)[0];

                        setValue(Numbers.roundNumber(lastResult.result, 1));
                        setQuality(lastResult.quality);
                        setValuationColor(Valuation.valuationColor(lastResult.valuation));
                        setVariation(Numbers.roundNumber(lastResult.variation, 1));
                        setFrom(Dates.fromAPIFormat(lastResult.from, APISettings.apiDateFormat));

                        setHiImpact(lastResult.hiImpact);
                        setEnpsImpact(lastResult.enpsImpact);

                        const previousResultIndex = result.results.length - 2;
                        if (previousResultIndex >= 0) {
                            setLastResult(Numbers.roundNumber(result.results[previousResultIndex].result, 1));
                        } else {
                            setLastResult(0);
                        }

                        const allNoData = result.results.every(result => result.quality === 'NO_DATA');
                        setNoData(allNoData);

                        if (!allNoData) {
                            result.results.forEach(result => {
                                chartResults.push([result.from, result.result]);
                            });

                            CompanySvrc.getUserCompany((err: any, company: any) => {
                                let benchmarkName;
                                if (err || !score.filtered) {
                                    benchmarkName = $translate.instant('Benchmark');
                                } else {
                                    benchmarkName = $translate.instant('Avg.') + company.name;
                                }
                                const benchmarkData = computeBenchmark(result.results, result.filtered);
                                const sparkChartConfig = scoreSparkLineEvolution(chartResults, benchmarkData, benchmarkName);
                                setSparkChart(sparkChartConfig);
                            });
                        }

                        setNoEnoughEmployees(false);
                    } else {
                        setNoData(true);
                    }
                }
                setLoading(false);
            });
        }
    }, [score, to, dateGrouping]);


    const getContent = () => {
        if (noEnoughEmployees) {
            return (
                <Grid item xs={12} sm={9} paddingTop={0}>
                    <EmptyState
                        image="images/anonymous.png"
                        small={true}
                        message={$translate.instant('NOT_ENOUGH_EMPLOYEES')}
                    />
                </Grid>
            );
        } else if (noData) {
            return (
                <Grid item xs={12} sm={9} paddingTop={0}>
                    <EmptyState
                        image="images/no_scores.svg"
                        small={true}
                        message={$translate.instant('NO_DATA')}
                    />
                </Grid>
            );
        } else {
            return (
                <>
                    <Grid item xs={9} sm={6} paddingTop={0}>
                        <HappyLoading loading={loading} noMessage={true} skeleton={3}>
                            {sparkChart && (
                                <HighchartsReact highcharts={Highcharts} options={sparkChart} />
                            )}
                        </HappyLoading>
                    </Grid>
                    <Grid item xs={0} sm={3} paddingTop={0}>
                        <Stack direction="column" gap={1}>

                            <Stack direction="column" gap={0.5}>
                                <Typography variant="caption">
                                    {$translate.instant('ENGAGEMENT_IMPACT_HI')}
                                </Typography>

                                <HfImpactMark level={hiImpact} color={valuationColor} loading={loading}/>
                            </Stack>

                            <Stack direction="column" gap={0.5}>

                                <Typography variant="caption">
                                    {$translate.instant('ENGAGEMENT_IMPACT_ENPS')}
                                </Typography>

                                <HfImpactMark level={enpsImpact} color={valuationColor} loading={loading}/>
                            </Stack>



                        </Stack>

                    </Grid>
                </>
            );
        }
    };

    return (
        <Stack gap={2}>

            <Link onClick={() => onShowDetail(score.id)}>
                <Typography variant="body3">
                    <ScoreName scoreId={score.id}/>
                </Typography>
            </Link>


            <HappyLoading loading={loading} noMessage={true}>
                <Grid container spacing={2} alignItems={'center'}>
                    <Grid item xs={12} sm={3} paddingTop={0}>
                        {!noEnoughEmployees && !noData && value > 0 && (

                            <Stack direction="row" alignItems="baseline" gap={0.5}>
                                <Typography variant="h1" fontWeight={'bold'} color={valuationColor}>
                                    {value}
                                </Typography>
                                <Typography variant="caption" fontWeight={'bold'}  color={valuationColor}>
                                    pts
                                </Typography>
                            </Stack>
                        )}

                        {!noEnoughEmployees && noData && (
                            <Typography variant="h2" fontWeight={'bold'} color={valuationColor}>
                                {$translate.instant('SCORE_NO_DATA_SHORT')}
                            </Typography>
                        )}

                        {!noEnoughEmployees && (
                            <QualityPopoverText quality={quality} status={status} />
                        )}
                    </Grid>

                    {getContent()}
                </Grid>
            </HappyLoading>


        </Stack>
    );
};

export default EngagementScoresSummaryRow;
