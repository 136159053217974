import React from 'react';

import ReactDOMClient from 'react-dom/client';

import isPlainObject from 'lodash/isPlainObject';

import isEqual from 'lodash/isEqual';

import {createTheme, Shadows, ThemeProvider} from '@mui/material';


import {LocalizationProviderWrapper} from '../LocalizationProviderWrapper';
import ExtendedTypographyOptions from './theme-extensions';
import type {} from '@mui/x-tree-view/themeAugmentation';


/*
  While we're migrating to React, we need to be able make MUI components to look like our old AngularJS components
  as much as possible on pages where we have both worlds at the same time.
  For this reason, we need to be able to use the same colors, fonts, and other styles that we use
    in AngularJS. This MUI theme override does that. Feel free to add more and more overrides until we are done
    to be able to use the new MUI stuff.
 */


const mediumStyleFontSize = 16;
const smallStyleFontSize = 14;
const miniStyleFontSize = 12;
const defaultStyleSize = 18;
const defaultFontFamily = 'Helvetica';





const theme = createTheme({
    //shadows: Array(25).fill('none') as Shadows, // Remove shadows on the 25 levels required by MUI
    palette: {
        primary: {
            main: '#4A90E2',
            dark: '#185195',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#ffffff',
            dark: '#ffffff',
            contrastText: '#4A90E2',
        },
        text: {
            primary: 'rgba(0, 0, 0, 0.87)',
            secondary: '#999999',
            disabled: 'rgba(0, 0, 0, 0.38)'
        }

    },
    iconSizes: {
        small: 16,
        medium: 24,
        large: 32,
    },
    typography: {
        fontFamily: defaultFontFamily,
        spacing: 4,
        fontSize: 16,
        htmlFontSize: 16,

        h1: {
            fontFamily: defaultFontFamily,
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '36px',
            letterSpacing: '0em',
        },
        h2: {
            fontFamily:  defaultFontFamily,
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '30px',
            letterSpacing: '0em',
        },
        h3: {
            fontFamily:  defaultFontFamily,
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '28px',
            letterSpacing: '0em',
        },
        h4: {
            fontFamily:  defaultFontFamily,
            fontSize: `${defaultStyleSize}px`,
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '24px',
            letterSpacing: '0em',
        },
        h5: {
            fontFamily:  defaultFontFamily,
            fontSize: `${smallStyleFontSize}px`,
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '16px',
            letterSpacing: '0em',
        },
        h6: {
            fontFamily:  defaultFontFamily,
            fontSize: `${smallStyleFontSize}px`,
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '16px',
            letterSpacing: '0em',
        },
        subtitle1: {
            fontFamily: defaultFontFamily,
            fontSize: `${smallStyleFontSize}px`,
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '16px',
            letterSpacing: '0em',
        },
        subtitle2: {
            fontFamily:  defaultFontFamily,
            fontSize: `${smallStyleFontSize}px`,
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '16px',
            letterSpacing: '0em',
        },
        body1: { // Default
            fontFamily:  defaultFontFamily,
            fontSize: `${defaultStyleSize}px`,
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '28px',
            letterSpacing: '0em',
        },
        body2: { // Default
            fontFamily:  defaultFontFamily,
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
            letterSpacing: '0em',
        },
        body3: { // Default
            fontFamily:  defaultFontFamily,
            fontSize: `${smallStyleFontSize}px`,
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '16px',
            letterSpacing: '0em',
        },
        caption: {
            fontFamily:  defaultFontFamily,
            fontSize: `${miniStyleFontSize}px`,
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '14px',
            letterSpacing: '0em',
        },
        button: {
            fontSize: `${smallStyleFontSize}px`,
            fontWeight: 400,
            lineHeight: '20px',
        },

        bigNumber: {
            fontSize: '60px',
            fontStyle: 'normal',
            fontWeight: 'bold',
            lineHeight: '69px',
        }
    } as ExtendedTypographyOptions,
    components: {

        MuiPopover: {
            styleOverrides: {
                root: {
                    zIndex: 3000
                }
            }

        },




        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: `${smallStyleFontSize}px`
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    fontSize: `${smallStyleFontSize}px`
                }
            }
        },
        MuiMenu : {
            styleOverrides: {
                root: {
                    zIndex: 3000
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: `${smallStyleFontSize}px`
                }
            }
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontSize: `${smallStyleFontSize}px`
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    fontSize: `${smallStyleFontSize}px`,
                    fontWeight: 'bold',
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiInputBase-input': {
                        fontSize: `${smallStyleFontSize}px`
                    },
                    '& .MuiInputLabel-root': {
                        fontSize: `${smallStyleFontSize}px`
                    },
                },
            }
        },
        MuiButtonBase: {
            styleOverrides: {
                // Name of the slot
                root: {
                    textTransform: 'inherit',
                    // Some CSS
                    borderRadius: 3,
                    '&.Mui-disabled': {
                        backgroundColor: 'transparent', // Fondo transparente para todos los botones deshabilitados
                    },
                },
            },
            defaultProps: {
                disableRipple: true, // No more ripple, on the whole application 💣!
                focusRipple: false,
                disableTouchRipple: true,
            }
        },
        MuiButton: {
            styleOverrides: {
                // Name of the slot
                root: {
                    textTransform: 'inherit',
                    // Some CSS
                    border: '1px solid',
                    boxShadow: 'none',
                    '&.Mui-disabled': {
                        backgroundColor: 'transparent', // Fondo transparente para todos los botones deshabilitados
                    },
                },
                text: {
                    border: '0px solid'
                }
            },
            defaultProps: {
                disableRipple: true, // No more ripple, on the whole application 💣!
                focusRipple: false,
                disableTouchRipple: true,
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    fontSize: `${miniStyleFontSize}px`
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontFamily: 'Helvetica',
                    fontSize: `${miniStyleFontSize}px`,
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '14px',
                    letterSpacing: '0em',
                    color: '#2d3133',
                    transform: 'none',
                }
            }
        },
        MuiChip: {
            styleOverrides : {
                root: {
                    fontFamily:  defaultFontFamily,
                    fontSize: `${smallStyleFontSize}px`,
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '16px',
                    letterSpacing: '0em',
                }
            }

        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontFamily: 'Helvetica',
                    fontSize: `${miniStyleFontSize}px`,
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '14px',
                    letterSpacing: '0em',
                    color: '#2d3133',
                    transform: 'none',
                }
            }
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    fontSize: `${smallStyleFontSize}px`,
                    color: '#4A90E2',
                    textDecoration: 'none',
                    onHover: {
                        textDecoration: 'underline',
                    },
                }
            }
        },
        MuiTreeItem: {
            styleOverrides: {
                root: {
                    fontSize: `${smallStyleFontSize}px`,
                    '& > .MuiTreeItem-content': {
                        fontSize: `${smallStyleFontSize}px`,
                        '& > .MuiTreeItem-label': {
                            fontSize: `${smallStyleFontSize}px`,
                        },
                    },

                },
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    fontSize: `${smallStyleFontSize}px`,
                    padding: '0px'
                },
                input: {
                    paddingTop: '8px !important',
                    paddingBottom: '8px !important'
                }
            }
        },
    }
});


function wrapComponent(root, Component, scope) {



    root.render(  <ThemeProvider theme={theme}>
        <LocalizationProviderWrapper>
            {React.createElement(Component, scope)}
        </LocalizationProviderWrapper>

    </ThemeProvider>);
}


/*
  This is a utility for migrating AngularJS components to React
 */


function angularize(Component, componentName, angularApp, bindings) {
    bindings = bindings || {};
    if (typeof window === 'undefined' || typeof angularApp === 'undefined')
    {return;}

    angularApp.component(componentName, {

        bindings,
        controller: [
            '$element',
            function ($element) {
                // Create react root for this element
                this.root = ReactDOMClient.createRoot($element[0]);

                if (window.angular) {
                    // Add $scope
                    this.$scope = window.angular.element($element).scope();

                    // Create a map of objects bound by '='
                    // For those that exists, use $doCheck to check them using angular.equals and trigger $onChanges
                    const previous = {};
                    this.$onInit = () => {
                        for (const bindingKey of Object.keys(bindings)) {
                            if (/^data[A-Z]/.test(bindingKey)) {
                                console.warn(
                                    `'${bindingKey}' binding for ${componentName} component will be undefined because AngularJS ignores attributes starting with data-`
                                );
                            }

                            if (bindings[bindingKey] === '=') {
                                previous[bindingKey] = window.angular.copy(this[bindingKey]);
                            }
                        }
                    };

                    this.$doCheck = () => {
                        for (const previousKey of Object.keys(previous)) {
                            if (!equals(this[previousKey], previous[previousKey])) {
                                this.$onChanges();
                                previous[previousKey] = window.angular.copy(this[previousKey]);
                                return;
                            }
                        }
                    };
                }

                this.$onChanges = () => {
                    wrapComponent(this.root, Component, this)

                };

                this.$onDestroy = () => {
                    this.root.unmount();
                };
            },
        ],
    });
}

function angularizeDirective(Component, directiveName, angularApp, bindings) {
    bindings = bindings || {};
    if (typeof window === 'undefined' || typeof angularApp === 'undefined')
    {return;}

    angularApp.directive(directiveName, function () {
        return {
            scope: bindings,
            replace: true,
            link: function (scope, element) {
                // Add $scope
                scope.$scope = scope;
                const root = ReactDOMClient.createRoot(element[0]);

                // First render - needed?
                wrapComponent(root, Component, scope);

                // Watch for any changes in bindings, then rerender
                const keys = [];
                for (const bindingKey of Object.keys(bindings)) {
                    if (/^data[A-Z]/.test(bindingKey)) {
                        console.warn(
                            `"${bindingKey}" binding for ${directiveName} directive will be undefined because AngularJS ignores attributes starting with data-`
                        );
                    }
                    if (bindings[bindingKey] !== '&') {
                        keys.push(bindingKey);
                    }
                }

                scope.$watchGroup(keys, (root) => {
                    wrapComponent(root, Component, scope);
                });

                scope.$on('$destroy', function () {
                    root.unmount();
                });
            },
        };
    });
}

function getService(serviceName) {
    if (typeof window === 'undefined' || typeof window.angular === 'undefined')
    {return {};}
    return window.angular.element(document.body).injector().get(serviceName);
}

function equals(o1, o2) {
    // Compare plain objects without equality check that angular.equals does
    if (isPlainObject(o1) && isPlainObject(o2)) {
        return isEqual(o1, o2);
    }
    return window.angular.equals(o1, o2);
}


export {angularize, angularizeDirective, getService};
