'use strict';

import { doGet, doPost, doPut, doDelete } from './axiosWrapper';

const baseUrl = '/company/hierarchy';

// Types:



export const postHierarchy = function (selectedLevel, success, error) {
    const url = `${baseUrl}`;
    doPost(url, selectedLevel, null,function (err, result) {
        if (err) {
            error(err);
        } else {
            success(result);
        }
    });
};

export const putHierarchy = function (selectedLevel, id, success, error) {
    const url = `${baseUrl}/${id}`;
    doPut(url, selectedLevel, function (err, result) {
        if (err) {
            error(err);
        } else {
            success(result);
        }
    });
};

export const deleteHierarchy = function (id, success, error) {
    const url = `${baseUrl}/${id}`;
    doDelete(url, {}, function (err, result) {
        if (err) {
            error(err);
        } else {
            success(result);
        }
    });
};


export const getHierarchyAll = function (callback) {
    const url = `${baseUrl}`;
    doGet(url, {}, callback);
};

export const getHierarchyName = function (hierarchyId, callback) {

    const url = `${baseUrl}/${hierarchyId}`;
    doGet(url, {}, function (err, result) {
        if (err) {
            callback(err, null);
        } else {
            callback(null, result.name);
        }
    });
};

export const getHierarchyById = function (hierarchyId, callback) {

    const url = `${baseUrl}/${hierarchyId}`;
    doGet(url, {}, callback);
};

export const getHierarchies = function (callback) {
    doGet(baseUrl, {}, callback);
};

export const hasHierarchies = function (callback) {
    doGet(baseUrl, {}, function (err, result) {
        if (err) {
            callback(err);
        } else {
            callback(null, result.length > 0);
        }
    });
};

export const isMultiHierarchies = function (callback) {

    doGet(baseUrl, {}, function (err, data) {
        if (err) {
            callback(err);
        } else {
            callback(null, data.length > 0 || data.some(hierarchy => (hierarchy.children || hierarchy.children.length > 0)));
        }
    });

};


export const getHierarchyPath = function (id, callback) {
    const _path = [];

    const recursive = (hierarchyId) => {
        getHierarchyById(hierarchyId, (err, hierarchy) => {
            if (err) return callback(err, null);
            if (hierarchy) {
                _path.unshift(hierarchy);
                if (hierarchy.parentId) {
                    recursive(hierarchy.parentId);
                } else {
                    callback(err, _path);
                }
            } else {
                callback(err, _path);
            }
        });
    };
    recursive(id);
};
