'use strict';
import { doGet, doPost, doPut } from './axiosWrapper';

const baseUrl = '/company/culture/values';

export function getCultureValues(callback) {
    const url = `${baseUrl}`;
    doGet(url, {}, callback);
}

export function addCultureValue(value, callback) {
    const url = `${baseUrl}`;
    doPost(url, value, null, callback);
}

export function updateCultureValue(value, callback) {
    const url = `${baseUrl}/${value.id}`;
    doPut(url, value, callback);
}
