import React, {useEffect, useState} from 'react';
import { angularize, getService } from 'reactInAngular';
import {Checkbox, Button, TextField, Box, Typography, Grid, Stack} from '@mui/material';
import angular from 'angular';


interface SettingsNotificationsProps {
    companyConfig: any;
    onSave: (companyConfig: any) => void;
}

const SettingsAnnouncementsQuestionNotifications = ({
    companyConfig,
    onSave
} : SettingsNotificationsProps) => {
    const $translate = getService('$translate');


    const [announcementNotificationDays, setAnnouncementNotificationDays] = useState([]);
    const [announcementNotificationStartHour, setAnnouncementNotificationStartHour] = useState(0);
    const [announcementNotificationEndHour, setAnnouncementNotificationEndHour] = useState(0);

    const [questionNotificationDays, setQuestionNotificationDays] = useState([]);
    const [questionNotificationStartHour, setQuestionNotificationStartHour] = useState(0);
    const [questionNotificationEndHour, setQuestionNotificationEndHour] = useState(0);

    /* Get from the configuration the days that are included in the notification for questions & announcements */


    useEffect(() => {

        // Get the announcements Days:
        const announcementNotificationDays = companyConfig?.announcementNotificationDays || [];
        setAnnouncementNotificationDays(announcementNotificationDays);
        setAnnouncementNotificationStartHour(companyConfig?.announcementNotificationStartHour || 0);
        setAnnouncementNotificationEndHour(companyConfig?.announcementNotificationEndHour || 0);


        // Get the questions Days:
        const questionNotificationDays = companyConfig?.questionNotificationDays || [];
        setQuestionNotificationDays(questionNotificationDays);
        setQuestionNotificationStartHour(companyConfig?.questionNotificationStartHour || 0);
        setQuestionNotificationEndHour(companyConfig?.questionNotificationEndHour || 0);


    }, [companyConfig]);



    const handleSave = () => {

        // Ok, now we need to save the configuration; for this we will clone the companyConfig and update the values
        const updatedCompanyConfig = {...companyConfig};
        updatedCompanyConfig.announcementNotificationDays = announcementNotificationDays;
        updatedCompanyConfig.announcementNotificationStartHour = announcementNotificationStartHour;
        updatedCompanyConfig.announcementNotificationEndHour = announcementNotificationEndHour;

        updatedCompanyConfig.questionNotificationDays = questionNotificationDays;
        updatedCompanyConfig.questionNotificationStartHour = questionNotificationStartHour;
        updatedCompanyConfig.questionNotificationEndHour = questionNotificationEndHour;

        onSave(updatedCompanyConfig);

    }

    const renderDaysCheckbox = (type, days, onChange) => {




        return (
            <>
                {[1, 2, 3, 4, 5, 6, 7].map((day) => (
                    <Box key={`${type}-${day}`} mr={2}>
                        <Checkbox
                            value={day}
                            checked={days.includes(day)}
                            onChange={onChange}
                        />
                        <Typography variant="body2" component="span">
                            {$translate.instant(['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'][day - 1])}
                        </Typography>
                    </Box>
                ))}
            </>
        );

    };



    if (!companyConfig) {
        return null;
    }

    return (
        <Stack gap={2}>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={10}>
                    <Typography variant="h2" fontWeight="bold">
                        {$translate.instant('QUESTION_ANNOUNCEMENTS_NOTIFICATION_SETTINGS')}
                    </Typography>
                    <Typography variant="body3" color="text.secondary">
                        {$translate.instant('QUESTION_ANNOUNCEMENTS_NOTIFICATION_SETTINGS_DESCRIPTION')}
                    </Typography>
                </Grid>
                <Grid item xs={2} display={'flex'} justifyContent={'end'}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                    >
                        {$translate.instant('SAVE')}
                    </Button>
                </Grid>
            </Grid>

            <Stack gap={1}>
                <Typography variant="h4" fontWeight="bold">
                    {$translate.instant('ANNOUNCEMENT_NOTIFICATION_SETTINGS')}
                </Typography>
                <Typography variant="body3" color="text.secondary">
                    {$translate.instant('ANNOUNCEMENT_NOTIFICATION_SETTINGS_DESCRIPTION')}
                </Typography>
            </Stack>

            <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                    <Stack gap={1}>
                        <Typography variant="h6" fontWeight="bold">
                            {$translate.instant('ANNOUNCEMENT_NOTIFICATION_DAYS')}
                        </Typography>
                        <Typography variant="body3" color="text.secondary">
                            {$translate.instant('ANNOUNCEMENT_NOTIFICATION_DAYS_DESCRIPTION')}
                        </Typography>

                        <Stack direction="row">
                            {renderDaysCheckbox(
                                'announcements',
                                announcementNotificationDays,
                                (e) => {

                                    const handleDay = Number(e.target.value);

                                    if (e.target.checked) {
                                        setAnnouncementNotificationDays([...announcementNotificationDays, handleDay]);
                                    } else {
                                        setAnnouncementNotificationDays(announcementNotificationDays.filter((day) => day !== handleDay));
                                    }

                                }
                            )}
                        </Stack>

                    </Stack>

                </Grid>

                <Grid item xs={12} lg={6}>

                    <Stack gap={1}>
                        <Typography variant="h6" fontWeight="bold">
                            {$translate.instant('ANNOUNCEMENT_NOTIFICATION_IN_HOUR')}
                        </Typography>
                        <Typography variant="body3" color="text.secondary">
                            {$translate.instant('ANNOUNCEMENT_NOTIFICATION_IN_HOUR_DESCRIPTION')}
                        </Typography>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={5}>
                                <TextField
                                    type="number"
                                    inputProps={{ min: 0, max: 24 }}
                                    value={announcementNotificationStartHour}
                                    onChange={(e) => setAnnouncementNotificationStartHour(Number(e.target.value))}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={2} textAlign="center">
                                -
                            </Grid>
                            <Grid item xs={5}>
                                <TextField
                                    type="number"
                                    inputProps={{ min: 0, max: 24 }}
                                    value={announcementNotificationEndHour}
                                    onChange={(e) => setAnnouncementNotificationEndHour(Number(e.target.value))}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Stack>


                </Grid>
            </Grid>

            <Stack gap={1}>
                <Typography variant="h4" fontWeight="bold">
                    {$translate.instant('QUESTION_NOTIFICATION_SETTINGS')}
                </Typography>
                <Typography variant="body3" color="text.secondary">
                    {$translate.instant('QUESTION_NOTIFICATION_SETTINGS_DESCRIPTION')}
                </Typography>
            </Stack>

            <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                    <Stack gap={1}>
                        <Typography variant="h6" fontWeight="bold">
                            {$translate.instant('QUESTION_NOTIFICATION_DAYS')}
                        </Typography>
                        <Typography variant="body3" color="text.secondary">
                            {$translate.instant('QUESTION_NOTIFICATION_DAYS_DESCRIPTION')}
                        </Typography>

                        <Stack direction="row">
                            {renderDaysCheckbox(
                                'questions',
                                questionNotificationDays,
                                (e) => {

                                    const handleDay = Number(e.target.value);

                                    if (e.target.checked) {
                                        setQuestionNotificationDays([...questionNotificationDays, handleDay]);
                                    } else {
                                        setQuestionNotificationDays(questionNotificationDays.filter((day) => day !== handleDay));
                                    }
                                }
                            )}
                        </Stack>
                    </Stack>


                </Grid>

                <Grid item xs={12} lg={6}>

                    <Stack gap={1}>
                        <Typography variant="h6" fontWeight="bold">
                            {$translate.instant('QUESTION_NOTIFICATION_IN_HOUR')}
                        </Typography>
                        <Typography variant="body3" color="text.secondary">
                            {$translate.instant('QUESTION_NOTIFICATION_IN_HOUR_DESCRIPTION')}
                        </Typography>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={5}>
                                <TextField
                                    type="number"
                                    inputProps={{ min: 0, max: 24 }}
                                    value={questionNotificationStartHour}
                                    onChange={(e) => setQuestionNotificationStartHour(Number(e.target.value))}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={2} textAlign="center">
                                -
                            </Grid>
                            <Grid item xs={5}>
                                <TextField
                                    type="number"
                                    inputProps={{ min: 0, max: 24 }}
                                    value={questionNotificationEndHour}
                                    onChange={(e) => setQuestionNotificationEndHour(Number(e.target.value))}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Stack>


                </Grid>
            </Grid>
        </Stack>
    );
};


angularize(
    SettingsAnnouncementsQuestionNotifications,
    'hfSettingsNotifications',
    angular.module('happyForceApp'),
    {
        companyConfig: '<',
        onSave: '<',
    }
);

export default SettingsAnnouncementsQuestionNotifications;
