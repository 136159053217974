import { NestedMenuItem } from 'mui-nested-menu';
import { MenuItem, Checkbox, Radio, ListItemText, Box, Typography } from '@mui/material';
import { Option } from '../types';

interface OptionNestedMenuItemProps {
    open?: boolean;
    options: Option[]; // All available options
    filters: any[]; // Only the selected options for the filters
    onAddFilter: (type, filters: string[]|boolean[]) => void;
    title: string;
    type: string;
    mode: string;
    nonRemovable: boolean;
}

const OptionNestedMenuItem = ({ open, options, filters, onAddFilter, title, type, mode, nonRemovable }: OptionNestedMenuItemProps) => {
    const handleFilter = (item) => {
        const alreadyExists = filters?.indexOf(item.id) > -1;

        if (mode === 'single') {
            // If it's already selected, remove it
            if (alreadyExists) {
                // .. only if it's not nonRemovable
                if (!nonRemovable) {
                    onAddFilter(type, []);
                } else {
                    return;
                }
            } else {
                onAddFilter(type, [item.id]);
                return;
            }
        } else if (mode === 'multi' || mode === 'grouped') {

            // If it's already selected, remove it
            if (alreadyExists) {
                // .. only if it's not nonRemovable or there are more than one selected
                if (!nonRemovable || filters.length > 1 ) {
                    onAddFilter(type, filters.filter(value => value !== item.id));
                } else {
                    return;
                }
            } else {
                onAddFilter(type, [...(filters ?? []), item.id]);
            }
        }

    }

    const renderOptions = (options: Option[]) => {
        if (!options) {
            return null;
        }

        if (mode === 'multi') {
            return options.map((item, index) => {
                // const isChecked = selectedOptions.findIndex(e => e.id === item.id) > -1;

                const isChecked = filters?.indexOf(item.id) > -1;
                if (item.children && item.children.length > 0) {
                    return (
                        <NestedMenuItem
                            key={index}
                            sx={{ paddingLeft: 1 }}
                            onClick={() => !item.unselectable && handleFilter(item)}
                            renderLabel={() =>
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    {!item.unselectable && <Checkbox
                                        checked={isChecked} />}
                                    <ListItemText primary={
                                        <Typography style={{ fontSize: '14px' }}>
                                            {item.title}
                                        </Typography>
                                    } />
                                </Box>
                            }
                            parentMenuOpen={open}
                            button={true}
                        >
                            {renderOptions(item.children)}
                        </NestedMenuItem>
                    );
                } else {
                    return (
                        <MenuItem sx={{ paddingLeft: 1 }} key={index} onClick={() => handleFilter(item)}>
                            <Checkbox checked={isChecked} />
                            <ListItemText primary={
                                <Typography style={{ fontSize: '14px' }}>
                                    {item.title}
                                </Typography>
                            } />
                        </MenuItem>
                    );
                }
            });
        } else if (mode === 'single') {
            return options.map((item, index) => {
                // If it's in the selectedOptions array... pass the type and check if the id exists in the values array

                const isSelected = filters?.indexOf(item.id) > -1;
                if (item.children && item.children.length > 0) {
                    return (
                        <NestedMenuItem
                            key={index}
                            sx={{ paddingLeft: 1 }}
                            onClick={() => !item.unselectable && handleFilter(item)}
                            renderLabel={() =>
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    {!item.unselectable && <Radio
                                        checked={isSelected}
                                    />}
                                    <ListItemText primary={
                                        <Typography style={{ fontSize: '14px' }}>
                                            {item.title}
                                        </Typography>
                                    } />
                                </Box>
                            }
                            parentMenuOpen={open}
                            button={true}
                        >
                            {renderOptions(item.children)}
                        </NestedMenuItem>
                    );
                } else {
                    return (
                        <MenuItem
                            sx={{ paddingLeft: 1 }}
                            key={index}
                            onClick={() => handleFilter(item)}
                        >
                            <Radio
                                checked={isSelected}
                            />
                            <ListItemText primary={
                                <Typography style={{ fontSize: '14px' }}>
                                    {item.title}
                                </Typography>
                            } />
                        </MenuItem>
                    );
                }
            });
        } else {
            // TODO: GROUPED
        }
    };

    return (
        <NestedMenuItem
            renderLabel={() => <ListItemText primary={
                <Typography style={{ fontSize: '14px', paddingLeft: 10 }}>
                    {title}
                </Typography>
            } />}
            parentMenuOpen={open}
        >
            {renderOptions(options)}
        </NestedMenuItem>
    );
};

export default OptionNestedMenuItem;
