import EngagementDetailDrawer from '../shared/big-drawer/EngagementDetailDrawer';
import {getService} from '../../../../migration_utils/react-in-angular';
import React, {useEffect, useMemo, useState} from 'react';
import {DataQuality, DateGrouping, ValuationType} from '../../../shared/types/common';
import {
    CommonSegmentationFilters,
    GroupFiltersConfig,
    HierarchyFiltersConfig, SegmentFiltersConfig
} from '../../../shared/new-components/hf-filter-menu/types';
import Dates from '../../../utilities/date.utilities';
import {toSegmentParams} from '../shared/utils';
import Numbers from '../../../utilities/number.utilities';
import moment from 'moment/moment';
import HIReportModal from './components/hi_report_modal/HiReportModal';
import {Box, ButtonGroup, Grid, Stack, Typography} from '@mui/material';
import HfFilterMenu from '../../../shared/new-components/hf-filter-menu/hfFilterMenu';
import EngagementDateBrowser from '../shared/date_browser/EngagementDateBrowser';
import Button from '@mui/material/Button';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import {WhiteBgPaper} from '../../../shared/new-components/hf-styled-components/WhiteBgPaddedPapper';
import QualityPopoverText from '../shared/quality_popover/EngagementQualityPopover';
import EmptyState from '../../../shared/components/_migrated/empty_state/empty_state';
import HiDetailOverview from './components/overview/HIOverview';
import EngagementDetailEvolutionChart from '../shared/evolution_chart/EngagementEvolutionChart';
import EngagementRankingBox from '../shared/ranking_box/ranking_box.controller';
import BenchmarkBox from '../shared/benchmark_box/benchmark_box';
import HFInfoIconWithTooltip from '../../../shared/new-components/hf-info-icon-with-tooltip';
import HIFunnelBox from './components/hi_funnel_box/HIFunnelBox';
import EngagementHiIndividualHeatmapBox from './components/hi_individual_heatmap/HIIndividualHeatmap';
import HiPositioningBox from './components/hi_positioning_box/HIPositioningChart';
import ScoresImpactChart from '../shared/scores_impact_chart/ScoresImpactChart';
import EngagementScoresFeedbackBox from '../components/feedback_box/EngagementFeedbackBox';


export interface HIDetailShowParams {
    visible: boolean;
    to: Date;
    dateGrouping: DateGrouping;
    filters: CommonSegmentationFilters;
}

interface HIDetailDrawerProps {

    showParams: HIDetailShowParams;
    onClose: () => void;

}


const HIDetailDrawer = ({showParams, onClose}: HIDetailDrawerProps) => {


    const $translate = getService('$translate');
    const ErrorSvrc = getService('ErrorSvrc');


    const Filters = getService('Filters');
    const HIService = getService('HIService');

    const Reports = getService('Reports');

    const Analytics = getService('Analytics');
    const toaster = getService('toaster');


    const [from, setFrom] = useState<Date>(null);
    const [to, setTo] = useState<Date>(null);
    const [quality, setQuality] = useState<DataQuality | null>(null);
    const [hasEnoughActiveEmployees, setHasEnoughActiveEmployees] = useState(true);
    const [loading, setLoading] = useState(false);
    const [filtered, setFiltered] = useState<boolean>(false);


    const [valuation, setValuation] = useState<ValuationType | null>(null);
    const [value, setValue] = useState<number | null>(null);
    const [variation, setVariation] = useState<number | null>(null);
    const [measureDate, setMeasureDate] = useState<Date | null>(null);
    const [globalBenchmark, setGlobalBenchmark] = useState<number | null>(null);
    const [industryBenchmark, setIndustryBenchmark] = useState<number | null>(null);
    const [companyBenchmark, setCompanyBenchmark] = useState<number | null>(null);
    const [ranking, setRanking] = useState<number | null>(null);
    const [results, setResults] = useState<any[]>([]);
    const [noData, setNoData] = useState(false);
    const [summaryView, setSummaryView] = useState<number>(1);

    const [showReportModal, setShowReportModal] = useState(false);
    const [exportingExcelReport, setExportingExcelReport] = useState(false);

    const [showDrawer, setShowDrawer] = useState<boolean>(false);

    const [filters, setFilters] = useState<CommonSegmentationFilters>({
        hierarchyIds: [],
        segmentIds: [],
        groupIds: [],
    });


    const hierarchyFiltersConfig: HierarchyFiltersConfig = useMemo(() => {
        return {
            id: 'HIERARCHY',
            type: 'hierarchyIds',
            mode: 'single',
            title: $translate.instant('AREA'),
            nonRemovable: false,
        }
    }, []);


    const groupFiltersConfig: GroupFiltersConfig = useMemo(() => {
        return {
            id: 'GROUP',
            type: 'groupIds',
            mode: 'multi',
            title: $translate.instant('GROUPS'),
            nonRemovable: false,
            groups: null,
        }
    }, []);


    const segmentFiltersConfig: SegmentFiltersConfig = useMemo(() => {
        return {
            id: 'SEGMENT',
            type: 'segmentIds',
            mode: 'multi',
            title: $translate.instant('SEGMENTED'),
            nonRemovable: false,
            segments: null
        }

    }, []);



    useEffect(() => {
        if (showParams?.visible) {
            setShowDrawer(true);
            setFilters(showParams.filters);
            const toValue = showParams.to ? showParams.to : Filters.endDate();
            setTo( toValue);
        } else {
            setShowDrawer(false);
        }
    }, [showParams]);


    const prepareParams = () => {
        const params = {
            from: Dates.firstDayOfMonth(to),
            to: Dates.lastDayOfMonth(to),
            filters: toSegmentParams(filters?.segmentIds as any[]),
            groupId: filters?.groupIds,
            hierarchyId: filters?.hierarchyIds
        };

        return params;
    }



    const loadResults =  () => {

        const params = prepareParams();

        HIService.stats.get(params, function (error, response) {
            if (error) {
                if (ErrorSvrc.isNotEnoughActiveEmployees(error)) {
                    setHasEnoughActiveEmployees(false);
                    setResults([]);
                } else {
                    console.error('Error getting engagement hi detail stats', error);
                }
            } else {
                setHasEnoughActiveEmployees(true);


                if (!response.results || response.results.length === 0) {
                    setNoData(true);
                } else {
                    setNoData(false);

                    setFiltered(response.filtered);

                    const numResults = response.results.length;
                    const globalBenchmark = response.globalBenchmark;
                    const industryBenchmark = response.industryBenchmark;
                    const companyBenchmark = response.companyBenchmark;
                    const ranking = response.ranking;
                    const current = response.results[numResults - 1];
                    const value = current.periodIndex;
                    const variation = current.periodIndexVariation;

                    setQuality(current.quality);
                    setValuation(current.valuation);
                    setValue(Numbers.roundNumber(value, 1));
                    setVariation(Numbers.roundNumber(variation, 1));
                    setMeasureDate(current.from);
                    setGlobalBenchmark(globalBenchmark);
                    setIndustryBenchmark(industryBenchmark);
                    setCompanyBenchmark(companyBenchmark);
                    setRanking(ranking);



                    const voteResults = Object.values(current.votes).map(function (vote) {
                        const date = moment(vote.date);
                        const dateString = date.format('DDMMYYYY');
                        return {
                            from: dateString,
                            to: dateString,
                            result: vote.indexRating,
                            companyBenchmark: companyBenchmark,
                            industryBenchmark: industryBenchmark,
                            globalBenchmark: globalBenchmark,
                            ranking: ranking
                        };
                    });

                    setResults(voteResults);

                }



            }


            setLoading(false);
        });
    };


    useEffect(() => {
        if (showDrawer && to) {
            setFrom(Dates.firstDayOfMonth(to));
            loadResults();
        }
    }, [showDrawer, to, filters]);


    const exportToExcel = (from?:Date, to?:Date) => {


        setShowReportModal(false);
        if (from && to) {
            const params = {
                ...prepareParams(),
                from: from,
                to: to
            }

            setExportingExcelReport(true);

            Reports.hi(params, function () {

                setExportingExcelReport(false);
                toaster.pop('success', null, $translate.instant('HI_EXPORT_SENT'));
                Analytics.trackEvent('hi_details', 'excel_report', 'success');

            }, function errorCallback(response) {
                setExportingExcelReport(false);
                ErrorSvrc.showErrorModal(response);
            });
        }

    }

    return (
        <EngagementDetailDrawer
            open={showDrawer}
            onClose={onClose}
            title={
                <Stack gap={1} width={'100%'} >

                    <Box display="flex" flexDirection="row" alignItems="center">
                        <Box flex="1">
                            <Stack direction="row" gap={0}>
                                <Typography variant="h1" noWrap>
                                    {$translate.instant('ENGAGEMENT_HI_SECTION_TITLE')}
                                </Typography>
                            </Stack>

                            <Typography variant="body3" color="text.secondary">
                                {$translate.instant('ENGAGEMENT_HI_SECTION_DESCRIPTION')}
                            </Typography>
                        </Box>

                        <Box>

                            <Stack direction="row" spacing={2}>

                                <EngagementDateBrowser
                                    to={to}
                                    dateGrouping={DateGrouping.MONTH}
                                    showDateGrouping={false}
                                    onDateChange={setTo}
                                    showYears={true}
                                    showQuarters={true}
                                    showMonths={false}
                                />

                                <Button
                                    variant="contained"
                                    disabled={exportingExcelReport}
                                    startIcon={<FileDownloadRoundedIcon />}
                                    onClick={() => setShowReportModal(true)}
                                >
                                </Button>

                            </Stack>

                        </Box>

                    </Box>

                    <HfFilterMenu
                        segmentFiltersConfig={segmentFiltersConfig}
                        hierarchyFiltersConfig={hierarchyFiltersConfig}
                        groupFiltersConfig={groupFiltersConfig}
                        selectedFilters={filters}
                        updateFilters={setFilters}
                    />
                </Stack>

            }>
            <Stack gap={2}>
                <HIReportModal to={to} show={showReportModal} onClose={exportToExcel}/>
                <WhiteBgPaper>

                    <Stack gap={1}>
                        <Stack gap={1}>
                            <Stack direction="row" alignItems="center" justifyContent="flex-start" gap={1}>
                                <Typography variant="h1">
                                    {$translate.instant('HI_DATA', {
                                        month: Dates.formatDateFromGrouping(to, DateGrouping.MONTH),
                                    })}
                                </Typography>

                            </Stack>

                            <QualityPopoverText quality={quality}/>
                        </Stack>

                        {!hasEnoughActiveEmployees && (
                            <EmptyState
                                small={true}
                                image="images/anonymous.png"
                                message={$translate.instant('NOT_ENOUGH_EMPLOYEES', {})}
                                submessage={$translate.instant('NOT_ENOUGH_EMPLOYEES_SUBMESSAGE', {})}
                            />
                        )}

                        {hasEnoughActiveEmployees && (
                            <Grid container spacing={2}>

                                <Grid item xs={12} md={4}>

                                    <HiDetailOverview
                                        value={value}
                                        variation={variation}
                                        measureDate={measureDate}
                                        quality={quality}
                                        valuation={valuation}
                                        loading={loading}
                                        hasEnoughActiveEmployees={hasEnoughActiveEmployees}
                                    />
                                </Grid>


                                <Grid item xs={12} md={6} sm={8}>

                                    <EngagementDetailEvolutionChart
                                        loading={loading}
                                        data={results}
                                        hasEnoughActiveEmployees={hasEnoughActiveEmployees}
                                        showCompanyBenchmark={filtered}
                                        dateGrouping={DateGrouping.DAY}
                                        scoreType={'HI'}
                                        maxValue={100}
                                        minValue={0}/>
                                </Grid>

                                <Grid item xs={12} md={2} sm={12}>

                                    <EngagementRankingBox
                                        loading={loading}
                                        ranking={ranking}
                                        type={'HI'}/>


                                    <Box mt={2} textAlign={'center'}>
                                        <BenchmarkBox
                                            loading={loading}
                                            value={value}
                                            companyBenchmark={companyBenchmark}
                                            globalBenchmark={globalBenchmark}
                                            industryBenchmark={industryBenchmark}
                                            filtered={filtered}/>
                                    </Box>

                                </Grid>
                            </Grid>
                        )}



                    </Stack>
                </WhiteBgPaper>



                <Grid container spacing={2}>
                    <Grid item xs={12} md={9}>

                        <Stack direction="column" spacing={2}>

                            <WhiteBgPaper>

                                <Stack direction="column" spacing={2}>

                                    <Stack direction="column" spacing={1}>
                                        <Stack direction="row">
                                            <Typography variant="h4">
                                                {$translate.instant('HI_CLASSIFICATION')}
                                            </Typography>

                                            <HFInfoIconWithTooltip
                                                tooltipContent={$translate.instant('HI_CLASSIFICATION_DESCRIPTION')}
                                                icon={'INFO'}
                                                color={'primary'}
                                            />
                                        </Stack>

                                    </Stack>


                                    <HIFunnelBox
                                        to={to}
                                        filters={filters}/>
                                </Stack>


                            </WhiteBgPaper>


                            <WhiteBgPaper>

                                <Stack direction="column" spacing={2}>

                                    <Box display={'flex'}
                                        width={'100%'}
                                        alignItems={'center'}
                                        justifyContent={'center'}>
                                        <ButtonGroup variant="outlined" aria-label="Basic button group">
                                            <Button
                                                variant={summaryView === 1 ? 'contained' : 'outlined'}
                                                onClick={() => setSummaryView(1)}
                                            >
                                                {$translate.instant('ENPS_INDIVIDUALS_MAP')}
                                            </Button>
                                            <Button
                                                variant={summaryView === 2 ? 'contained' : 'outlined'}
                                                onClick={() => setSummaryView(2)}
                                            >
                                                {$translate.instant('ENPS_POSITIONING')}
                                            </Button>

                                            <Button
                                                variant={summaryView === 3 ? 'contained' : 'outlined'}
                                                onClick={() => setSummaryView(3)}
                                            >
                                                {$translate.instant('ENPS_SCORES_IMPACT')}
                                            </Button>
                                        </ButtonGroup>
                                    </Box>

                                    {summaryView === 1 && (
                                        <EngagementHiIndividualHeatmapBox
                                            to={to}
                                            filters={filters}/>
                                    )}

                                    {summaryView === 2 && (

                                        <HiPositioningBox
                                            to={to}
                                            navigateToDetail={() => {}}/>
                                    )}

                                    {summaryView === 3 && (
                                        <ScoresImpactChart
                                            from={from}
                                            to={to}
                                            filters={filters}
                                            dateGrouping={DateGrouping.MONTH}
                                            type={'ENPS'}/>
                                    )}


                                </Stack>



                            </WhiteBgPaper>

                        </Stack>

                    </Grid>

                    <Grid item xs={12} md={3}>
                        <WhiteBgPaper sx={{height: '100%'}}>
                            <EngagementScoresFeedbackBox
                                hasEnoughActiveEmployees={hasEnoughActiveEmployees}
                                mode={'HI'}
                                dateGrouping={DateGrouping.MONTH}
                                to={to}
                                filters={filters}/>
                        </WhiteBgPaper>
                    </Grid>
                </Grid>
            </Stack>
        </EngagementDetailDrawer>
    );

};


export default HIDetailDrawer;
