import {Person} from '../../../types';
import React, {useEffect} from 'react';
import {getService} from 'reactInAngular';
import Dates from '../../../../../../../utilities/date.utilities';
import {APISettings} from '../../../../../../../shared/services/migrated/axiosWrapper';
import {Box, FormControl, InputLabel, MenuItem, Select, Typography} from '@mui/material';
import moment from 'moment/moment';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';


interface UserSegmentsProps {
    person: Person;
    onRowChange: (updatedPerson: Person) => void;
    onValidationChange: (isValid: boolean) => void;
}

const UserSegments = ({person, onRowChange, onValidationChange}: UserSegmentsProps) => {

    const [characteristics, setCharacteristics] = React.useState([]);

    const Characteristics = getService('Characteristics');
    const ErrorSvrc = getService('ErrorSvrc');
    const $translate = getService('$translate');


    useEffect(() => {

        Characteristics.getAll(function (err, data) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
                return;
            } else {
                setCharacteristics(data);
            }
        });
    }, []);


    const handleListChange = (event, characteristic) => {

        const currentValue = person?.characteristics?.find((userCharacteristic) => {
            return userCharacteristic.characteristicId === characteristic.id;
        });

        const updatedUserCharacteristics = person.characteristics ? [...person.characteristics] : [];


        if (currentValue) {
            console.log('currentValue: '+  currentValue.value + ' targetValue: ' + event.target.value);
            currentValue.value = event.target.value;
        } else {

            updatedUserCharacteristics.push({
                characteristicId: characteristic.id,
                value: event.target.value,
                questionType: 'LIST'
            });
        }

        onRowChange({...person, characteristics: updatedUserCharacteristics});


    }

    const handleDateChange = (newValue, characteristic) => {

        if (newValue?.isValid() === false) {
            onValidationChange(false);
        } else {
            const dateValue = newValue ? Dates.toAPIFormat(newValue, APISettings.apiDateFormat) : null;

            const updatedUserCharacteristics = person?.characteristics ? [...person.characteristics] : [];

            const currentValue = person?.characteristics?.find((userCharacteristic) => {
                return userCharacteristic.characteristicId === characteristic.id;
            });


            if (currentValue) {
                currentValue.date = dateValue;
            } else {
                updatedUserCharacteristics.push({
                    characteristicId: characteristic.id,
                    date: dateValue,
                    questionType: 'DATE'
                });
            }
            onRowChange({...person, characteristics: updatedUserCharacteristics});
            onValidationChange(true);

        }


    }

    const renderListCharacteristic = (characteristic) => {

        const currentValue = person.characteristics?.find((userCharacteristic) => {
            return userCharacteristic.characteristicId === characteristic.id;
        });


        return (
            <Select
                key={characteristic.id}
                labelId={`${characteristic.id}-label`}
                id={characteristic.id}
                variant="standard"
                value={currentValue?.value}
                onChange={(event) => handleListChange(event, characteristic)}
            >
                {
                    characteristic?.values.map((option: any) => {
                        return (
                            <MenuItem key={option.id} value={option.id}>{option.value.defaultValue}</MenuItem>
                        );
                    })
                }
            </Select>
        );
    };

    const renderDateCharacteristic = (characteristic) => {
        const currentValue = person.characteristics?.find((userCharacteristic) => {
            return userCharacteristic.characteristicId === characteristic.id;
        });

        let dateValue = null;
        if (currentValue) {
            dateValue = moment(Dates.fromAPIFormat(currentValue.date, APISettings.apiDateFormat));
        }

        return (
            <DatePicker
                key={characteristic.id}
                label={'\n'}
                value={dateValue}
                slotProps={{ textField: { variant: 'standard', } }}
                onChange={(newValue) => handleDateChange(newValue, characteristic)}
            />
        );

    }


    return (
        <Box sx={{display: 'flex', flexDirection: 'column'}}>

            <Box  sx={theme => ({width: '80%', minWidth: '300px'})}>
                {characteristics.map(characteristic => (
                    <FormControl key={characteristic.id}
                        onClick={(event) => event.stopPropagation()}
                        size="small"
                        sx={theme => ({
                            marginBottom: theme.spacing(2),
                        })}
                        fullWidth>
                        <InputLabel id={`${characteristic.id}-label`}>{characteristic.description}</InputLabel>

                        {characteristic?.questionType === 'LIST' && (
                            renderListCharacteristic(characteristic)
                        )}
                        {characteristic?.questionType === 'DATE' && (
                            renderDateCharacteristic(characteristic)
                        )}
                    </FormControl>
                ))
                }
            </Box>
        </Box>

    )
}

export default UserSegments;
