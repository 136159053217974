import React, { useState, useEffect } from 'react';

import {Box, Grid, Stack, Typography} from '@mui/material';
import {getService} from 'reactInAngular';
import Numbers from '../../../../../../utilities/number.utilities';
import Valuation from '../../../../../../utilities/valuation.utils';
import Style from '../../../../../../utilities/style.utils';
import EngagementCellWrapper from './EngagementCellWrapper';
import EngagementCellDataPopover from './EngagementCellDataPopover';
import VariationArrow from '../../../../../engagement_beta/components/details/components/VariationArrow';
import ArrowIndicator from '../../../../../../shared/components/_migrated/arrow-indicator-directive/arrow-indicator';


interface ENPSCellProps {
    cellData?: {
        result: number | null;
        quality?: 'NORMAL' | 'GOOD' | string;
        variation?: number;
        valuation?: any; // Replace `any` with the correct type for valuation
    };
    loading: boolean;
    segment: object; // Replace `object` with the exact type for segment
    column: object; // Replace `object` with the exact type for column
    selectedInsight?: object; // Replace `object` with the exact type for selectedInsight
    onCellClick: (segment: object, column: object, selectedInsight?: object) => void; // Update types if needed
}

const ENPSCell: React.FC<ENPSCellProps> = ({
    cellData,
    loading,
    segment,
    column,
    selectedInsight,
    onCellClick,
}) => {
    const [value, setValue] = useState<number | null>(null);
    const [hasData, setHasData] = useState(true);
    const [color, setColor] = useState('');

    const $translate = getService('$translate');

    useEffect(() => {
        if (cellData) {
            const isRepresentative =
                cellData.quality === 'NORMAL' || cellData.quality === 'GOOD';

            setValue(Numbers.roundNumber(cellData.result, 0));
            let calculatedColor = Style.noData;


            if (cellData.result === null || cellData.result === undefined) {
                setHasData(false);
                calculatedColor = Style.noData;
                if (!isRepresentative) {
                    calculatedColor = Style.neutralNotRelevant;
                }
            } else {
                setHasData(true);
                calculatedColor = Valuation.valuationColor(cellData.valuation, true);
                if (!isRepresentative) {
                    calculatedColor = Valuation.valuationColorWithLowQuality(
                        cellData.valuation,
                        true
                    );
                }
            }
            setColor(calculatedColor);
        }
    }, [cellData]);

    const handleOnCellClick = () => {
        if (onCellClick) {
            onCellClick(segment, column, selectedInsight);
        }
    };

    return (

        <EngagementCellWrapper
            color={color}
            loading={loading}
        >
            <Grid container={true}>
                <Grid item={true}
                    xs={4}>
                    <Box display={'hidden'}></Box>
                </Grid>

                <Grid item={true} xs={4} textAlign={'center'}>
                    <Typography variant={'body3'}>
                        {hasData ? (
                            <>
                                <Stack direction={'row'} role="button" onClick={handleOnCellClick}>
                                    {value}
                                    <ArrowIndicator label={''} value={cellData?.variation} />
                                </Stack>
                                <VariationArrow variation={cellData?.valuation?.variation} />
                            </>


                        ) : (
                            <Box>{$translate.instant('NO_DATA')}</Box>
                        )}
                    </Typography>
                </Grid>

                <Grid item={true} xs={4}>
                    <EngagementCellDataPopover
                        hasData={hasData}
                        quality={cellData?.quality}
                        variation={cellData?.variation}
                        value={value}
                        companyBenchmark={cellData?.valuation?.companyBenchmark}/>
                </Grid>
            </Grid>

        </EngagementCellWrapper>

    );
};

export default ENPSCell;
