
import React, { useState, useEffect } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Style from '../../../../../utilities/style.utils';
import { getENPSGrouping } from '../../../../../shared/services/migrated/enps.service';
import EngagementCharts from '../../../engagement.charts';
import { angularize, getService } from 'reactInAngular';
import HappyLoading from '../../../../../shared/components/_migrated/loader/loading.directive';
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup, Grid,
    InputLabel,
    Link,
    MenuItem,
    Select,
    Stack, Tooltip, Typography
} from '@mui/material';
import {
    CharacteristicName,
    GroupName,
    HierarchyName
} from '../../../../../shared/new-components/hf-segmentation-name/hfSegmentationName';
import async from 'async';
import { getHierarchyName } from '../../../../../shared/services/migrated/hierarchy.service';
import { dateToQueryParam } from '../../../../../utilities/navigation_state.utilities';
import EmptyState from '../../../../../shared/components/_migrated/empty_state/empty_state';


interface EnpsPositioningBoxProps {
    to: Date;
    mode: string;
    noData: boolean;
    navigateToDetail: (route: string, params: Record<string, any>) => void;
}

interface DataItem {
    hierarchyId?: string;
    groupId?: string;
    characteristic?: {
        characteristicId: string;
        value: string;
    };
    color?: string;
    selected?: boolean;
    name?: string;
}

const EnpsPositioningBox: React.FC<EnpsPositioningBoxProps> = ({ to, mode, navigateToDetail, noData }) => {
    const [positioningBy, setPositioningBy] = useState<string>('HIERARCHIES');
    const [loading, setLoading] = useState<boolean>(false);
    const [noTypes, setNoTypes] = useState<boolean>(false);
    const [data, setData] = useState<DataItem[]>([]);
    const [bubbleChart, setBubbleChart] = useState<any>(null);
    const [allSelected, setAllSelected] = useState<boolean>(false);

    const positioningByOptions = [
        { id: 'HIERARCHIES', label: 'Areas', idField: 'hierarchyId' },
        { id: 'GROUPS', label: 'Groups', idField: 'groupId' },
        { id: 'CHARACTERISTICS', label: 'Segments' }
    ];

    const ErrorSvrc = getService('ErrorSvrc');
    const $translate = getService('$translate');
    const Groups = getService('Groups');
    const Characteristics = getService('Characteristics');
    const Languages = getService('Languages');

    const computeColor = (count: number): string => {
        const remainder = count % Style.colors.length;
        return Style.colors[remainder === 0 ? Style.colors.length - 1 : remainder - 1];
    };

    const selectAll = () => {
        const newSelection = !allSelected;
        const newData = data.map(item => ({ ...item, selected: newSelection }));
        setData(newData);
        setAllSelected(newSelection);
    };

    const handleSelect = (item: DataItem) => {
        const newData = data.map(dataItem => {
            const match =
                (item.hierarchyId && item.hierarchyId === dataItem.hierarchyId) ||
                (item.groupId && item.groupId === dataItem.groupId) ||
                (item.characteristic && item.characteristic.characteristicId === dataItem.characteristic.characteristicId && item.characteristic.value === dataItem.characteristic.value);

            if (match) {
                return { ...dataItem, selected: !dataItem.selected };
            }

            return dataItem;
        });
        setData(newData);
    };

    const gotoItemDetail = (item: DataItem) => {
        const linkParams: Record<string, any> = { to: dateToQueryParam(to) };

        if (item.hierarchyId) {
            linkParams.hierarchyId = item.hierarchyId;
        } else if (item.groupId) {
            linkParams.groupId = item.groupId;
        } else if (item.characteristic) {
            linkParams.filters = item.characteristic.characteristicId + ':' + item.characteristic.value;
        }
        navigateToDetail('engagement.enpsDetail', linkParams);
    };

    const renderChart = () => {
        if (!data.length) {
            return;
        }
        setAllSelected(data.every(item => item.selected));
        const chart = EngagementCharts.scorePositioningChart(data, -100, 100, 'eNPS', 'ENPS');
        setBubbleChart(chart);
    };

    const getSegmentationName = (segment: DataItem, callback: (err: any, name: string | null) => void) => {
        if (positioningBy === 'HIERARCHIES') {
            getHierarchyName(segment.hierarchyId!, (err, name) => {
                if (err) {
                    callback(err, null);
                } else {
                    callback(null, name);
                }
            });
        } else if (positioningBy === 'GROUPS') {
            callback(null, Groups.getGroupName(segment.groupId!));
        } else if (positioningBy === 'CHARACTERISTICS') {
            let name = '';
            const characteristic = Characteristics.getCharacteristicById(segment.characteristic!.characteristicId);
            if (characteristic) {
                name = `${characteristic.description}-${Languages.getTranslationFromUserLanguage(
                    Characteristics.getCharacteristicValueName(segment.characteristic!.characteristicId, segment.characteristic!.value)
                )}`;
            }
            callback(null, name);
        }
    };

    const loadResults = () => {
        setLoading(true);
        getENPSGrouping(positioningBy, { from: to, to: to }, (err, response) => {
            setLoading(false);
            if (err) {
                ErrorSvrc.showErrorModal(err);
                return;
            } else {
                const hasData = response && response.results;
                setNoTypes(response.noTypes);
                if (hasData && response.results.length) {
                    let count = 0;
                    const stream: any[] = [];
                    response.results
                        .filter((item: DataItem) => (item.hierarchyId || item.groupId || item.characteristic))
                        .forEach((item: DataItem) => {
                            stream.push((next: (err: any, result: DataItem | null) => void) => {
                                const finalItem = {
                                    ...item,
                                    color: computeColor(++count),
                                    selected: true
                                };
                                getSegmentationName(item, (err, name) => {
                                    if (err) {
                                        next(err, null);
                                    } else {
                                        finalItem.name = name;
                                        next(null, finalItem);
                                    }
                                });
                            });
                        });

                    async.parallel(stream, (err, results) => {
                        if (err) {
                            ErrorSvrc.showErrorModal(err);
                            return;
                        } else {
                            setData(results);
                        }
                    });
                }
            }
        });
    };

    useEffect(() => {
        renderChart();
    }, [data, allSelected]);

    useEffect(() => {
        loadResults();
    }, [to, mode, positioningBy]);

    const renderItemForType = (item: DataItem) => {
        if (item.hierarchyId) {
            return (<HierarchyName id={item.hierarchyId} />);
        } else if (item.groupId) {
            return (<GroupName id={item.groupId} />);
        } else if (item.characteristic) {
            return (<CharacteristicName id={item.characteristic.characteristicId} valueId={item.characteristic.value} />);
        } else {
            return undefined;
        }
    };


    if (!loading && noData) {

        return (
            <EmptyState
                image="images/analyze_no_data.png"
                small
                message={$translate.instant('SCORE_NO_DATA', {})}
                submessage={$translate.instant('SCORE_NO_DATA_DESCRIPTION', {})}
            />

        )

    } else {
        return (
            <HappyLoading loading={loading}>

                <Stack gap={1}>


                    <Stack alignItems={'center'} justifyContent={'center'} direction={'row'} gap={1} >
                        <Box flexGrow={1}>
                            <Typography variant="h2">
                                {$translate.instant('ENPS_POSITIONING')}
                            </Typography>
                        </Box>
                        <Box>
                            <FormControl fullWidth>
                                <InputLabel id="positioning-by-label">{$translate.instant('SHOW_BY')}</InputLabel>
                                <Select
                                    labelId="positioning-by-label"
                                    value={positioningBy}
                                    label="Show By"
                                    onChange={event => {
                                        setPositioningBy(event.target.value);
                                    }}
                                >
                                    {positioningByOptions.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>{option.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </Stack>

                    {!noTypes && (

                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <Stack gap={1} alignItems={'flex-start'} justifyContent={'center'}>
                                    <Typography variant="body3">
                                        {$translate.instant('ENGAGEMENT_POSITIONING_FILTER')}
                                    </Typography>


                                    <Typography variant="body3">
                                        <Link onClick={selectAll}>
                                            {allSelected ? $translate.instant('UNSELECT_ALL') : $translate.instant('SELECT_ALL')}
                                        </Link>
                                    </Typography>

                                    <Box>
                                        <FormGroup>
                                            {data.map(item => (
                                                <FormControlLabel
                                                    key={item.hierarchyId || item.groupId || item.characteristic?.characteristicId}
                                                    control={<Checkbox checked={item.selected} onClick={() => {
                                                        handleSelect(item);
                                                    }}/>}
                                                    label={renderItemForType(item)}
                                                />
                                            ))}
                                        </FormGroup>
                                    </Box>
                                </Stack>


                            </Grid>
                            <Grid item xs={9}>
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={bubbleChart}></HighchartsReact>
                            </Grid>
                        </Grid>


                    )}
                    {noTypes && (
                        <EmptyState
                            image="images/analyze_no_data.png"
                            small
                            message={$translate.instant('ACTIVATION_PACE_NO_SEGMENTS', {})}
                            submessage={$translate.instant('ACTIVATION_PACE_NO_SEGMENTS_SUBMESSAGE', {})}
                        />

                    )}


                </Stack>


            </HappyLoading>
        );
    }



};

export default EnpsPositioningBox;
