import React, {useCallback, useEffect, useState} from 'react';
import moment from 'moment';
import {FormControl, IconButton, MenuItem, Select, Stack} from '@mui/material';
import {ChevronLeft, ChevronRight} from '@mui/icons-material';
import {getService} from 'reactInAngular';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {DateView} from '@mui/x-date-pickers';
import {DateGrouping} from '../../../../shared/types/common';

interface EngagementDateBrowserProps {
    to: Date;
    dateGrouping: DateGrouping;
    showMonths?: boolean;
    showQuarters?: boolean;
    showYears?: boolean;
    showWeeks?: boolean;
    showDays?: boolean;
    showDateGrouping?: boolean;
    enps?: boolean;
    onDateChange: (date: Date) => void;
    onDateGroupingChange?: (grouping: DateGrouping) => void;
}

const EngagementDateBrowser: React.FC<EngagementDateBrowserProps> = ({
    to,
    dateGrouping,
    showMonths = true,
    showWeeks = false,
    showDays = false,
    showQuarters = false,
    showYears = false,
    showDateGrouping = true,
    enps = false,
    onDateChange,
    onDateGroupingChange
}) => {
    const [currentTo, setCurrentTo] = useState<moment.Moment | undefined>();
    const [currentGrouping, setCurrentGrouping] = useState<DateGrouping>(dateGrouping || DateGrouping.MONTH);
    const [dateGroupingTypes, setDateGroupingTypes] = useState<{ id: string; label: string }[]>([]);
    const [dateFormat, setDateFormat] = useState<string>('');
    const [minView, setMinView] = useState<moment.Moment>(undefined);
    const [view, setView] = useState<DateView[]>();

    const $translate = getService('$translate');
    const CompanySvrc = getService('CompanySvrc');
    const Settings = getService('Settings');

    useEffect(() => {
        CompanySvrc.getUserCompany((err: Error | null, company: { createdOn: string }) => {
            if (!err) {
                setMinView(moment(new Date(company.createdOn)));
            }
        });
    }, []);



    useEffect(() => {
        if (enps) {
            const toDate = moment(to);

            if (!toDate.isValid()) {
                return;
            }

            const month = toDate.month();
            let adjustedMonth: number;
            if (month >= 11 || month <= 1) {adjustedMonth = 11;}
            else if (month >= 2 && month <= 4) {adjustedMonth = 2;}
            else if (month >= 5 && month <= 7) {adjustedMonth = 5;}
            else if (month >= 8 && month <= 10) {adjustedMonth = 8;}

            toDate.month(adjustedMonth);
            setCurrentTo(toDate);
        } else {
            setCurrentTo(moment(to));
        }
        updateSelectorSettings();
    }, [to, enps]);


    useEffect(() => {
        const types: { id: string; label: string }[] = [];
        if (showDays) {
            types.push({ id: 'DAY', label: $translate.instant('DATE_GROUPING_DAY') });
        }
        if (showWeeks) {
            types.push({ id: 'WEEK', label: $translate.instant('DATE_GROUPING_WEEK') });
        }
        if (showMonths) {
            types.push({ id: 'MONTH', label: $translate.instant('DATE_GROUPING_MONTH') });
        }
        if (showQuarters) {
            types.push({ id: 'QUARTER', label: $translate.instant('DATE_GROUPING_QUARTER') });
        }
        if (showYears) {
            types.push({ id: 'YEAR', label: $translate.instant('DATE_GROUPING_YEAR') });
        }
        setDateGroupingTypes(types);
    }, [showMonths, showQuarters, showYears]);



    const updateSelectorSettings = useCallback(() => {
        let format: string;
        let minViewSetting: string;

        switch (currentGrouping) {
            case 'WEEK':
            case 'DAY':
                format = Settings.defaultDayDateFormat;
                setView(['day']);
                break;
            case 'MONTH':
                format = Settings.defaultMonthDateFormat;
                setView(['month','year']);
                break;
            case 'QUARTER':
                format = Settings.defaultQuarterDateFormat;
                setView(['month','year']);
                break;
            case 'YEAR':
                format = Settings.defaultYearDateFormat;
                setView(['year']);
                break;
            default:
                format = 'YYYY-MM-DD';
                setView(['month']);
        }

        setDateFormat(format);
    }, [currentGrouping]);




    const handlePrevPeriod = () => {
        const prevDate = moment(currentTo).subtract(1, currentGrouping).toDate();
        onDateChange(prevDate);
    };

    const handleNextPeriod = () => {
        const nextDate = moment(currentTo).add(1, currentGrouping).toDate();
        if (nextDate.getTime() <= Date.now()) {
            onDateChange(nextDate);
        }
    };

    const handleDateChange = (newDate: moment.Moment) => {
        onDateChange(newDate.toDate());
    };

    return (
        <Stack gap={1} direction={'row'} display={'flex'} alignItems={'center'}>
            <IconButton onClick={handlePrevPeriod}>
                <ChevronLeft />
            </IconButton>

            <DatePicker minDate={minView}
                value={currentTo}
                onAccept={handleDateChange}
                disableFuture={true}
                views={view}
                slotProps={{

                    field: {
                        variant: 'outlined',
                        readOnly: true
                    }
                }}
            />


            {showDateGrouping && (
                <FormControl  variant="outlined">
                    <Select

                        id="date-grouping"
                        value={currentGrouping}
                        onChange={(e) => {
                            const value = e.target.value as string;
                            setCurrentGrouping(value);
                            onDateGroupingChange(value);
                        }}
                    >
                        {dateGroupingTypes.map((type) => (
                            <MenuItem key={type.id} value={type.id}>
                                {type.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
            <IconButton onClick={handleNextPeriod}>
                <ChevronRight />
            </IconButton>
        </Stack>
    );
};


export default EngagementDateBrowser;
