import React, {useState, useRef, useMemo, useEffect} from 'react';
import PropTypes from 'prop-types';
import { angularize, getService } from 'reactInAngular';
import 'trix/dist/trix.css';
import angular from 'angular';
import JoditEditor, {Jodit} from 'jodit-react';
import {IControlType} from 'jodit/esm/types/toolbar';
import {AnchorPosition, HfContentAIDialog} from './components/hfContentAIDialog';


type HFContentEditorProps = {
  content: string;
  onContentUpdated?: (content: string) => void;
  onMediaAdded?: (media: any) => void;
  onAttachmentAdded?: (attachment: File[]) => void;
};

const HFContentEditor: React.FC<HFContentEditorProps> = ({
    content,
    onContentUpdated,
    onMediaAdded,
    onAttachmentAdded,
}) => {

    const $translate = getService('$translate');
    const Settings = getService('Settings');
    const AuthSrvc = getService('AuthSrvc');
    const toaster = getService('toaster');

    const editorRef = useRef(null);


    const joditRef = useRef(null);
    const [anchorPosition, setAnchorPosition] = useState<AnchorPosition>(null); // Track position of the contextual menu
    const [highlightedRange, setHighlightedRange] = useState<Range | null>(null); // Store the highlighted range
    const [selectedHtml, setSelectedHtml] = useState<string | undefined>();
    const [cursorBookmark, setCursorBookmark] = useState(null);



    const editorConfig = useMemo(() => {

        // If we have a generateByAIHandler, add the AI button to the toolbar


        const aiButton: IControlType = {
            name: 'ai-assistant-custom',
            iconURL: '/images/chat_bot.svg',
            exec: async (editor) => {
                handleOpenContextualMenu(editor);
            },
            tooltip: $translate.instant('AI_CONTENT_GENERATION'),
        }

        const  buttons =  [ aiButton, 'speechRecognize', '|', 'paragraph','bold','italic','|','underline','strikethrough','hr','|','ul','ol','|','outdent','indent','|','file','image','video','|','link','\n','undo','redo','source']




        return {
            readonly: false, // all options from https://xdsoft.net/jodit/docs/,
            toolbarButtonSize: 'small',
            buttons: buttons,
            toolbarAdaptive: false,
            hidePoweredByJodit: true,
            styleValues: {
                'color-text': 'red',
                colorBorder: 'black',
                'color-panel': 'blue'
            },
            pasteFromWord: {
                enable: true,
                convertUnitsToPixel: true,
                allowedStyleProps: [
                    'background',
                    'background-color',
                    'border',
                    'border-.*',
                    'color',
                    'font-family',
                    'font-size',
                    'font-style',
                    'font-weight',
                    'height',
                    'line-height',
                    'list-style-type',
                    'margin',
                    'margin-bottom',
                    'margin-left',
                    'margin-right',
                    'margin-top',
                    'padding',
                    'text-align',
                    'text-decoration',
                    'text-indent',
                    'vertical-align',
                    'width'
                ]
            },
            cleanHTML: {
                denyTags: {
                    script: true
                }
            },
            imageeditor: {
                closeAfterSave: true,
                crop: false,
                resize: true,
                width: 500
            },

            uploader: {
                url:  Settings.APIUrl + '/company/files',
                insertImageAsBase64URI: false,
                headers: {
                    SessionId: AuthSrvc.sessionId()
                },
                format: 'application/json, text/plain, */*',
                filesVariableName: t => 'file',
                getMessage: function (resp) {
                    return null;
                },
                defaultHandlerSuccess: function (data, resp) {
                    if (data && data.length) {
                        data.forEach((file) => {
                            if (file.fileType && /^(jpg|jpeg|png|gif)$/i.test(file.fileType)) {
                                // Insert image
                                const editor = this.j || this;
                                editor?.selection.insertImage(file.filePath);
                                onMediaAdded?.(file);
                            } else {
                                onAttachmentAdded?.([file]);
                            }
                        });

                        toaster.pop('success', null, $translate.instant('UPLOAD_FILE_SUCCESS_DESC'));
                    }
                },
                isSuccess: function (resp) {
                    // Only return true if the response is an array
                    return resp instanceof Array;
                },
                process: resp => {
                    // We don't need format the response in the JoditEditor format, since we are using a custom defaultHandlerSuccess
                    return resp;
                },
                error: e => {
                    const messageError = $translate.instant('UPLOAD_FILE_ERROR_DESC', { name: e });
                    toaster.pop('error', null, messageError);
                },
            },
            events: {
                afterInit: (instance) => {
                    joditRef.current = instance;
                },

            }

        }




    }, []);





    const highlightSelection = (range: Range) => {
        const editorInstance = joditRef.current; // Get the editor's container
        if (!editorInstance) {
            console.error('Editor instance not found');
            return;
        }
        editorInstance.history.snapshot.transaction(() => {


            editorInstance.s.commitStyle({
                element: 'span',
                attributes: {
                    className: 'temporary-highlight',
                    style: { backgroundColor: 'rgba(255, 229, 100, 0.5)' }
                }
            }); // Apply style to selected text

            editorInstance.synchronizeValues();
        });


    };

    const removeHighlight = () => {
        const editorInstance = joditRef.current; // Get the editor's container

        if (!editorInstance) {
            console.error('Editor instance not found');
            return;
        }

        editorInstance.history.snapshot.transaction(() => {
            // Get the editor's HTML content
            const editorHTML = editorInstance.getEditorValue();

            // Parse the content and remove highlight spans
            const tempContainer = document.createElement('div');
            tempContainer.innerHTML = editorHTML;

            // Select all elements with the highlight class
            const highlights = tempContainer.querySelectorAll('.temporary-highlight');

            highlights.forEach((highlight) => {
                const parent = highlight.parentNode;
                if (parent) {
                    // Replace the highlight span with its child nodes
                    while (highlight.firstChild) {
                        parent.insertBefore(highlight.firstChild, highlight);
                    }
                    parent.removeChild(highlight);
                }
            });

            // Update the editor's content
            editorInstance.setEditorValue(tempContainer.innerHTML);

            // Synchronize the editor with its underlying value
            editorInstance.synchronizeValues();

            console.log('All highlights removed and editor updated');
        });


    };


    const handleOpenContextualMenu = (joditInstance: Jodit) => {
        // Get the selection range
        // Get the selection range
        const selectionRange = joditInstance.selection.range;



        let top: number;
        let left: number;

        if (selectionRange && !selectionRange.collapsed) {
            const rect = selectionRange.getBoundingClientRect();

            // Highlight the selected range
            highlightSelection(selectionRange);

            setSelectedHtml(joditInstance.selection.html);


            // Ensure the contextual menu stays within the viewport
            const viewportHeight = window.innerHeight;
            const viewportWidth = window.innerWidth;

            top = rect.bottom + window.scrollY; // Default top position
            left = rect.left + window.scrollX; // Default left position

            // Adjust position if the menu would go outside the viewport
            if (rect.bottom > viewportHeight) {
                top = rect.y + window.scrollY; // Use rect.y for alternative position
            }
            if (rect.right > viewportWidth) {
                left = viewportWidth - 50; // Adjust to the edge of the viewport
            }


            // Store the current range
            setHighlightedRange(selectionRange);
            setCursorBookmark(null);
        } else {

            const bookmark = joditInstance.selection.save();

            // Save the cursor position
            setCursorBookmark(bookmark);


            // If there is no selection, position the menu at the start of the editing area
            const editorContentAreaRect = joditInstance.editor.getBoundingClientRect();
            top = editorContentAreaRect.top + window.scrollY;
            left = editorContentAreaRect.left + window.scrollX;
        }

        // Open the contextual menu near the cursor
        setAnchorPosition({
            top: top,
            left: left,
        });
    };

    const handleContentGenerated = (content: string) => {
        const editorInstance = joditRef.current;

        if (!editorInstance) {
            console.error('Editor instance not found');
            return;
        }

        // Get the current content of the editor
        const editorHTML = editorInstance.getEditorValue();

        // Search for the selectedHtml in the editor's content
        if (selectedHtml && editorHTML.includes(selectedHtml)) {
            // Replace the selectedHtml with the incoming content
            const updatedHTML = editorHTML.replace(selectedHtml, content);
            // Update the editor's content
            editorInstance.setEditorValue(updatedHTML);
        } else if (cursorBookmark) {

            editorInstance.selection.restore(cursorBookmark);
            editorInstance.selection.focus();
            editorInstance.selection.insertHTML(content);
        } else {
            editorInstance.selection.insertHTML(content);
        }

        // Close the contextual menu
        editorInstance.synchronizeValues();
        handleContextualMenuClose();

    };

    const handleContextualMenuClose = () => {
        removeHighlight();
        setAnchorPosition(null);
        setSelectedHtml(undefined);
    }




    const handleContentChanged = (newContent: string) => {
        console.log('Content changed:', newContent);
        onContentUpdated?.(newContent);
    }

    return (
        <>
            <JoditEditor
                ref={editorRef}
                value={content}
                config={editorConfig}
                tabIndex={1} // tabIndex of textarea
                onBlur={newContent => handleContentChanged(newContent)} // preferred to use only this option to update the content for performance reasons
            />
            <HfContentAIDialog
                position={anchorPosition}
                selectedContent={selectedHtml}
                allContent={joditRef.current?.getEditorValue()}
                onClose={handleContextualMenuClose}
                onAIContentGenerated={handleContentGenerated}
                outputFormat={'html'}/>
        </>

    );
};

HFContentEditor.propTypes = {
    content: PropTypes.string.isRequired,
    onContentUpdated: PropTypes.func,
    onMediaAdded: PropTypes.func,
    onAttachmentAdded: PropTypes.func,
};

angularize(HFContentEditor, 'hfContentEditor', angular.module('happyForceApp'), {
    content: '<',
    onContentUpdated: '<',
    onMediaAdded: '<',
    onAttachmentAdded: '<',
    generateByAIHandler: '<',
});

export default HFContentEditor;
