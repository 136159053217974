import React, { useEffect, useState } from 'react';
import { getService } from 'reactInAngular';
import Numbers from '../../../../utilities/number.utilities';
import {Box, Stack, Tooltip, Typography} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

interface VotesChartProps {
    values: {
        value: number;
        color: string;
    }[];
    totalVotes: number;
}

const VotesChart: React.FC<VotesChartProps> = ({ values, totalVotes }) => {
    const [gridColumns, setGridColumns] = useState<string | null>(null);
    const [columValues, setColumValues] = useState<{ color: string; percent: number; label: string }[] | null>(null);

    const $translate = getService('$translate');

    useEffect(() => {
        const newValues = values?.map((value) => {
            const percent = Numbers.roundNumber((value.value / totalVotes) * 100, 0);
            return {
                color: value.color,
                percent: percent,
                label: $translate.instant('VOTES_CHART_VOTES', { percent: percent, votes: value.value })
            };
        });

        setColumValues(newValues);
        setGridColumns(newValues?.map((entry) => `${entry.percent}%`).join(' '));
    }, [values, totalVotes]);

    return (
        <Stack gap={0.5} direction={'row'} width={'100'} display={'flex'}>
            {columValues?.filter((value) => { return value.percent > 0}).map((value, index) => (
                <Box key={index}
                    padding={0.5}
                    color={'white'}
                    textAlign={'center'}
                    sx={{
                        backgroundColor: value.color,
                        width: value.percent + '%',
                        overflow: 'hidden',
                    }}>
                    <Tooltip title={value.label}>
                        <Typography variant={'body3'}>{value.label}</Typography>
                    </Tooltip>

                </Box>
            ))}
        </Stack>
    );
};

export default VotesChart;
