import React, { useState, useEffect } from 'react';
import { getService } from 'reactInAngular';
import Style from '../../../../../utilities/style.utils';
import Valuation from '../../../../../utilities/valuation.utils';
import EmptyState from '../../../../../shared/components/_migrated/empty_state/empty_state';
import ScaleChart from '../../../../../shared/components/_migrated/scale-chart/scale-chart.component';
import HFTag from '../../../../../shared/new-components/hf-tag/hf-tag';
import HappyLoading from '../../../../../shared/components/_migrated/loader/loading.directive';
import {Box, Stack, Typography} from '@mui/material';

interface EnpsDetailOverviewProps {
    loading: boolean;
    value: number;
    averageValue: number;
    variation: number;
    averageVariation: number;
    measureDate: string;
    quality: string;
    valuation: string;
    noData: boolean;
    hasEnoughActiveEmployees: boolean;
    mode: string;
}

const EnpsDetailOverview: React.FC<EnpsDetailOverviewProps> = ({ loading, value, averageValue, variation, averageVariation, measureDate, quality, valuation, noData, hasEnoughActiveEmployees, mode }) => {

    const [shouldDisplayData, setShouldDisplayData] = useState<boolean>(false);
    const [valutationStatus, setValutationStatus] = useState<string>('');
    const [color, setColor] = useState<string>('');
    const [variationText, setVariationText] = useState<string>('');

    const $translate = getService('$translate');

    const enpsSlices = [{
        color: Style.bad,
        range: [-100, -10],
        label: $translate.instant('ENPS_SLICE_FIRST')
    }, {
        color: Style.neutral,
        range: [-10, 20],
        label: $translate.instant('ENPS_SLICE_SECOND')
    }, {
        color: Style.warning,
        range: [20, 40],
        label: $translate.instant('ENPS_SLICE_THIRD')
    }, {
        color: Style.excellent,
        range: [40, 100],
        label: $translate.instant('ENPS_SLICE_FOURTH')
    }];

    const averageSlices = [{
        color: Style.bad,
        range: [0, 6],
        label: $translate.instant('ENPS_SLICE_FIRST')
    }, {
        color: Style.warning,
        range: [7, 8],
        label: $translate.instant('ENPS_SLICE_THIRD')
    }, {
        color: Style.excellent,
        range: [9, 10],
        label: $translate.instant('ENPS_SLICE_FOURTH')
    }];



    useEffect(() => {
        if (variation && averageVariation) {

            const variationComparison = mode === 'ENPS_MODE' ? variation : averageVariation;
            let key = 'SCORE_VARIATION_';
            if (variationComparison > 0) {
                key += 'UP';
            } else if (variationComparison < 0) {
                key += 'DOWN';
            } else {
                key += 'EQUAL';
            }
            setVariationText($translate.instant(key, { variation: Math.abs(variationComparison) }));
        }

    }, [averageVariation, variation]);

    useEffect(() => {
        if (value) {
            setShouldDisplayData(hasEnoughActiveEmployees && quality !== 'NO_DATA');
            if (mode === 'ENPS_MODE') {
                setColor(Valuation.enpsValuationColor(value, averageValue, valuation, false));
                setValutationStatus(Valuation.enpsTagType(value, averageValue, valuation, true));
            } else {
                setColor(Valuation.enpsValuationColor(value, averageValue, valuation, true));
                setValutationStatus(Valuation.enpsTagType(value, averageValue, valuation, false));
            }
        } else {
            setShouldDisplayData(false);
        }
    }, [value, averageValue, variation, averageVariation, measureDate, quality, valuation, noData, hasEnoughActiveEmployees, mode]);

    return (
        <HappyLoading loading={loading}>
            <Stack gap={2}
                direction={'row'}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}>
                <Box width={'100%'}>
                    {shouldDisplayData && (
                        <Stack gap={1} justifyContent={'flex-start'}>
                            <Box component={'span'}>
                                {mode === 'ENPS_MODE' && (
                                    <HFTag
                                        status={valutationStatus}
                                        text={$translate.instant('ENPS_AVERAGE_RESULT', { result: averageValue })}
                                    />
                                )}
                                {mode !== 'ENPS_MODE' && (
                                    <HFTag
                                        status={valutationStatus}
                                        text={$translate.instant('ENPS_STANDARD_RESULT', { result: value })}
                                    />
                                )}

                            </Box>


                            <Box component={'span'} style={{color: color}}>
                                <Typography component="span" variant="bigNumber">{mode === 'ENPS_MODE' ? value :averageValue}</Typography>
                                <Typography component="span" variant="subtitle1">pts</Typography>
                            </Box>



                            <ScaleChart slices={mode === 'ENPS_MODE' ? enpsSlices : averageSlices} value={mode === 'ENPS_MODE' ? value : averageValue} />
                            <Typography variant="body3" dangerouslySetInnerHTML={{__html: variationText}}></Typography>

                        </Stack>
                    )}

                    {quality === 'NO_DATA' && (
                        <Stack gap={1}>

                            <Box component={'span'} style={{color: color}}>
                                <Typography component="span"
                                    variant="bigNumber">{$translate.instant('NO_DATA')}</Typography>
                            </Box>
                            <Typography variant="body3">
                                {$translate.instant('SCORE_NO_DATA_SHORT_DESCRIPTION')}
                            </Typography>

                        </Stack>

                    )}

                    {!hasEnoughActiveEmployees && (
                        <EmptyState
                            image="images/anonymous.png"
                            message={$translate.instant('NOT_ENOUGH_EMPLOYEES', {})}
                            submessage={$translate.instant('NOT_ENOUGH_EMPLOYEES_SUBMESSAGE', {})}
                        />
                    )}


                </Box>


            </Stack>


        </HappyLoading>
    );
};

export default EnpsDetailOverview;
