import React, { useState, useEffect } from 'react';
import { angularize, getService } from 'reactInAngular';
import Dates from '../../../../../utilities/date.utilities';
import Numbers from '../../../../../utilities/number.utilities';
import Style from '../../../../../utilities/style.utils';
import HappyLoading from '../../../../../shared/components/_migrated/loader/loading.directive';
import { HfBannerReact } from '../../../../../shared/new-components/hf-banner/hfBanner';
import {Stack, Typography} from '@mui/material';
import moment from 'moment';
import { ClassificationFlowChart } from '../../../shared/classification_flow_chart/ClassificationFlowChart';
import VotesChart from '../../../shared/votes_chart/VotesChart';
import {toSegmentParams} from '../../../shared/utils';
import {FilterSelected} from '../../../../../shared/new-components/hf-filter-menu/types';
import EmptyState from '../../../../../shared/components/_migrated/empty_state/empty_state';

interface HIFunnelBoxProps {
    to: Date;
    filters: FilterSelected;
}

const HIFunnelBox: React.FC<HIFunnelBoxProps> = ({ to, filters }) => {
    const [hasEnoughActiveEmployees, setHasEnoughActiveEmployees] = useState<boolean>(true);
    const [flowValues, setFlowValues] = useState<any[] | null>(null);
    const [flowBanner, setFlowBanner] = useState<string>('');
    const [totalVotes, setTotalVotes] = useState<number>(0);
    const [distributionValues, setDistributionValues] = useState<any[] | null>(null);
    const [loadingClassification, setLoadingClassification] = useState<boolean>(false);
    const [loadingVotes, setLoadingVotes] = useState<boolean>(true);
    const [noData, setNoData] = useState<boolean>(false);

    const HIService = getService('HIService');
    const ErrorSvrc = getService('ErrorSvrc');
    const $translate = getService('$translate');

    const categories = [
        { category: 'ENCHANTED', color: Style.excellent, last: false },
        { category: 'HAPPY', color: Style.warning, last: false },
        { category: 'NEUTRAL', color: Style.neutral, last: false },
        { category: 'SAD', color: Style.faceBad, last: false },
        { category: 'DISENCHANTED', color: Style.faceSad, last: true }
    ];

    const buildArrowElement = (funnelItem: any) => {
        return {
            count: funnelItem.count,
            sourceLabel: $translate.instant('ATTITUDE_' + funnelItem.source),
            targetLabel: $translate.instant('ATTITUDE_' + funnelItem.target)
        };
    };

    const buildVotesLabel = (total: number, votes: number) => {
        const percent = Numbers.roundNumber((votes / total) * 100, 1) + '%';
        const keys = { percent: percent, votes: votes };
        return $translate.instant('HI_DETAIL_DISTRIBUTION_VOTES', keys);
    };

    const processCategory = (results: any, type: any) => {
        const result = {
            category: {
                type: 'category',
                label: $translate.instant('ATTITUDE_' + type.category),
                number: results.classification[type.category],
                color: type.color
            },
            arrow: null
        };

        let hiFlowCount = 0;
        const hiFlow: any[] = [];

        results.funnel.forEach((category: any) => {
            if (category.source === type.category || category.target === type.category) {
                hiFlowCount += category.count;
                if (!type.last && category.count !== 0) {
                    hiFlow.push(buildArrowElement(category));
                }
            }
        });

        if (!type.last) {
            result.arrow = {
                type: 'arrow',
                flow: hiFlowCount,
                flowItems: hiFlow
            };
        }

        return result;
    };

    const prepareParams = () => {
        const params = {
            from: Dates.firstDayOfMonth(to),
            to: Dates.lastDayOfMonth(to),
            filters: toSegmentParams(filters?.segmentIds as any[]),
            groupId: filters?.groupIds,
            hierarchyId: filters?.hierarchyIds
        };

        return params;
    }



    const loadResults = () => {
        setLoadingClassification(true);
        setLoadingVotes(true);
        setHasEnoughActiveEmployees(true);

        const params = prepareParams();

        HIService.stats.classification(params, (err: any, response: any) => {
            setLoadingClassification(false);
            if (response.quality === 'NO_DATA') {
                setNoData(true);
                setDistributionValues(null);
                setFlowValues(null);
                setFlowBanner('');
            } else {
                const results: any[] = [];
                categories.forEach((type) => {
                    const result = processCategory(response, type);
                    results.push(result.category);
                    if (result.arrow) {
                        results.push(result.arrow);
                    }
                });
                setFlowValues(results);
                setFlowBanner($translate.instant('FLOW_DESCRIPTION_' + response.valuation));
                setNoData(false);
            }
        });

        HIService.stats.get(params, (err: any, response: any) => {
            setLoadingVotes(false);
            if (response.results && response.results.length > 0) {
                const currentResult = response.results[0];
                setTotalVotes(currentResult.votesPeriod);
                const ratings = currentResult.ratings;
                const newDistributionValues = [
                    { value: ratings[4] || 0, color: Style.faceHappy },
                    { value: ratings[3] || 0, color: Style.faceGood },
                    { value: ratings[2] || 0, color: Style.faceBad },
                    { value: ratings[1] || 0, color: Style.faceSad }
                ];
                setDistributionValues(newDistributionValues);
            } else {
                setDistributionValues(null);
            }
        });
    };

    useEffect(() => {
        if (to) {
            loadResults();
        }

    }, [to, filters]);



    const renderFlow = () => {


        if (hasEnoughActiveEmployees) {
            return (
                <Stack gap={2}>
                    {flowValues && <ClassificationFlowChart values={flowValues} />}



                    <HfBannerReact type={'primary'}>
                        <Typography variant="h6" component="div">
                            {flowBanner}
                        </Typography>
                    </HfBannerReact>


                    <Stack gap={1}>
                        <Typography variant="body3">
                            {$translate.instant('HI_DETAIL_NUM_VOTES', { votes: totalVotes, month: moment(to).format('MMM YYYY') })}
                        </Typography>

                        {distributionValues && <VotesChart values={distributionValues} totalVotes={totalVotes} />}

                    </Stack>
                </Stack>
            );
        } else {
            return (
                <EmptyState
                    small={true}
                    image="images/anonymous.png"
                    message={$translate.instant('NOT_ENOUGH_EMPLOYEES', {})}
                    submessage={$translate.instant('NOT_ENOUGH_EMPLOYEES_SUBMESSAGE', {})}
                />
            )
        }



    }

    return (
        <HappyLoading loading={loadingClassification || loadingVotes}>


            {noData ? (
                <EmptyState
                    image="images/empty_state_chart.png"
                    small={false}
                    message={$translate.instant('SCORE_NO_DATA')}
                    submessage={$translate.instant('SCORE_NO_DATA_DESCRIPTION')}
                />
            ) : (
                <>{renderFlow()}</>
            )}

        </HappyLoading>
    );
};

export default HIFunnelBox;
