const toSegmentParams = (segmentIds: any[]) => {
    if (!segmentIds || segmentIds.length === 0) {
        return null;
    }
    return segmentIds
        .map((entry) => {
            return entry.values.map((value: any) => `${entry.id}:${value}`);
        })
        .reduce((acc, curr) => acc.concat(curr), [])
        .join(',');
};

export { toSegmentParams };

