

import React, { useEffect, useState } from 'react';
import { getService } from 'reactInAngular';
import Numbers from '../../../../../utilities/number.utilities';
import EngagementRankingBox from '../../../shared/ranking_box/ranking_box.controller';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {Stack} from '@mui/material';

interface EngagementDetailEnpsBenchmarkProps {
    loading: boolean;
    companyBenchmark: number;
    globalBenchmark: number;
    industryBenchmark: number;
    ranking: number;
    value: number;
    companyAverageBenchmark: number;
    globalAverageBenchmark: number;
    industryAverageBenchmark: number;
    averageValue: number;
    noData: boolean;
    mode: string;
    showCompanyBenchmark: boolean;
}

const EngagementDetailEnpsBenchmark: React.FC<EngagementDetailEnpsBenchmarkProps> = ({ loading, companyBenchmark, globalBenchmark, industryBenchmark, ranking, value, companyAverageBenchmark, globalAverageBenchmark, industryAverageBenchmark, averageValue, noData, mode, showCompanyBenchmark }) => {


    const [companyBenchmarkText, setCompanyBenchmarkText] = useState<string>('');
    const [globalBenchmarkText, setGlobalBenchmarkText] = useState<string>('');
    const [industryBenchmarkText, setIndustryBenchmarkText] = useState<string>('');

    const textForBenchmark = (value: number, benchmark: number, key: string) => {
        const difference = value - benchmark;
        const formattedDiff = Numbers.roundNumber(difference, 1);

        const keys = { benchmark: benchmark, difference: Math.abs(formattedDiff) };
        const $translate = getService('$translate');

        if (difference === 0) {
            return $translate.instant(key + '_NEUTRAL', keys);
        } else if (difference > 0) {
            return $translate.instant(key + '_POSITIVE', keys);
        } else {
            return $translate.instant(key + '_NEGATIVE', keys);
        }
    };

    useEffect(() => {

        if (mode === 'ENPS_MODE') {
            setCompanyBenchmarkText(textForBenchmark(value, companyBenchmark, 'BENCHMARK_COMPANY'));
            setGlobalBenchmarkText(textForBenchmark(value, globalBenchmark, 'BENCHMARK_GLOBAL'));
            setIndustryBenchmarkText(textForBenchmark(value, industryBenchmark, 'BENCHMARK_INDUSTRY'));
        } else {
            setCompanyBenchmarkText(textForBenchmark(averageValue, companyAverageBenchmark, 'BENCHMARK_COMPANY'));
            setGlobalBenchmarkText(textForBenchmark(averageValue, globalAverageBenchmark, 'BENCHMARK_GLOBAL'));
            setIndustryBenchmarkText(textForBenchmark(averageValue, industryAverageBenchmark, 'BENCHMARK_INDUSTRY'));

        }

    }, [companyAverageBenchmark, globalAverageBenchmark, industryBenchmark, industryAverageBenchmark, globalAverageBenchmark, globalBenchmark, averageValue, mode]);

    if (noData) {
        return null;
    }

    return (
        <Stack gap={2} textAlign={'center'}>
            <EngagementRankingBox loading={loading} ranking={ranking} type="ENPS" />
            {showCompanyBenchmark && (
                <Typography variant="body3" dangerouslySetInnerHTML={{ __html: companyBenchmarkText }} />
            )}
            <Typography variant="body3" dangerouslySetInnerHTML={{ __html: globalBenchmarkText }} />
            <Typography variant="body3" dangerouslySetInnerHTML={{ __html: industryBenchmarkText }} />
        </Stack>
    );
};


export default EngagementDetailEnpsBenchmark;

