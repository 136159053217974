import React, { useState, useEffect } from 'react';
import { getService } from 'reactInAngular';
import Valuation from '../../../../../utilities/valuation.utils';
import Dates from '../../../../../utilities/date.utilities';
import ScaleChart from '../../../../../shared/components/_migrated/scale-chart/scale-chart.component';
import EmptyState from '../../../../../shared/components/_migrated/empty_state/empty_state';
import {ValuationType} from '../../../../../shared/types/common';

interface HiDetailOverviewProps {
    value: number;
    variation: number;
    measureDate: Date;
    quality: string;
    valuation: ValuationType;
    loading: boolean;
    hasEnoughActiveEmployees: boolean;
}

const HiDetailOverview: React.FC<HiDetailOverviewProps> = ({ value, variation, measureDate, quality, valuation, loading, hasEnoughActiveEmployees }) => {
    const $translate = getService('$translate');
    const Settings = getService('Settings');

    const [color, setColor] = useState<string>('');
    const [shouldDisplayData, setShouldDisplayData] = useState<boolean>(false);

    const slices = [{
        color: Valuation.valuationColor('BAD'),
        range: [0, 33],
        label: $translate.instant('HI_SLICE_FIRST')
    }, {
        color: Valuation.valuationColor('WARNING'),
        range: [33, 66],
        label: $translate.instant('HI_SLICE_SECOND')
    }, {
        color: Valuation.valuationColor('EXCELLENT'),
        range: [66, 100],
        label: $translate.instant('HI_SLICE_THIRD')
    }];

    const getVariationText = (): string => {
        let key = 'SCORE_VARIATION_';
        if (variation > 0) {
            key += 'UP';
        } else if (variation < 0) {
            key += 'DOWN';
        } else {
            key += 'EQUAL';
        }

        return $translate.instant(key, { variation: variation });
    };

    useEffect(() => {
        if (value && measureDate && quality && valuation) {
            setShouldDisplayData(hasEnoughActiveEmployees);
            setColor(Valuation.valuationColor(valuation, true));
        }
    }, [value, measureDate, quality, valuation, hasEnoughActiveEmployees]);

    return (
        <section className="hi-detail-detail-overview white-bg">
            <div className="row overview-row">
                <div className="col-xs-12">
                    {shouldDisplayData && value && (
                        <div className="row same-height">
                            <div className="col-xs-12 col-lg-10">
                                <div className="big-number-container" style={{ color: color }}>
                                    <span className="big-number">{value}</span>
                                    <span className="big-number-sub">pts</span>
                                </div>
                                <ScaleChart slices={slices} value={value} />
                                <p className="small-style m-t-xs" dangerouslySetInnerHTML={{ __html: getVariationText() }}></p>
                            </div>
                        </div>
                    )}
                    {hasEnoughActiveEmployees && quality === 'NO_DATA' && (
                        <div className="row">
                            <div className="col-xs-12">
                                <div className="big-number-container" style={{ color: color }}>
                                    <span className="big-number-no-data">{ $translate.instant('NO_DATA') }</span>
                                </div>
                                <p className="small-style">
                                    { $translate.instant('SCORE_NO_DATA_SHORT_DESCRIPTION') }
                                </p>
                            </div>
                        </div>
                    )}

                    {!hasEnoughActiveEmployees && (
                        <div className="row">
                            <EmptyState image={'images/anonymous.png'}
                                message={$translate.instant('NOT_ENOUGH_EMPLOYEES')}
                                submessage={$translate.instant('NOT_ENOUGH_EMPLOYEES_SUBMESSAGE')}
                            />
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default HiDetailOverview;
