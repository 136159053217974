import React, { useState, useEffect } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import Pagination from '@mui/material/Pagination';
import { getENPSIndividualStats } from '../../../../../shared/services/migrated/enps.service';
import { getService } from 'reactInAngular';
import Charts from '../../../../../utilities/charts.utilities';
import Dates from '../../../../../utilities/date.utilities';
import Style from '../../../../../utilities/style.utils';
import Valuation from '../../../../../utilities/valuation.utils';
import InfoIcon from '@mui/icons-material/Info';
import {Box, FormControl, InputLabel, MenuItem, Select, Stack, Tooltip, Typography} from '@mui/material';
import HappyLoading from '../../../../../shared/components/_migrated/loader/loading.directive';
import {FilterSelected} from '../../../../../shared/new-components/hf-filter-menu/types';
import {toSegmentParams} from '../../../shared/utils';
import EmptyState from '../../../../../shared/components/_migrated/empty_state/empty_state';
import PersonEngagementStatusContextModal
    from '../../../../../shared/components/_migrated/engagement_context_modal/PersonEngagementContextModal';

interface EngagementEnpsIndividualHeatmapBoxProps {
    to: Date;
    noData: boolean;
    filters: FilterSelected;
}

const DisplayByOptions = [
    { id: 'CALENDAR', labelKey: 'INDIVIDUAL_VOTES_CALENDAR' },
    { id: 'SCATTER', labelKey: 'INDIVIDUAL_VOTES_SCATTER' }
];

const ValueByOptions = [
    { id: 'LAST_VOTE', labelKey: 'LAST_VOTE' },
    { id: 'AVERAGE_VOTE', labelKey: 'AVERAGE_VOTE' }
];

const EngagementEnpsIndividualHeatmapBox: React.FC<EngagementEnpsIndividualHeatmapBoxProps> = ({ to, noData, filters }) => {
    const [itemsPerPage] = useState<number>(25);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [displayBy, setDisplayBy] = useState<string>('SCATTER');
    const [valueBy, setValueBy] = useState<string>('LAST_VOTE');
    const [loading, setLoading] = useState<boolean>(true);
    const [results, setResults] = useState<any[]>([]);
    const ErrorSvrc = getService('ErrorSvrc');
    const $translate = getService('$translate');
    const [individualChartConfig, setIndividualChartConfig] = useState<Highcharts.Options | null>(null);
    const [calendarChartConfig, setCalendarChartConfig] = useState<Highcharts.Options | null>(null);

    const [showEngagementContext, setShowEngagementContext] = useState<boolean>(false);
    const [selectedMemberId, setSelectedMemberId] = useState<any>(null);



    const displayByOptions = DisplayByOptions.map(option => ({
        id: option.id,
        label: $translate.instant(option.labelKey),
    }));

    const valueByOptions = ValueByOptions.map(option => ({
        id: option.id,
        label: $translate.instant(option.labelKey),
    }));

    const handleDisplayByChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setDisplayBy(event.target.value as string);
    };

    const handleValueByChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setValueBy(event.target.value as string);
    };

    const buildIndividual = (members: any[]) => {
        const serieData: any[] = [];

        const markerPulsing = {
            radius: 5,
            symbol: 'circle',
            states: {
                hover: { enabled: true },
                normal: { enabled: true, lineColor: Style.chartColors[0], lineWidthPlus: 3 }
            }
        };

        const markerNotPulsing = {
            radius: 5,
            symbol: 'circle',
            states: { hover: { enabled: true } }
        };

        members.forEach(member => {
            const vote = (member.votes && valueBy === 'LAST_VOTE') ? member.votes[member.votes.length - 1].rating : member.result;
            const valuation = (member.votes && valueBy === 'LAST_VOTE') ? member.votes[member.votes.length - 1].valuation : member.valuation;

            const memberData = {
                x: member.votes.length,
                y: vote,
                lastVoteDate: member.votes[member.votes.length - 1].date,
                color: Valuation.valuationColor(valuation),
                encryptedId: member.encryptedId,
                marker: member.hasConversations ? markerPulsing : markerNotPulsing
            };

            serieData.push(memberData);
        });

        return {
            chart: { type: 'scatter', zoomType: 'xy', plotBorderWidth: 0 },
            boost: { useGPUTranslations: true },
            title: null,
            xAxis: { title: { text: 'Votes' }, gridLineWidth: 0, tickInterval: 1 },
            yAxis: { gridLineWidth: 0, min: 0, max: 10, title: { text: 'eNPS' } },
            legend: { enabled: false },
            plotOptions: {
                series: {
                    turboThreshold: 0,
                    point: {
                        events: {
                            click: function (event: any) {
                                if (!event.point.isCluster) {

                                    setSelectedMemberId(event.point.options.encryptedId);
                                    setShowEngagementContext(true);
                                }
                            }
                        }
                    },
                    dataLabels: { enabled: true, pointFormat: '' },
                    jitter: { x: 0.75, y: 0.25 }
                }
            },
            series: [{
                type: 'scatter',
                data: serieData,
                turboThreshold: 0,
                cursor: 'pointer',
                tooltip: {
                    followPointer: false,
                    pointFormat: valueBy === 'AVERAGE_VOTE'
                        ? 'Num. votes: {point.x} : eNPS: {point.y}'
                        : 'Last vote: {point.options.lastVoteDate:%b-%Y} : eNPS: {point.y}'
                }
            }]
        };
    };

    const buildCalendarChart = (members: any[]) => {
        Charts.initializeDateFormats();

        const series: any[] = [];
        const enpsCategories: any[] = [];

        members.forEach(member => {
            const periodEnps = (member.votes && valueBy === 'LAST_VOTE') ? member.votes[member.votes.length - 1].rating : member.result;
            enpsCategories.push(periodEnps);

            const memberSerie = { data: [] };
            member.votes.forEach(vote => {
                memberSerie.data.push([Dates.formatServerDateToTimestamp(vote.date), series.length, vote.rating]);
            });
            series.push(memberSerie);
        });

        const numSeries = series.length;

        return {
            chart: { type: 'heatmap', plotBorderWidth: 0, height: ((35 * numSeries) + 100) + 'px' },
            boost: { useGPUTranslations: true },
            title: null,
            xAxis: [{
                type: 'datetime',
                gridLineWidth: 0,
                tickInterval: 90 * 24 * 3600 * 1000,
                labels: { format: '{value:Q%q\' %Y}', rotation: -30 }
            }, {
                type: 'datetime',
                gridLineWidth: 0,
                opposite: true,
                linkedTo: 0,
                tickInterval: 90 * 24 * 3600 * 1000,
                labels: { format: '{value:Q%q\' %Y}', rotation: -30 }
            }],
            yAxis: [{
                type: 'category',
                categories: enpsCategories,
                title: null,
                gridLineWidth: 0,
                dashStyle: 'Dot',
                labels: {
                    useHTML: true,
                    formatter: function () {
                        const member = members[this.pos];
                        const lastVote = member.votes ? member.votes[member.votes.length - 1].rating : member.result;
                        const img = lastVote >= 9 ? '/images/enps_promoter.svg' : lastVote >= 7 ? '/images/enps_neutral.svg' : '/images/enps_detractor.svg';
                        const hasConversationClass = members[this.pos].hasConversations ? 'fa-comments' : 'fa-comments-o';

                        return `<span role="button" class="medium-style text-success" style="display: flex;align-items: center;">
                            <img src="${img}" class="img-xsm" />
                            <i class="fa m-l-xs ${hasConversationClass}"></i></span>`;
                    },
                    events: {
                        click: function () {
                            if (this.pos >= 0) {

                                setSelectedMemberId(  members[this.pos].encryptedId);
                                setShowEngagementContext(true);
                            }

                        }
                    }
                }
            }],
            colorAxis: {
                dataClasses: [
                    { from: 1, to: 6, color: Style.bad },
                    { from: 7, to: 8, color: Style.warning },
                    { from: 9, to: 10, color: Style.good }
                ]
            },
            plotOptions: {
                series: {
                    borderWidth: 0,
                    colsize: 90 * 24 * 36e5,
                    rowsize: 0.50,
                    marker: { symbol: 'circle' }
                }
            },
            legend: { enabled: false },
            tooltip: {
                useHTML: true,
                formatter: function () {
                    return `<b>${Highcharts.dateFormat('%e-%b-%Y', new Date(this.point.x))}</b>: ${this.point.value}`;
                }
            },
            series: series
        };
    };

    const prepareParams = () => {
        const params = {
            filters: toSegmentParams(filters?.segmentIds as any[]),
            groupId: filters?.groupIds,
            hierarchyId: filters?.hierarchyIds,
        };
        return params;
    };

    useEffect(() => {
        const toFormatted = new Date(to).toISOString().split('T')[0];
        const from = new Date(to);
        from.setFullYear(from.getFullYear() - 1);
        const fromFormatted = from.toISOString().split('T')[0];


        getENPSIndividualStats(fromFormatted, toFormatted, prepareParams(), (err: any, result: any) => {
            setLoading(false);
            if (err) {
                if (!ErrorSvrc.isNotEnoughActiveEmployees(err)) {
                    ErrorSvrc.showErrorModal(err);
                }
                setResults([]);
            } else {
                setResults(result.members);
            }
        });
    }, [to, filters, displayBy, valueBy]);

    useEffect(() => {
        if (results && results.length) {
            const paginatedMembers = results.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
            setCalendarChartConfig(buildCalendarChart(paginatedMembers));
            setIndividualChartConfig(buildIndividual(results));
        }
    }, [results, currentPage]);

    if (!loading && noData) {
        return (
            <EmptyState
                image="images/analyze_no_data.png"
                small
                message={$translate.instant('SCORE_NO_DATA', {})}
                submessage={$translate.instant('SCORE_NO_DATA_DESCRIPTION', {})}
            />

        )
    }

    return (
        <HappyLoading message={$translate.instant('LOADING_DATA_SENTENCE_1')} loading={loading}>
            <Stack gap={2}>
                <Stack alignItems={'center'} justifyContent={'center'} direction={'row'} gap={1} >
                    <Box flexGrow={1}>
                        <Typography variant="h2" >
                            {$translate.instant('HI_INDIVIDUALS_MAP')}
                        </Typography>

                        <Typography variant="body3"  color={'textSecondary'} >
                            {$translate.instant(displayBy === 'SCATTER'
                                ? 'INDIVIDUAL_HEATMAP_BY_SCATTER_DESCRIPTION'
                                : 'INDIVIDUAL_HEATMAP_BY_CALENDAR_DESCRIPTION', { metric: 'eNPS' })}
                        </Typography>
                    </Box>
                    <Box>
                        <FormControl fullWidth>
                            <InputLabel>{$translate.instant('DISPLAY_MODE')}</InputLabel>
                            <Select value={displayBy}
                                onChange={handleDisplayByChange}>
                                {displayByOptions.map(option => (
                                    <MenuItem key={option.id} value={option.id}>{option.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl fullWidth>
                            <InputLabel>
                                {$translate.instant('VALUE_MODE')}
                                <Tooltip title={$translate.instant('VALUE_MODE_DESCRIPTION_POPUP')}>
                                    <InfoIcon className="text-success m-l-xs" />
                                </Tooltip>
                            </InputLabel>
                            <Select value={valueBy} onChange={handleValueByChange}>
                                {valueByOptions.map(option => (
                                    <MenuItem key={option.id} value={option.id}>{option.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Stack>


                <Stack>
                    {displayBy === 'SCATTER' && (
                        <HighchartsReact highcharts={Highcharts} options={individualChartConfig} />
                    )}
                    {displayBy === 'CALENDAR' && (
                        <>
                            <HighchartsReact highcharts={Highcharts} options={calendarChartConfig} />
                            <Box display="flex" justifyContent="center">
                                <Pagination
                                    count={Math.ceil(results.length / itemsPerPage)}
                                    page={currentPage}
                                    onChange={(_, page) => setCurrentPage(page)}
                                />
                            </Box>
                        </>

                    )}
                </Stack>

            </Stack>
            <PersonEngagementStatusContextModal
                from={Dates.firstDayOfMonth(to)}
                to={Dates.lastDayOfMonth(to)}
                employeeToken={selectedMemberId}
                showDrawer={showEngagementContext}
                onClose={() => setShowEngagementContext(false)}
            />
        </HappyLoading>

    );
};

export default EngagementEnpsIndividualHeatmapBox;
