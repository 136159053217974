import React, { useEffect, useState } from 'react';
import { getService } from 'reactInAngular';
import Style from '../../../../../utilities/style.utils';
import { getENPSClassification } from '../../../../../shared/services/migrated/enps.service';
import {Stack, Typography} from '@mui/material';
import EmptyState from '../../../../../shared/components/_migrated/empty_state/empty_state';
import Dates from '../../../../../utilities/date.utilities';
import { HfBannerReact } from '../../../../../shared/new-components/hf-banner/hfBanner';
import HappyLoading from '../../../../../shared/components/_migrated/loader/loading.directive';
import {FilterSelected} from '../../../../../shared/new-components/hf-filter-menu/types';
import {DateGrouping} from '../../../../../shared/types/common';
import {toSegmentParams} from '../../../shared/utils';
import { ClassificationFlowChart } from '../../../shared/classification_flow_chart/ClassificationFlowChart';
import VotesChart from '../../../shared/votes_chart/VotesChart';

interface ENPSFunnelBoxProps {
    to: Date;
    filters: FilterSelected;
    dateGrouping: DateGrouping;
    currentClassification: any;
}

const categories = [
    {
        category: 'DETRACTORS',
        color: Style.bad,
        last: false
    },
    {
        category: 'PASSIVES',
        color: Style.warning,
        last: false
    },
    {
        category: 'PROMOTERS',
        color: Style.good,
        last: true
    }
];

const ENPSFunnelBox: React.FC<ENPSFunnelBoxProps> = ({ to, filters, dateGrouping, currentClassification}) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [noData, setNoData] = useState<boolean>(false);
    const [hasEnoughActiveEmployees, setHasEnoughActiveEmployees] = useState<boolean>(true);
    const [distributionValues, setDistributionValues] = useState<any[] | null>(null);
    const [total, setTotal] = useState<number | null>(null);
    const [flowValues, setFlowValues] = useState<any[] | null>(null);
    const [valuation, setValuation] = useState<string | null>(null);

    const ErrorSvrc = getService('ErrorSvrc');
    const $translate = getService('$translate');

    const processDistribution = (results: any) => {
        if (!results) {
            return;
        }

        const { DETRACTORS = 0, PASSIVES = 0, PROMOTERS = 0 } = results;
        const total = DETRACTORS + PASSIVES + PROMOTERS;
        setTotal(total);

        const distributionValues = [
            {
                value: DETRACTORS,
                color: Style.bad
            },
            {
                value: PASSIVES,
                color: Style.warning
            },
            {
                value: PROMOTERS,
                color: Style.excellent
            }
        ];

        return distributionValues;
    };

    const buildArrowElement = (funnelItem: any) => {
        return {
            count: funnelItem.count,
            sourceLabel: $translate.instant(`ENPS_${funnelItem.source}`),
            targetLabel: $translate.instant(`ENPS_${funnelItem.target}`)
        };
    };

    const processCategory = (results: any, categoryInfo: any) => {
        const result: any = {
            category: {
                type: 'category',
                label: $translate.instant(`ENPS_${categoryInfo.category}`),
                number: results.classification[categoryInfo.category],
                color: categoryInfo.color
            },
            arrow: null
        };

        const flowCount = results.funnel
            .filter((f: any) => f.source === categoryInfo.category || f.target === categoryInfo.category)
            .reduce((acc: number, f: any) => acc + f.count, 0);

        const flowItems = results.funnel
            .filter((f: any) =>
                (f.source === categoryInfo.category && f.count !== 0) ||
                (f.target === categoryInfo.category && f.count !== 0))
            .map(buildArrowElement);

        if (!categoryInfo.last) {
            result.arrow = {
                type: 'arrow',
                flow: flowCount,
                flowItems: flowItems
            };
        }

        return result;
    };


    const prepareParams = () => {

        const toParam = Dates.lastDayOfMonth(to);
        const params = {
            to: toParam,
            filters: toSegmentParams(filters?.segmentIds as any[]),
            groupId: filters?.groupIds,
            hierarchyId: filters?.hierarchyIds,
        };
        return params;

    }

    const loadResults = () => {

        const params = prepareParams();

        setLoading(true);


        getENPSClassification(params).then((response: any) => {


            if (response.quality === 'NO_DATA') {
                setNoData(true);
                setDistributionValues(null);
                setFlowValues(null);
                setValuation(null);
                setHasEnoughActiveEmployees(false);
            } else {
                const distributionValues = processDistribution(currentClassification);

                const results: any[] = [];
                categories.forEach((type) => {
                    const result = processCategory(response, type);
                    results.push(result.category);
                    if (result.arrow) {
                        results.push(result.arrow);
                    }
                });

                setDistributionValues(distributionValues);
                setFlowValues(results);
                setValuation(response.valuation);
                setHasEnoughActiveEmployees(true);
                setNoData(false);
            }
            setLoading(false);


        }).catch((err: any) => {
            ErrorSvrc.showErrorModal(err);
        }).finally(() => {
            setLoading(false);
        });


    };

    useEffect(() => {

        if (to && dateGrouping) {
            loadResults();
        } else {
            setNoData(true);
        }


    }, [to, filters, dateGrouping, currentClassification]);


    const renderFlow = () => {


        if (hasEnoughActiveEmployees) {
            return (
                <Stack gap={2}>
                    {flowValues && <ClassificationFlowChart values={flowValues} />}



                    <HfBannerReact type={'primary'}>
                        <Typography variant="h6" component="div">
                            {$translate.instant('FLOW_DESCRIPTION_' + valuation)}
                        </Typography>
                    </HfBannerReact>


                    <Stack gap={1}>
                        <Typography variant="body3">{$translate.instant('ENPS_DISTRIBUTION_OVERALL')}</Typography>

                        <Typography variant="caption" color="textSecondary">{$translate.instant('ENPS_DISTRIBUTION_OVERALL_DESCRIPTION')}</Typography>

                        {distributionValues && <VotesChart values={distributionValues} totalVotes={total} />}

                    </Stack>
                </Stack>
            );
        } else {
            return (
                <EmptyState
                    small={true}
                    image="images/anonymous.png"
                    message={$translate.instant('NOT_ENOUGH_EMPLOYEES', {})}
                    submessage={$translate.instant('NOT_ENOUGH_EMPLOYEES_SUBMESSAGE', {})}
                />
            )
        }



    }

    return (
        <HappyLoading loading={loading}>


            {noData ? (
                <EmptyState
                    image="images/empty_state_chart.png"
                    small={false}
                    message={$translate.instant('SCORE_NO_DATA')}
                    submessage={$translate.instant('SCORE_NO_DATA_DESCRIPTION')}
                />
            ) : (
                <>{renderFlow()}</>
            )}
        </HappyLoading>
    );
};

export default ENPSFunnelBox;
