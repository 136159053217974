import {getService} from 'reactInAngular';
import React, {useEffect, useState} from 'react';
import Numbers from '../../../../utilities/number.utilities';
import {Stack, Typography} from '@mui/material';


interface BenchmarkBoxProps {
    loading: boolean;
    value: number;
    companyBenchmark: number;
    globalBenchmark: number;
    industryBenchmark: number;
    filtered: boolean;
}

const BenchmarkBox = ({ loading, value, companyBenchmark, globalBenchmark, industryBenchmark, filtered } : BenchmarkBoxProps) => {

    const [globalBenchmarkText, setGlobalBenchmarkText] = useState<string>('');
    const [companyBenchmarkText, setCompanyBenchmarkText] = useState<string>('');
    const [industryBenchmarkText, setIndustryBenchmarkText] = useState<string>('');
    const $translate = getService('$translate');


    const textForBenchmark = (value, benchmark, type) => {
        const difference = value - benchmark;
        const formattedDiff = Numbers.roundNumber(difference, 1);

        const keys = { benchmark: benchmark, difference: Math.abs(formattedDiff) };
        if (difference == 0) {
            return $translate.instant(type + '_NEUTRAL', keys);
        } else if (difference > 0) {
            return $translate.instant(type + '_POSITIVE', keys);
        } else {
            return $translate.instant(type + '_NEGATIVE', keys);
        }
    }


    useEffect(() => {
        setGlobalBenchmarkText(textForBenchmark(value, globalBenchmark, 'BENCHMARK_GLOBAL'));
    }, [globalBenchmark]);

    useEffect(() => {
        setCompanyBenchmarkText(textForBenchmark(value, companyBenchmark, 'BENCHMARK_COMPANY'));
    }, [companyBenchmark]);

    useEffect(() => {
        setIndustryBenchmarkText(textForBenchmark(value, industryBenchmark, 'BENCHMARK_INDUSTRY'));
    }, [industryBenchmark]);

    if (loading) {
        return <></>
    }

    return (
        <Stack gap={2}>
            {filtered && <Typography variant="body3" dangerouslySetInnerHTML={{ __html: companyBenchmarkText }} />}
            <Typography variant="body3" dangerouslySetInnerHTML={{ __html: globalBenchmarkText }} />
            <Typography variant="body3" dangerouslySetInnerHTML={{ __html: industryBenchmarkText }} />
        </Stack>
    );
}

export default BenchmarkBox;
