import React, { useEffect, useState } from 'react';
import {Box, Typography, Pagination, Grid, Stack} from '@mui/material';
import { getService } from 'reactInAngular';
import DateUtilities from '../../../utilities/date.utilities';
import HappyLoading from '../../components/_migrated/loader/loading.directive';
import {getENPSPersonalStats} from '../../services/migrated/enps.service';
import Valuation from '../../../utilities/valuation.utils';
import {ScoreFactorName, ScoreName} from '../hf-score-name/hfScoreName';
import useScoreNames from '../../hooks/useScoreNames';
import {useTheme} from '@mui/material/styles';


interface PersonEngagementContextProps {
    personToken: string;
    maxScoreRows: number;
    to: Date;
}

const ScoreIcon = ({ scoreId }) => {
    const { scoreIconPath } = useScoreNames(scoreId);
    const theme = useTheme();

    return (
        <Box component={'img'}    width={theme.typography.body1.fontSize}
            height={theme.typography.body1.fontSize} src={scoreIconPath}/>
    );
}

const PersonEngagementContext = ({ personToken, maxScoreRows, to } : PersonEngagementContextProps) => {
    const HIService = getService('HIService');
    const ScoresV2Srvc = getService('ScoresV2Srvc');
    const ErrorSvrc = getService('ErrorSvrc');
    const $translate = getService('$translate');

    const [hi, setHi] = useState(null);
    const [hiValuationColor, setHiValuationColor] = useState('');
    const [eNPS, setENPS] = useState(null);
    const [eNPSValuationColor, setENPSValuationColor] = useState('');
    const [scores, setScores] = useState([]);
    const [visibleScores, setVisibleScores] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentScoresPage, setCurrentScoresPage] = useState(1);

    const loadPersonHI = (from, to) => {
        return new Promise((resolve, reject) => {
            HIService.stats.personal(from, to, personToken, (err, data) => {
                if (err) {
                    reject(err);
                } else {
                    setHi(data.hi);
                    setHiValuationColor(Valuation.valuationColor(data.valuation, false));
                    resolve(null);
                }
            });
        });
    };

    const loadPersonENPS = (to) => {
        return new Promise((resolve, reject) => {
            getENPSPersonalStats(to, personToken, (err, data) => {
                if (err) {
                    reject(err);
                } else {
                    setENPS(data.result);
                    setENPSValuationColor(Valuation.valuationColor(data.valuation, false));
                    resolve(null);
                }
            });
        });
    };

    const loadPersonScores = (to) => {
        return new Promise((resolve, reject) => {
            ScoresV2Srvc.stats.personal(to, personToken, (err, data) => {
                if (err) {
                    reject(err);
                } else {
                    const formattedScores = data.map((result) => ({
                        id: result.scoreId,
                        value: result.score,
                        valuationColor: Valuation.valuationColor(result.valuation, false),
                        factors: Object.keys(result.factors).map((factorId) => ({
                            id: factorId,
                            value: result.factors[factorId].score,
                            valuationColor: Valuation.valuationColor(result.factors[factorId].valuation, false),
                        })),
                    }));

                    setScores(formattedScores);
                    resolve(null);
                }
            });
        });
    };

    const paginate = (page) => {
        const startIndex = (page - 1) * maxScoreRows;
        const endIndex = Math.min(startIndex + maxScoreRows, scores.length);
        setVisibleScores(scores.slice(startIndex, endIndex));
        setCurrentScoresPage(page);
    };

    useEffect(() => {
        paginate(1);
    }, [scores]);

    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            try {

                const fromDate = DateUtilities.firstDayOfMonth(to);
                const toDate =  DateUtilities.lastDayOfMonth(to)

                await Promise.all([loadPersonHI(fromDate, toDate), loadPersonENPS(toDate), loadPersonScores(toDate)]);
            } catch (err) {
                ErrorSvrc.showErrorModal(err);
            } finally {
                setLoading(false);
            }
        };
        loadData();
    }, [personToken, to]);

    return (
        <HappyLoading loading={loading}>



            <Stack gap={2}>
                <Box display="flex" justifyContent="space-around" mt={2}>
                    <Box>
                        <Typography variant="body2" fontWeight={'bold'}>{`${$translate.instant('PERSON_ENGAGEMENT_CONTEXT_HI')}`}</Typography>
                        <Box display="flex" alignItems="center" mt={1} color={hiValuationColor}>
                            <img src="/images/hi_column_hm.png" alt="HI" style={{ width: '24px', marginRight: '8px' }} />
                            <Typography variant="body1">{hi ? `${hi.toFixed(1)} pts` : 'ND'}</Typography>
                        </Box>
                    </Box>
                    <Box>
                        <Typography variant="body2" fontWeight={'bold'}>{`${$translate.instant('PERSON_ENGAGEMENT_CONTEXT_ENPS')}`}</Typography>
                        <Box display="flex" alignItems="center" mt={1} color={eNPSValuationColor}>
                            <img src="/images/enps_column_hm.png" alt="eNPS" style={{ width: '24px', marginRight: '8px' }} />
                            <Typography variant="body1">{eNPS !== null ? `${eNPS} pts` : 'ND'}</Typography>
                        </Box>
                    </Box>
                </Box>


                <Stack direction={'column'} gap={2}>


                    {visibleScores.map((score) => (
                        <Stack key={score.id} gap={1}>

                            <Grid container>
                                <Grid item xs={3}>
                                    <Stack direction={'row'} gap={1} display={'flex'} alignItems={'center'}>
                                        <ScoreIcon scoreId={score.id} />
                                        <Typography variant="caption" fontWeight={'bold'}>
                                            <ScoreName scoreId={score.id} />
                                        </Typography>

                                    </Stack>

                                </Grid>
                                {score.factors.map((factor) => (
                                    <Grid key={factor.id} item xs={3}>
                                        <ScoreFactorName  scoreId={score.id} factorId={factor.id} />
                                    </Grid>

                                ))}
                            </Grid>


                            <Grid container>
                                <Grid item xs={3}>
                                    <Typography variant="caption" fontWeight={'bold'}>
                                        <Typography variant="caption">
                                            {score.value ? score.value.toFixed(2) : 'ND'}
                                        </Typography>
                                    </Typography>
                                </Grid>
                                {score.factors.map((factor) => (
                                    <Grid key={factor.id} item xs={3}>
                                        <Typography variant="caption" color={factor.valuationColor}>
                                            {factor.value ? factor.value.toFixed(1) : 'ND'}
                                        </Typography>
                                    </Grid>

                                ))}
                            </Grid>


                        </Stack>
                    ))}
                </Stack>

                {scores.length > maxScoreRows && (
                    <Box display="flex" justifyContent="center" mt={3}>
                        <Pagination
                            count={Math.ceil(scores.length / maxScoreRows)}
                            page={currentScoresPage}
                            onChange={(e, page) => paginate(page)}
                        />
                    </Box>
                )}
            </Stack>
        </HappyLoading>

    );
};


export default PersonEngagementContext;
