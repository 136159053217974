import Dates from 'root/app/utilities/date.utilities';

const DateGroupingFormat = function (Settings) {
    'ngInject';
    return function (date, grouping) {
        if (typeof date !== 'undefined') {
            return Dates.formatDateFromGrouping(date, grouping);
        } else {
            return '';
        }
    };
};

export default DateGroupingFormat;


