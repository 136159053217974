import { NestedMenuItem } from 'mui-nested-menu';
import { MenuItem, Checkbox, Radio, Box, ListItemText, Typography } from '@mui/material';
import {useEffect, useState} from 'react';
import {getService} from 'reactInAngular';
import {Group} from '../../../services/migrated/group.types';

interface GroupNestedMenuItemProps {
    open?: boolean;
    type: string;
    groups?: Group[];
    filters?: string[];
    onAddFilter: (type: string, filters: string[]) => void;
    title: string;
    mode: string;
    nonRemovable: boolean;
}

const GroupNestedMenuItem = ({ open, groups, filters, type, onAddFilter, title, mode, nonRemovable }: GroupNestedMenuItemProps) => {


    const [finalGroups, setFinalGroups] = useState<Group[] | undefined>();

    // If no groups are defined, load all the tree:


    const Groups = getService('Groups');

    useEffect(() => {
        if (!groups || groups.length === 0) {

            Groups.getAll({ active: true }, function (err, groups) {
                if (err) {
                    console.error('Error', err);
                    return;
                }

                setFinalGroups(groups);
            });

        } else {
            setFinalGroups(groups);
        }


    },[groups]);

    //handle with remove/add
    const handleFilter = (item) => {
        const alreadyExists = filters?.indexOf(item.id) > -1;

        if (mode === 'single') {
            // If it's already selected, remove it
            if (alreadyExists) {
                // .. only if it's not nonRemovable
                if (!nonRemovable) {
                    onAddFilter(type, []);
                } else {
                    return;
                }
            } else {
                onAddFilter(type, [item.id]);
                return;
            }
        } else if (mode === 'multi' || mode === 'grouped') {

            // If it's already selected, remove it
            if (alreadyExists) {
                // .. only if it's not nonRemovable or there are more than one selected
                if (!nonRemovable || filters.length > 1 ) {
                    onAddFilter(type, filters.filter(value => value !== item.id));
                } else {
                    return;
                }
            } else {
                onAddFilter(type, [...(filters ?? []), item.id]);
            }
        }

    }

    const renderGroups = (items) => {
        if (!items) {
            return null;
        }

        if (mode === 'multi') {
            return items.map((item, index) => {
                const isChecked = filters && filters.includes(item.id);

                if (item.children && item.children.length > 0) {
                    return (
                        <NestedMenuItem
                            key={index}
                            sx={{ paddingLeft: 1 }}
                            onClick={() => handleFilter(item)}
                            renderLabel={() =>
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <Checkbox
                                        checked={isChecked} />
                                    <ListItemText primary={
                                        <Typography style={{ fontSize: '14px' }}>
                                            {item.name}
                                        </Typography>
                                    } />
                                </Box>
                            }
                            parentMenuOpen={open}
                            button={true}
                        >
                            {renderGroups(item.children)}
                        </NestedMenuItem>
                    );
                } else {
                    return (
                        <MenuItem sx={{ paddingLeft: 1 }} key={index} onClick={() => handleFilter(item)}>
                            <Checkbox
                                checked={isChecked} /><ListItemText primary={
                                <Typography style={{ fontSize: '14px' }}>
                                    {item.name}
                                </Typography>
                            } />
                        </MenuItem>
                    );
                }
            });
        } else if (mode === 'single') {
            return items.map((item, index) => {
                const isSelected = filters && filters.includes(item.id);
                if (item.children && item.children.length > 0) {
                    return (
                        <NestedMenuItem
                            key={index}
                            sx={{ paddingLeft: 1 }}
                            onClick={() => handleFilter(item)}
                            renderLabel={() => <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Radio
                                    checked={isSelected}
                                />
                                <ListItemText primary={
                                    <Typography style={{ fontSize: '14px' }}>
                                        {item.name}
                                    </Typography>
                                } />
                            </Box>}
                            parentMenuOpen={open}
                            button={true}
                        >
                            {renderGroups(item.children)}
                        </NestedMenuItem>
                    );
                } else {
                    return (
                        <MenuItem
                            sx={{ paddingLeft: 1 }}
                            key={index}
                            onClick={() => handleFilter(item)}
                        >
                            <Radio
                                checked={isSelected}
                            />
                            <ListItemText primary={
                                <Typography style={{ fontSize: '14px' }}>
                                    {item.name}
                                </Typography>
                            } />
                        </MenuItem>
                    );
                }
            });
        } else {
            //TODO: GROUPED
        }
    };

    return (
        <NestedMenuItem
            renderLabel={() => <ListItemText primary={
                <Typography style={{ fontSize: '14px', paddingLeft: 10 }}>
                    {title}
                </Typography>
            } />}
            parentMenuOpen={open}
        >
            {renderGroups(finalGroups)}
        </NestedMenuItem>
    );
};

export default GroupNestedMenuItem;
